import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IStatusMessageDto} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {monthNames} from '../../../state/features/invoice/debtor-posting-helper';
import {saveStausMessages} from '../../../state/features/status-message/operation';
import {AppState} from '../../../state/store';
import {basicCardBgColor, btnTextColor} from '../../../styles/color-constants';
import {h5} from '../../../styles/font-size-constants';
import {isAdmin} from '../../../utilities/platform-helpers/auth-helper';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {TitleAndSubCard} from '../../../view/components/default-components/title-and-sub-card';
import {StatusMessagesCardItem} from './status-message-card-item';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardClass: {
      maxWidth: '100%',
      height: 'calc(100% - 32px)',
      maxHeight: '800px',
      minHeight: '200px',
    },
    nonStatusMsg: {
      height: '80%',
    },
    statusMsgItemBox: {
      maxHeight: 350,
      overflowY: 'auto',
      paddingLeft: 5,
      paddingRight: 5,
      marginLeft: -5,
      marginRight: -5,
    },
    statusMsgHeader: {
      height: '60px',
    },
    circularProgressBox: {
      height: '100%',
    },
    headerAndButton: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
    },
    dialogCategorySeperator: {
      marginTop: 30,
    },
    header: {
      flexGrow: 1,
    },
  }),
);

export const useButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '80px',
      minHeight: '32px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: basicCardBgColor,
      margin: '10px 0 10px 0',
      color: btnTextColor,
      boxShadow: '3px 3px 10px #838D9B33',
      border: '1px solid #E0E0E0',
      fontSize: h5,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
    },
    statusMessageIcon: {
      margin: '0 10px 0 0',
    },
  }),
);

interface PropsFromParent {
  redirect?: any;
}

const sortStatusMessages = (a: IStatusMessageDto, b: IStatusMessageDto): number =>
  b.date?.valueOf()! - a.date?.valueOf()!;

export const DashboardStatusMessageCard: FC<PropsFromParent> = React.memo(({redirect}) => {
  const classes = useStyles();
  const buttonClasses = useButtonStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string>();
  const [text, setText] = useState<string>();
  const {pending, statusMessageSlice} = useSelector((store: AppState) => store.statusMessageReducer);
  const [statusMessages, setStatusMessages] = useState<IStatusMessageDto[]>(statusMessageSlice);

  useEffect(() => {
    setStatusMessages(statusMessageSlice);
  }, [statusMessageSlice]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    const newStatusMsg = {
      title: title,
      text: text,
      date: new Date(),
    } as IStatusMessageDto;

    dispatch(saveStausMessages(newStatusMsg!));
    setStatusMessages([...statusMessages, newStatusMsg]);
    setTitle('');
    setText('');
    setOpen(false);
  };

  const handleClose = () => {
    setTitle('');
    setText('');
    setOpen(false);
  };

  const headlineInputHandler = (event: any) => {
    setTitle(event.target.value);
  };

  const textInputHandler = (event: any) => {
    setText(event.target.value);
  };

  interface IProps {
    messages: IStatusMessageDto[];
  }

  const StausMessageItemsMapped: FC<IProps> = ({messages}) => (
    <>
      {messages.length === 0 ? (
        <Grid container direction="column" justify="center" alignItems="center" className={classes.nonStatusMsg}>
          <Grid item>{localized('EverythingIsAjour')}</Grid>
        </Grid>
      ) : (
        <Box className={classes.statusMsgItemBox}>
          {messages.slice().sort(sortStatusMessages).map(generateStatusMessageCards(monthNames))}
        </Box>
      )}
    </>
  );

  return (
    <BasicCard className={classes.cardClass}>
      <Box className={classes.headerAndButton}>
        <Box className={classes.header}>
          <TitleAndSubCard title={localized('StatusMessages')} />
        </Box>

        {isAdmin() ? (
          <>
            <Button className={buttonClasses.root} onClick={handleClickOpen}>
              <CreateIcon className={buttonClasses.statusMessageIcon} />
              {localized('WriteStatusMessage')}
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="max-width-dialog-title"
              maxWidth={'md'}
              fullWidth>
              <DialogTitle id="max-width-dialog-title">{localized('NewStatusMessage')}</DialogTitle>
              <DialogContent>
                <TextField
                  id="standard-basic"
                  label={localized('Headline')}
                  fullWidth
                  onChange={headlineInputHandler}
                />
                <div className={classes.dialogCategorySeperator} />
                <TextField
                  id="standard-multiline-static"
                  label={localized('Message')}
                  multiline
                  rows={5}
                  fullWidth
                  onChange={textInputHandler}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {localized('Close')}
                </Button>
                <Button onClick={handleConfirm} color="primary" disabled={title && text ? false : true}>
                  {localized('Confirm')}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <></>
        )}
      </Box>
      {pending ? (
        <Grid container direction="column" justify="center" alignItems="center" className={classes.circularProgressBox}>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <StausMessageItemsMapped messages={statusMessages} />
      )}
    </BasicCard>
  );
});

function generateStatusMessageCards(
  monthNames: string[],
): (value: IStatusMessageDto, index: number, array: IStatusMessageDto[]) => JSX.Element {
  return (messages, index) => {
    return (
      <StatusMessagesCardItem
        id={messages.id}
        key={index}
        title={messages.title!}
        text={messages.text!}
        date={
          messages.date?.getDate()! + ' ' + monthNames[messages.date?.getMonth()!] + ' ' + messages.date?.getFullYear()!
        }
      />
    );
  };
}
