import moment from 'moment';
import {TimestampFormat} from '../constants';
export const setFromDate = (dateFrom: Date) => {
  dateFrom.setHours(0);
  dateFrom.setMinutes(0);
  dateFrom.setSeconds(0);

  return dateFrom;
};

export const setToDate = (dateTo: Date) => {
  dateTo.setHours(23);
  dateTo.setMinutes(59);
  dateTo.setSeconds(59);

  return dateTo;
};

export const showTimestamp = (timestamp: Date) => {
  return moment(timestamp).format(TimestampFormat);
};

export const adjustForTimezone = (date: Date | undefined, negativeOffset: boolean = false): Date | undefined => {
  if (date) {
    let timeOffsetInMS: number = date.getTimezoneOffset() * 60000;
    if (negativeOffset) {
      date.setTime(date.getTime() + timeOffsetInMS);
    } else {
      date.setTime(date.getTime() - timeOffsetInMS);
    }

    return date;
  }
  return undefined;
};

export const countNumberOfDaysBetweenDates = (from: Date, to: Date) => {
  return moment(to).diff(moment(from), 'days') + 1;
};

export const formatToLocalDateAndLeadingZeros = (date: Date) => {
  return date.toLocaleDateString().replace(/(^|\D)(\d)(?!\d)/g, '$10$2');
};
