import {createAsyncThunk} from '@reduxjs/toolkit';
import {
  ConsumptionExcelData,
  ConsumptionExcelDataDto,
  FileResponse,
  IDailyConsumptionDto,
  IUnitDto,
} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {saveExccelAs} from '../../../utilities/helpers/general-helpers';
import {ConsumptionWebClient, UnitsClient} from '../../../api/api';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {consumptionWebClient, unitsClient} from '../../api-clients';
import moment from 'moment';
import { DateFormat } from '../../../utilities/constants';

export const getConsumption = createAsyncThunk(
  'ConsumptionWeb/getByDateRange',
  (input: {fromDate: Date; toDate: Date; organizationId: number | undefined}) => {
    return AsyncOperationHandler(
      (client) => (client as ConsumptionWebClient).getByDateRange(input.fromDate, input.toDate, input.organizationId),
      consumptionWebClient,
    );
  },
);

export const getConsumptionByDate = createAsyncThunk(
  'ConsumptionWeb/getByDate',
  (input: {date: Date;organizationId: number | undefined}) => {
    return AsyncOperationHandler(
      (client) => (client as ConsumptionWebClient).getByDate(input.date, input.organizationId),
      consumptionWebClient,
    );
  },
);
export const getConsumptionOneYearPrior = createAsyncThunk(
  'ConsumptionWeb/getByDateRangeOneYearPrior',
  (input: {fromDate: Date; toDate: Date; organizationId: number | undefined}) => {
    return AsyncOperationHandler(
      (client) => (client as ConsumptionWebClient).getByDateRange(input.fromDate, input.toDate, input.organizationId),
      consumptionWebClient,
    );
  },
);

export const getConsumptionByDateOneYearPrior = createAsyncThunk(
  'ConsumptionWeb/getByDateOneYearPrior',
  (input: {date: Date;organizationId: number | undefined}) => {
    return AsyncOperationHandler(
      (client) => (client as ConsumptionWebClient).getByDate(input.date, input.organizationId),
      consumptionWebClient,
    );
  },
);

export function GetConsumptionExcelExport(data: IDailyConsumptionDto[], units: IUnitDto[], oneDay: boolean) {
  const unitHashmap = keyValuePairSingle(units, 'connectionid');
  const excelData = new ConsumptionExcelDataDto({
    excelData: data.map((ed) => {
      let connection: IUnitDto | undefined;
      if (ed.connectionId && unitHashmap[ed.connectionId]) {
        connection = unitHashmap[ed.connectionId];
      }
      return new ConsumptionExcelData({
        date: ed.date ? (oneDay ? ed.date.toLocaleString() : moment(ed.date).format(DateFormat)) : '',
        usage: ed.consumptionValue ? ed.consumptionValue : 0,
        eanNumber: connection?.eanId,
        measuringPoint:
        (connection?.street ? connection?.street + ' ' : '') + (connection?.houseNum ? connection?.houseNum : ''),
      });
    }),
    headers: [localized('Date'), localized('Usage'), localized('EanNumber'), localized('MeasuringPoint'), ],
    worksheetName: localized('EnergyConsumption'),
  });

  return consumptionWebClient
    .getExcelExport(excelData)
    .then((fileResponse: FileResponse | null) => {
      if (fileResponse !== null) {
        saveExccelAs(fileResponse, localized('EnergyConsumption') + '.xlsx');
      }
    })
    .catch(() => {
      return;
    });
}

export const getUnits = createAsyncThunk('Units/getUnitsByOrganizationId', (organizationId: number | undefined) => {
  return AsyncOperationHandler(
    (client) => (client as UnitsClient).getUnitsByOrganizationId(organizationId),
    unitsClient,
  );
});

function keyValuePairSingle<T>(data: T[], key: keyof T) {
  const items: {[key: string]: T} = {};
  data.forEach((item) => {
    const itemKey = item[key];
    if (itemKey) {
      items[(itemKey as any).toString()] = item;
    }
  });
  return items;
}
