import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import React, { FC } from 'react';
import { warningIconColor } from '../../../styles/color-constants';
import { isAdmin } from '../../../utilities/platform-helpers/auth-helper';
import { BasicCard } from '../../../view/components/default-components/basic-card/basic-card';
import { TitleAndSubCard } from '../../../view/components/default-components/title-and-sub-card';
import { DeletStatusMessageButton } from './dashboard-delete-statatus-message-button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    warningIcon: {
      color: warningIconColor,
      marginRight: 20,
    },
    messageTitle: {
      flexGrow: 1,
    },
    messageText: {
      marginLeft: 44,
    },
    dateText: {
      fontWeight: 'bold',
    },
    basicPadding: {
      margin: '10px 0 10px 0',
    },
    flexBox: {
      display: 'flex',
    },
    flexBoxSpaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

interface PropsFromParent {
  id: number;
  title: string;
  text: string;
  date: string;
}
export const StatusMessagesCardItem: FC<PropsFromParent> = React.memo(({title, text, date, id}) => {
  const classes = useStyles();
  return (
    <BasicCard className={classes.basicPadding}>
      <Box className={classes.flexBox}>
        <Box>
          <WarningIcon className={classes.warningIcon} />
        </Box>
        <Box className={classes.messageTitle}>
          <TitleAndSubCard title={title} />
        </Box>
        <Box className={classes.dateText}>{date}</Box>
      </Box>
      <Box className={classes.flexBoxSpaceBetween}>
        <Box className={classes.messageText}>
          <Typography>{text}</Typography>
        </Box>
        {isAdmin() && <DeletStatusMessageButton id={id} />}
      </Box>
    </BasicCard>
  );
});
