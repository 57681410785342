import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import React, {FC} from 'react';
import {arrowColor, primaryTextColor} from '../../../styles/color-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {TitleAndSubCard} from '../../../view/components/default-components/title-and-sub-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardClass: {
      height: 180,
      cursor: 'pointer',
      minWidth: 280,
    },
    cardText: {
      flexGrow: 1,
    },
    cardIcon: {
      marginRight: 20,
    },
    arrow: {
      opacity: 1,
      color: arrowColor,
      '&:hover': {
        color: primaryTextColor,
      },
    },
  }),
);

interface PropsFromParent {
  icon: any;
  title: string;
  subTitle?: string;
  redirect: any;
}

export const DashboardCard: FC<PropsFromParent> = React.memo(
  ({icon, title, subTitle, redirect, children, ...props}) => {
    const classes = useStyles();
    const cardIcon = icon;

    return (
      <BasicCard className={classes.cardClass} onClick={redirect}>
        <Box display={'flex'} alignItems="center">
          <Box className={classes.cardIcon}>{cardIcon}</Box>
          <Box className={classes.cardText}>
            <TitleAndSubCard title={title} subTitle={subTitle} />
            {subTitle ? null : <br />}
          </Box>
          <KeyboardArrowRightOutlinedIcon className={classes.arrow} />
        </Box>
        {children}
      </BasicCard>
    );
  },
);
