import React from 'react';
import { localized } from '../../../i18n/i18n';
import { PageContentContainer } from '../../../view/components/default-components/page-container/page-content-container';
import { PageTitle } from '../../../view/components/default-components/page-title/page-title';
import { PageHeader } from '../../../view/components/default-components/page-header.tsx/page-header';

export const EBooks = React.memo(() => {
    return (
        <PageTitle title="E-Books and Whitepaper">
            <PageHeader area="template" title={localized('EBooksAndWhitepapers')}>
                
            </PageHeader>
            <PageContentContainer>
                
            </PageContentContainer>
        </PageTitle>
    )
});
