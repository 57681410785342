import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import React, {FC} from 'react';
import { localized } from '../../i18n/i18n';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nonDocumentsGrid: {
      height: '80%',
    },
  }),
);

export const BasicNoDocuments: FC = React.memo(() => {
  const classes = useStyles();
  return (
    <>
      <Grid container direction="column" justify="center" alignItems="center" className={classes.nonDocumentsGrid}>
        <Grid item>
          {localized('WeCurrentlyDoNotKnowTheseDocuments')}
        </Grid>
      </Grid>
    </>
  );
});
