import {FileResponse} from '../api/api';

export const promptUserWithFileOptions = (file: FileResponse, filename: string) => {
  const url = URL.createObjectURL(file.data);
  const link = document.createElement('a');
  link.href = url;
  link.target= "_blank";
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
