import {createStyles, makeStyles, Theme} from '@material-ui/core';
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import deleteIcon from '../../../assets/icons/delete.svg';
import {deleteStausMessages} from '../../../state/features/status-message/operation';

export const DeleteUserFromOrganizationButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {marginRight: 5, cursor: 'pointer'},
  }),
);

interface DeletStatusMessageButtonProps {
  id: number;
}

export const DeletStatusMessageButton = React.memo((props: DeletStatusMessageButtonProps) => {
  const classes = DeleteUserFromOrganizationButtonStyles();

  const dispatch = useDispatch();
  const onButtonClicked = useCallback(() => {
    if (props.id) {
      dispatch(deleteStausMessages(props.id));
    }
  }, [props.id, dispatch]);

  return (
    <div className={classes.button} onClick={onButtonClicked}>
      <img src={deleteIcon} width="32px" height="23px" alt="firm-icon" />
    </div>
  );
});
