import {Box, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import {useLayoutStyles} from '../../../app-routes';
import {localized} from '../../../i18n/i18n';
import {h5} from '../../../styles/font-size-constants';
import {HowToReadInvoice} from './how-to-read-invoice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subText: {
      fontSize: h5,
      margin: '0 0 0 16px',
      lineHeight: '26px',
      maxWidth: '648px',
    },
    boldText: {
      fontWeight: 'bold',
    },
    flexDirectionRow: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      '&:hover': {
        color: '#3333',
      },
    },
    seachHeaderFlexBox: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

export const EconomyHeaderHowTo = () => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  return (
    <>
      <div className={layoutClasses.desktopOnly}>
        <Box className={classes.seachHeaderFlexBox}>
          <Box flexGrow={1}>
            <Typography className={clsx(classes.subText, classes.boldText)}>
              {localized('SearchInvoiceHeaderText')}
            </Typography>
          </Box>
          <HowToReadInvoice />
        </Box>
      </div>
    </>
  );
};
