import moment from 'moment';
import {countNumberOfDaysBetweenDates} from '../platform-helpers/date-helpers';

export const contractNoEndDate = new Date('9999-12-31 00:00:00.0000000 +00:00');

export const sameDate = (date1: Date, date2: Date) => {
  return (
    date1.getUTCDate() === date2.getUTCDate() &&
    date1.getUTCMonth() === date2.getUTCMonth() &&
    date1.getUTCFullYear() === date2.getUTCFullYear()
  );
};

export const numberOfDaysInCurrentMonth = () => {
  return countNumberOfDaysBetweenDates(moment().startOf('month').toDate(), moment().endOf('month').toDate());
};
export const numberOfDaysInCurrentQuarter = () => {
  return countNumberOfDaysBetweenDates(moment().startOf('quarter').toDate(), moment().endOf('quarter').toDate());
};

export const getMondayInWeek = (date: Date) => {
  return moment(date).startOf('isoWeek').toDate();
};
export const getSundayInWeek = (date: Date) => {
  return moment(date).endOf('isoWeek').toDate();
};
export const getFirstDayInMonth = (date: Date) => {
  return moment(date).startOf('month').toDate();
};
export const getLastDayInMonth = (date: Date) => {
  return moment(date).endOf('month').toDate();
};
export const getFirstDayInQuarter = (date: Date) => {
  return moment(date).startOf('quarter').toDate();
};
export const getLastDayInQuarter = (date: Date) => {
  return moment(date).endOf('quarter').toDate();
};

export const getYesterday = () => {
  return moment().subtract(1, 'day').toDate();
};
export const getMondayInPreviousWeek = () => {
  return moment().subtract(1, 'week').startOf('isoWeek').toDate();
};
export const getSundayInPreviousWeek = () => {
  return moment().subtract(1, 'week').endOf('isoWeek').toDate();
};
export const getFirstDayInPreviousMonth = () => {
  return moment().subtract(1, 'month').startOf('month').toDate();
};
export const getLastDayInPreviousMonth = () => {
  return moment().subtract(1, 'month').endOf('month').toDate();
};
export const getFirstDayInPreviousQuarter = () => {
  return moment().subtract(1, 'quarter').startOf('quarter').toDate();
};
export const getLastDayInPreviousQuarter = () => {
  return moment().subtract(1, 'quarter').endOf('quarter').toDate();
};

export const getOneYearPrior = (date: Date) => {
  date = moment(date).subtract(1, 'year').toDate();
  return date;
};

export const getDateByStringFormatDDMMYYYY = (dateString: string) => {
  return moment(dateString, 'DD-MM-YYYY').toDate();
};

// Addition
export const addOneDay = (date: Date) => {
  return moment(date).add(1, 'day').toDate();
};
export const addOneWeek = (date: Date) => {
  return moment(date).add(1, 'week').toDate();
};

export const addOneMonth = (date: Date) => {
  return moment(date).add(1, 'month').toDate();
};
export const addOneMonthToEndOfMonth = (date: Date) => {
  return moment(date).add(1, 'month').endOf('month').toDate();
};

export const addOneQuarter = (date: Date) => {
  return moment(date).add(1, 'quarter').toDate();
};
export const addOneQuarterToEndOfMonth = (date: Date) => {
  return moment(date).add(1, 'quarter').endOf('month').toDate();
};

// Subtraction
export const subtractOneDay = (date: Date) => {
  return moment(date).subtract(1, 'day').toDate();
};
export const subtractOneWeek = (date: Date) => {
  return moment(date).subtract(1, 'week').toDate();
};
export const subtractOneMonth = (date: Date) => {
  return moment(date).subtract(1, 'month').toDate();
};
export const subtractOneMonthToEndOfMonth = (date: Date) => {
  return moment(date).subtract(1, 'month').endOf('month').toDate();
};
export const subtractOneQuarter = (date: Date) => {
  return moment(date).subtract(1, 'quarter').toDate();
};
export const subtractOneQuarterToEndOfMonth = (date: Date) => {
  return moment(date).subtract(1, 'quarter').endOf('month').toDate();
};

// last day of month
export const setLastDayOfMonth = (date: Date) => {
  return moment(date).endOf('month').toDate();
};
