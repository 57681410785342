import { createAsyncThunk } from '@reduxjs/toolkit';
import { OrganizationClient } from '../../../api/api';
import { AsyncOperationHandler } from '../../../utilities/platform-helpers/api-helper';
import { organizationClient } from '../../api-clients';

export const getOrganizationById = createAsyncThunk('organizations/getOrganizationById', (accountNum: string) => {
  return AsyncOperationHandler(
    (client) => (client as OrganizationClient).getOrganizationDetails(accountNum),
    organizationClient,
  );
});

export const getOrganizationsByUserId = createAsyncThunk('organizations/getOrganizationsByAuthId', () => {
  return AsyncOperationHandler(
    (client) => (client as OrganizationClient).getOrganizationsByAuthId(),
    organizationClient,
  );
});
