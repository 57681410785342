import {
  CompetitionClient,
  ConsumptionWebClient,
  ContactClient,
  ContractClient,
  CustomerServiceClient,
  DebtorPostingClient,
  DocumentClient,
  GreenOptimizationClient,
  NewsClient,
  OrganizationClient,
  StatusMessageClient,
  UnitsClient,
  UserClient,
  MarketingMaterialsClient,
} from '../api/api';
import {ApiBaseUrl} from '../utilities/constants';

export const statusMessagesClient = new StatusMessageClient(ApiBaseUrl);
export const documentClient = new DocumentClient(ApiBaseUrl);
export const contractClient = new ContractClient(ApiBaseUrl);
export const contactClient = new ContactClient(ApiBaseUrl);
export const customerServiceClient = new CustomerServiceClient(ApiBaseUrl);
export const greenOptimizationClient = new GreenOptimizationClient(ApiBaseUrl);
export const consumptionWebClient = new ConsumptionWebClient(ApiBaseUrl);
export const unitsClient = new UnitsClient(ApiBaseUrl);
export const organizationClient = new OrganizationClient(ApiBaseUrl);
export const debtorPostingClient = new DebtorPostingClient(ApiBaseUrl);
export const userClient = new UserClient(ApiBaseUrl);
export const newsClient = new NewsClient(ApiBaseUrl);
export const competitionClient = new CompetitionClient(ApiBaseUrl);
export const marketingMaterialsClient = new MarketingMaterialsClient(ApiBaseUrl);
export type ApiClient =
  | StatusMessageClient
  | DocumentClient
  | ContractClient
  | ContactClient
  | CustomerServiceClient
  | GreenOptimizationClient
  | OrganizationClient
  | ConsumptionWebClient
  | UnitsClient
  | UserClient
  | DebtorPostingClient
  | NewsClient
  | CompetitionClient
  | MarketingMaterialsClient;
