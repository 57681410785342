export const routes = {
  userManagement: '/user-management',
  userManagementCreate: '/user-management/create',
  userManagementEdit: '/user-management/:userId/edit',

  organization: '/organization',
  organizationCreate: '/organization/create',
  organizationEdit: '/organization/:organizationId',

  template: '/template',
  dashboard: '/',
  sustainableInitiatives: '/sustainable-initiatives',
  economy: '/economy',
  contact: '/contact',
  contracts: '/contracts',
  documents: '/documents',
  eBooks: '/e-books',
  energyConsumption: '/energy-consumption',
  news: '/blog',
  singleNewsItem: '/blog/*',
  userInfo: '/user-info',
  marketingMaterial: '/marketing-materials',

  callback: '/callback',

  noPermission: '/no-permission',
};

export const defaultRoute = routes.dashboard;
