import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IContractDto } from '../../../api/api';
import { ContractSelectedState } from '../contract/types';
import {setSelectedOrganization} from '../organization/organization-slice';

const initialState: ContractSelectedState = {
  selectedContract: undefined,
};

export const selectedContractSlice = createSlice({
  name: 'selectedContract',
  initialState,
  reducers: {
    setSelectedContract: (state, action: PayloadAction<IContractDto | undefined>) => {
      state.selectedContract = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSelectedOrganization, (state, action) => {
      if (action.payload.previousOrganizationId !== undefined) {
        return initialState;
      }
    });
  },
});

// eslint-disable-next-line
export const {} = selectedContractSlice.actions;

export default selectedContractSlice.reducer;
