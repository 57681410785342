import { Button, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { INews } from '../../../api/api';
import Scan_windows from '../../../assets/scan_windows.jpg';
import { localized } from '../../../i18n/i18n';
import { GetAllNewsItems } from '../../../state/features/news/operation';
import { AppState } from '../../../state/store';
import { primaryTextColor, secondaryColor, whiteTextColor } from '../../../styles/color-constants';
import { GridWithCustomBreakpoints } from '../../../styles/custom-grid-breakpoints';
import { h5 } from '../../../styles/font-size-constants';
import { BasicCard } from '../../../view/components/default-components/basic-card/basic-card';
import { DashboardFilesCardFooter } from '../dashboard/dashboard-files-card-footer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summaryStyles: {
      '& h2': {
        margin: 0,
      },
      '& p': {
        margin: 0,
      },
    },
    minWidth176: {minHeight: '40px', width: '100%', height: '100%', maxWidth: '176px', maxHeight: '72px', paddingRight: "5px"},
    summaryContainerStyles: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    root: {
      minWidth: '100px',
      paddingRight: '10px',
      minHeight: '32px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      backgroundColor: whiteTextColor,
      margin: '10px 0px 10px 10px',
      color: primaryTextColor,
      boxShadow: '3px 3px 10px #838D9B33',
      border: '1px solid #E0E0E0',
      fontSize: h5,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
      '&.Mui-disabled': {
        backgroundColor: secondaryColor,
        color: '#FFF',
      },
    },
  }),
);

const generateNewsItems = (
  classes: ClassNameMap<'summaryStyles' | 'summaryContainerStyles' | 'minWidth176'>,
  history: any,
) => (news: INews, index: number): JSX.Element => {
  const goToNewsPage = () => {
    if (news.id) {
      history.push({pathname: news.id});
    }
  };
  return (
    <BasicCard key={news.id}>
      <Grid container direction="row">
        <GridWithCustomBreakpoints
          className={classes.minWidth176}
          item
          xs={12}
          sm={12}
          md={12}
          lg={3}
          xl={3}
          betweenlgandxl={3}>
          <img
            className={classes.minWidth176}
            src={news && news.headerImageSrc ? news.headerImageSrc : Scan_windows}
            alt="GreenEnergy"
          />
        </GridWithCustomBreakpoints>

        <GridWithCustomBreakpoints item xs={12} sm={12} md={12} lg={9} xl={9} betweenlgandxl={9}>
          <div className={classes.summaryContainerStyles}>
            {news && news.summary && (
              <div className={classes.summaryStyles} key={index} dangerouslySetInnerHTML={setInnerHtml(news)}></div>
            )}
            <DashboardFilesCardFooter
              category={news && news.category !== undefined ? news.category : ''}
              date={news && news.publishDate ? news.publishDate : undefined}
              author={news && news.authorName ? news.authorName : ''}
              readMore={goToNewsPage}
            />
          </div>
        </GridWithCustomBreakpoints>
      </Grid>
    </BasicCard>
  );
};
export const NewsItemsOverview = React.memo(() => {
  const newsItems = useSelector((state: AppState) => state.newsReducer.newsItems);
  const fetchedAllNews = useSelector((state: AppState) => state.newsReducer.fetchedAll);
  const divStyle = useStyles();
  const [news, setNews] = useState(newsItems.slice(0, 4));
  const [showFourMore, setShowFourMore] = useState(4);
  let dispatch = useDispatch();

  let history = useHistory();
  useEffect(() => {
    if (!fetchedAllNews) {
      dispatch(GetAllNewsItems());
    }
  }, [dispatch, fetchedAllNews]);
  const getShowFourMore = useCallback(() => {
    setShowFourMore(showFourMore + 4);
  }, [showFourMore, setShowFourMore]);
  useEffect(() => {
    setNews(newsItems.slice(0, newsItems.length < showFourMore ? newsItems.length : showFourMore));
  }, [setNews, showFourMore, newsItems]);
  return (
    <BasicCard>
      {fetchedAllNews && news.map(generateNewsItems(divStyle, history))}
      {(showFourMore < 9 || showFourMore <= newsItems.length) && (
        <Button className={divStyle.root} onClick={getShowFourMore}>
          {localized('SeeMore')}
        </Button>
      )}
    </BasicCard>
  );
});

function setInnerHtml(newsToUse: INews) {
  return {__html: newsToUse.summary ? newsToUse.summary : ''};
}
