import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import React from 'react';
import {useLayoutStyles} from '../../../app-routes';
import {localized} from '../../../i18n/i18n';
import {h5} from '../../../styles/font-size-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subText: {
      fontSize: h5,
      margin: '0 0 0 16px',
      lineHeight: '26px',
      maxWidth: '648px',
    },
  }),
);

export const EconomyHeader = () => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  return (
    <>
      <div className={layoutClasses.desktopOnly}>
        <Typography className={classes.subText}>{localized('EconomyDefaultText')}</Typography>
      </div>
    </>
  );
};
