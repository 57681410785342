import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
  open: false,
  showOverlaySpinner: false,
};

export const webappSlice = createSlice({
  name: 'webapp',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.open = !state.open;
    },
    setOverlaySpinner: (state, action: PayloadAction<boolean>) => {
      state.showOverlaySpinner = action.payload;
    },
  },
});

export const {toggleDrawer, setOverlaySpinner} = webappSlice.actions;

export default webappSlice.reducer;
