import {Box, Button, createStyles, makeStyles, Theme} from '@material-ui/core';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {localized} from '../../../i18n/i18n';
import {chooseGreenOptimization} from '../../../state/features/green-optimization/operation';
import {AppState} from '../../../state/store';
import {arrowColor, checkmarkColor, secondaryColor} from '../../../styles/color-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {useButtonStyles} from '../dashboard/dashboard-files-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardActive: {
      minWidth: 250,
      minHeight: 570,
      boxShadow: (active: boolean) => (active ? 'inset 0px 0px 0px 4px ' + secondaryColor : ''),
      padding: 30,
      display: 'flex',
      flexGrow: 1,
    },
    iconActive: {
      opacity: 1,
      fontSize: 30,
      float: 'right',
      color: (active: boolean) => (active ? checkmarkColor : arrowColor),
    },
    flexbox: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      minHeight: '100%',
      minWidth: '100%'
    },
    childrenBox: {
      minHeight: 330,
    },
    buttonBox: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'center',
      flexGrow: 1,
      marginBottom: 10,
    },
    activeButton: {
      textAlign: 'center',
      backgroundColor: secondaryColor,
    },
    greenLeaf: {
      float: 'left',
      height: 30,
    },
  }),
);

interface PropsFromParent {
  active: boolean;
  greenOptimizationId: string;
}

export const GreenElementCard: FC<PropsFromParent> = React.memo(({active, greenOptimizationId, children}) => {
  const classes = useStyles(active);
  const buttonClasses = useButtonStyles();
  const {organizationId} = useSelector((store: AppState) => store.organizationReducer);

  const handleOnClick = () => {
    if (organizationId) {
      chooseGreenOptimization(organizationId, greenOptimizationId);
    }
  };

  return (
    <BasicCard className={classes.cardActive}>
      <Box className={classes.flexbox}>
        <Box>
          <CheckCircleTwoToneIcon className={classes.iconActive} />
        </Box>
        <Box className={classes.childrenBox}>{children}</Box>
        <Box className={classes.buttonBox}>
          <Button className={buttonClasses.root} disabled={active} onClick={handleOnClick}>
            {active ? 'Aktiv' : localized('ContactMe')}
          </Button>
        </Box>
      </Box>
    </BasicCard>
  );
});
