import {Box, createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DebtorPostingType} from '../../../api/api';
import {useLayoutStyles} from '../../../app-routes';
import {localized} from '../../../i18n/i18n';
import {SetCurrentFilter} from '../../../state/features/invoice/operation';
import {DebtorPostingFilter} from '../../../state/features/invoice/types';
import {AppState} from '../../../state/store';
import {basicCardBgColor, basicCardBoxShadow} from '../../../styles/color-constants';
import {DateRangePicker} from '../../../view/components/default-components/date-range-picker/date-range-picker';
import {BasicTextField} from '../../../view/components/default-components/text-fields/basic-text-field';
import {BasicCardCheckbox} from '../basic-card-checkbox';
import {ResetFilterButton} from './reset-filter-button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchInvoiceTextField: {
      margin: '0px',
      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiInput-underline:after': {
        border: 'none',
      },
      '& .MuiInput-underline:hover:not(.mui-disabled):before': {
        border: 'none',
      },
      '& .MuiFormHelperText-root': {display: 'none'},
      '& .MuiInputBase-input': {
        lineHeight: '40px',
        height: '40px',
        padding: '0px 0px 0px 10px',
      },
    },
    searchInvoiceContainer: {
      margin: '16px 10px 16px 16px',
      maxHeight: '40px',
      maxWidth: '170px',
      backgroundColor: basicCardBgColor,
      boxShadow: basicCardBoxShadow,
      borderRadius: '10px',
    },
    optionText: {
      borderBottom: 'none',
    },
    flexDirectionRow: {
      flexDirection: 'row',
      display: 'flex',
      marginLeft: '10px',
    },
    searchPopUp: {
      marginLeft: 10,
    },
  }),
);

export const FilterInvoices = React.memo(() => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  const dispatch = useDispatch();
  const {currentFilter} = useSelector((state: AppState) => state.debtorPostingReducer);

  const setFilterDateFromTo = useCallback(
    (fDate: Date, tDate: Date) => {
      const filter = {...currentFilter} as DebtorPostingFilter;
      if (fDate) {
        filter.DateFrom = fDate;
      }
      if (tDate) {
        filter.DateTo = moment(tDate, 'DD/MM/YYYY').endOf('day').toDate();
      }
      dispatch(SetCurrentFilter(filter));
    },
    [currentFilter, dispatch],
  );

  const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
  const [toDate, setToDate] = useState<Date | undefined>(new Date());

  const handleFromDateChanged = useCallback(
    (event: Date) => {
      setFromDate(event);
      setFilterDateFromTo(event, toDate!);
    },
    [setFromDate, toDate, setFilterDateFromTo],
  );

  const handleToDateChanged = useCallback(
    (event: Date) => {
      setToDate(event);
      setFilterDateFromTo(fromDate!, event);
    },
    [setToDate, fromDate, setFilterDateFromTo],
  );

  const setFilterInvoiceType = useCallback(
    (type?: DebtorPostingType) => {
      const filter = {...currentFilter} as DebtorPostingFilter;
      filter.DebtorPostingType = type;
      dispatch(SetCurrentFilter(filter));
    },
    [currentFilter, dispatch],
  );
  const setFilterInvoiceNumber = useCallback(
    (num?: string) => {
      const filter = {...currentFilter} as DebtorPostingFilter;

      filter.InvoiceNumber = num;
      dispatch(SetCurrentFilter(filter));
    },
    [currentFilter, dispatch],
  );

  const handleInvoiceTypeInvoiceChanged = useCallback(() => {
    setInvoiceType(currentFilter, DebtorPostingType.Invoice, setFilterInvoiceType);
  }, [setFilterInvoiceType, currentFilter]);
  const handleInvoiceTypeCreditChanged = useCallback(() => {
    setInvoiceType(currentFilter, DebtorPostingType.Credit, setFilterInvoiceType);
  }, [setFilterInvoiceType, currentFilter]);
  const handleInvoiceTypeOtherChanged = useCallback(() => {
    setInvoiceType(currentFilter, DebtorPostingType.Other, setFilterInvoiceType);
  }, [setFilterInvoiceType, currentFilter]);
  useEffect(() => {
    return function cleanup() {
      dispatch(SetCurrentFilter({DateFrom: undefined, DateTo: new Date()}));
    };
  }, [dispatch]);

  const handleResetButtonPressed = useCallback(() => {
    let resetFilter: DebtorPostingFilter = {
      DateFrom: undefined,
      DateTo: new Date(),
    };
    setFromDate(resetFilter.DateFrom!);
    setToDate(resetFilter.DateTo!);

    dispatch(SetCurrentFilter(resetFilter));
    
    // eslint-disable-next-line
  }, [dispatch, SetCurrentFilter]);

  return (
    <div className={layoutClasses.desktopOnly}>
      <Grid container direction="row">
        <DateRangePicker
          dateFrom={fromDate!}
          dateFromChanged={handleFromDateChanged}
          dateTo={toDate!}
          dateToChanged={handleToDateChanged}
        />
        <Box className={classes.searchInvoiceContainer}>
          <BasicTextField
            text={currentFilter.InvoiceNumber}
            textChanged={setFilterInvoiceNumber}
            className={classes.searchInvoiceTextField}
            placeholder={localized('SearchInvoice')}></BasicTextField>
          <div className={classes.searchPopUp}>{currentFilter.InvoiceNumber! && localized('SearchInvoice')}</div>
        </Box>
        <ResetFilterButton onClick={handleResetButtonPressed} />
      </Grid>
      <div className={classes.flexDirectionRow}>
        <BasicCardCheckbox
          onClick={handleInvoiceTypeInvoiceChanged}
          optionState={
            currentFilter.DebtorPostingType === DebtorPostingType.Invoice
              ? generateInvoiceSelectedItem(currentFilter.DebtorPostingType)
              : undefined
          }
          option={facturaCheckBoxOption()}
        />
        <BasicCardCheckbox
          onClick={handleInvoiceTypeCreditChanged}
          optionState={
            currentFilter.DebtorPostingType === DebtorPostingType.Credit
              ? generateInvoiceSelectedItem(currentFilter.DebtorPostingType)
              : undefined
          }
          option={creditNoteCheckBoxOption()}
        />
        <BasicCardCheckbox
          onClick={handleInvoiceTypeOtherChanged}
          optionState={
            currentFilter.DebtorPostingType === DebtorPostingType.Other
              ? generateInvoiceSelectedItem(currentFilter.DebtorPostingType)
              : undefined
          }
          option={othersCheckBoxOption()}
        />
      </div>
    </div>
  );
});
const generateInvoiceSelectedItem = (debtorPostingType: DebtorPostingType) => ({
  selected: true,
  inputValue: debtorPostingType.toString(),
});

const facturaCheckBoxOption = () => ({title: localized('Invoice'), id: 'Invoice'});
const creditNoteCheckBoxOption = () => ({title: localized('CreditNote'), id: 'CreditNote'});
const othersCheckBoxOption = () => ({title: localized('Others'), id: 'Others'});

function setInvoiceType(
  currentFilter: DebtorPostingFilter,
  type: DebtorPostingType,
  setFilterInvoiceType: (type?: DebtorPostingType | undefined) => void,
) {
  if (currentFilter.DebtorPostingType !== undefined && currentFilter.DebtorPostingType === type) {
    setFilterInvoiceType(undefined);
  } else {
    setFilterInvoiceType(type);
  }
}
