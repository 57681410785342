import React, {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DocumentType, IDocumentDto} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {AppState} from '../../../state/store';
import {DocumentCard} from '../document/document-card';
import {FilterProvider} from '../filterprovider';

interface PropsFromParent {}

export const ConsumptionDocumentsReport: FC<PropsFromParent> = React.memo(({children, ...props}) => {
  const dispatch = useDispatch();
  const [reportsDocs, setReportDocs] = useState<IDocumentDto[]>();
  const {pending, documentsSlice} = useSelector((store: AppState) => store.documentsReducer);

  useEffect(() => {
    setReportDocs(documentsSlice.filter((d) => d.documentType === DocumentType.BasicReports));
  }, [documentsSlice, dispatch]);

  return (
    <FilterProvider>
      <DocumentCard
        title={localized('Reports')}
        parentDocuments={reportsDocs!}
        pending={pending}
        docuNumber={10}
        docuHeight={70}
      />
    </FilterProvider>
  );
});
