import {createSlice} from '@reduxjs/toolkit';
import {IDebtorPostingDocumentDto, IDocument} from '../../../api/api';
import {setSelectedOrganization} from '../organization/organization-slice';
import {getDebtorPostingDocumentDetails, getDocumentDetails} from './operation';
import {DocumentsState} from './types';

const initialState: DocumentsState = {
  documentsSlice: [] as IDocument[],
  debtorPostingDocuments: [] as IDebtorPostingDocumentDto[],
  pending: false,
  isSuccess: false,
};

export const documentsSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Documents
    builder.addCase(getDocumentDetails.fulfilled, (state, action) => {
      state.pending = false;
      if (action.payload) {
        state.documentsSlice = action.payload;
      }
    });

    builder.addCase(getDocumentDetails.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getDocumentDetails.rejected, (state, action) => {
      state.pending = false;
    });

    // Get DebtorPostingDocuments
    builder.addCase(getDebtorPostingDocumentDetails.fulfilled, (state, action) => {
      state.pending = false;
      if (action.payload) {
        state.debtorPostingDocuments = action.payload;
      }
    });

    builder.addCase(getDebtorPostingDocumentDetails.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getDebtorPostingDocumentDetails.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(setSelectedOrganization, (state, action) => {
      if (action.payload.previousOrganizationId !== undefined) {
        return initialState;
      }
    });
  },
});

// eslint-disable-next-line
export const {} = documentsSlice.actions;

export default documentsSlice.reducer;
