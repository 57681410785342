import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import clsx from 'clsx';
import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {energyConsumptionSlice} from '../../../state/features/energy-consumption/energy-consumption-slice';
import {AppState} from '../../../state/store';
import {basicCardBgColor, primaryColor} from '../../../styles/color-constants';
import {h6} from '../../../styles/font-size-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sortFlexbox: {
      cursor: 'pointer',
      marginLeft: 5,
      marginRight: 5,
      maxHeight: 40,
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
    },
    selected: {
      backgroundColor: primaryColor,
      color: basicCardBgColor,
      '&:hover': {
        backgroundColor: primaryColor,
        color: basicCardBgColor,
      },
    },
    typogrphy: {
      fontSize: h6,
    },
  }),
);

interface PropsFromParent {
  btnText: string;
}

export const ConsumptionPriorYearButton: FC<PropsFromParent> = React.memo(({children, ...props}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {yearPriorSelected} = useSelector((store: AppState) => store.energyConsumptionReducer);

  const handlePriorYearToggled = useCallback(() => {
    dispatch(energyConsumptionSlice.actions.setYearPriorSelected(!yearPriorSelected));
  }, [dispatch, yearPriorSelected]);

  return (
    <BasicCard
      className={clsx(yearPriorSelected ? classes.selected : null, classes.sortFlexbox)}
      onClick={handlePriorYearToggled}>
      <Typography className={classes.typogrphy}>{props.btnText}</Typography>
    </BasicCard>
  );
});
