import {useRouteMatch, Switch, Route} from 'react-router-dom';
import {SingleNewsContentPage} from './single-news-content-page';
import React, {FC} from 'react';

export const SingleNewsContentPageRoute: FC = React.memo(() => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={match.path}>
        <SingleNewsContentPage />
      </Route>
    </Switch>
  );
});
