import {BottomNavigation, BottomNavigationAction, createStyles, makeStyles, Theme, withStyles} from '@material-ui/core';
import {Menu} from '@material-ui/icons';
import React, {FC, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {useLayoutStyles} from '../../../app-routes';
import DashboardIconGrey from '../../../assets/icons/menu-icons/dashboard_grey.png';
import DashboardIconWhite from '../../../assets/icons/menu-icons/dashboard_white.png';
import EconomyIconGrey from '../../../assets/icons/menu-icons/economy_grey.png';
import EconomyIconWhite from '../../../assets/icons/menu-icons/economy_white.png';
import EnergyConsumptionIconGrey from '../../../assets/icons/menu-icons/energyconsumption_grey.png';
import EnergyConsumptionIconWhite from '../../../assets/icons/menu-icons/energyconsumption_white.png';
import GreenOptimizationIconGrey from '../../../assets/icons/menu-icons/greenoptimization_grey.png';
import GreenOptimizationIconWhite from '../../../assets/icons/menu-icons/greenoptimization_white.png';
import {toggleDrawer} from '../../../state/features/webapp/webapp-slice';
import {routes} from '../../../state/routes';
import {basicCardBoxShadow, navigationIconColor, primaryColor} from '../../../styles/color-constants';
import {GetIcon} from '../../../utilities/platform-helpers/icon-helper';

const StyledBottomNavigation = withStyles({
  root: {
    position: 'fixed',
    bottom: '0px',
    left: 0,
    width: 'calc(100vw)',
    boxShadow: `0px -4px 6pt ${basicCardBoxShadow}`,
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  // THIS might be used laster
  // root: {
  //   position: 'fixed',
  //   bottom: '0px',
  //   left: 0,
  //   width: 'calc(100vw - 70px)',
  //   boxShadow: `0px -4px 6pt ${basicCardBoxShadow}`,
  //   alignItems: 'center',
  //   justifyContent: 'flex-start',
  //   overflowY: 'hidden',
  // backgroundImage: `linear-gradient(to right, white, white),
  // linear-gradient(to right, white, white),
  // linear-gradient(to right, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0)),
  // linear-gradient(to left, rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0))`,
  // backgroundPosition: 'left center, right center, left center, right center',
  // backgroundRepeat: 'no-repeat',
  // backgroundColor: 'white',
  // backgroundSize: '20px 100%, 20px 100%, 10px 100%, 10px 100%',
  // backgroundAttachment: 'local, local, scroll, scroll',
  // },
})(BottomNavigation);

const StyledBottomNavigationAction = withStyles({
  root: {
    padding: '15px 5px 15px 5px',
    minWidth: 60,
    flex: 0,
    '&$selected': {
      padding: '15px 5px 15px 5px',
      '& .MuiBottomNavigationAction-label': {
        display: 'none',
      },
      '& .MuiBottomNavigationAction-wrapper': {
        height: 35,
        backgroundColor: primaryColor,
      },
    },
    '& .MuiBottomNavigationAction-wrapper': {
      height: 35,
      borderRadius: 10,
    },
  },
  selected: {},
})(BottomNavigationAction);

const StyledMenuBottomNavigationAction = withStyles({
  root: {
    padding: '15px 5px 15px 5px',
    minWidth: 60,
    flex: 0,
    '&$selected': {
      padding: '15px 5px 15px 5px',
      '& .MuiBottomNavigationAction-label': {
        display: 'none',
      },
      '& .MuiBottomNavigationAction-wrapper': {
        height: 35,
        backgroundColor: 'none',
      },
    },
    '& .MuiBottomNavigationAction-wrapper': {
      height: 35,
      borderRadius: 10,
    },
  },
  selected: {},
})(BottomNavigationAction);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuSelected: {
      color: 'white',
      fontSize: 30,
    },
    menu: {
      color: navigationIconColor,
      fontSize: 30,
    },
    menuIconContainer: {
      background: 'none',
    },
    // THIS might be used later
    // menuIconContainer: {
    //   position: 'absolute',
    //   bottom: 0,
    //   right: 0,
    //   padding: '13px 20px',
    //   display: 'flex',
    //   justifyContent: 'flex-end',
    //   backgroundColor: 'white',
    //   flex: 1,
    //   [theme.breakpoints.up('md')]: {
    //     display: 'none',
    //   },
    // },
    bottomDrawerContainer: {
      zIndex: 1000,
      left: 0,
      width: 'calc(100vw)',
      bottom: 0,
      position: 'fixed',
    },
  }),
);

const AppDrawer: FC = React.memo(() => {
  const [item, setItem] = useState<string>('');
  let history = useHistory();
  const classes = useStyles();
  let location = useLocation();
  const dispatch = useDispatch();
  const layoutClasses = useLayoutStyles();

  useEffect(() => {
    setItem(location.pathname);
  }, [location]);

  const onChangeEvent = (event: React.ChangeEvent<{}>, newValue: string) => {
    history.push(`${newValue}`);
    setItem(newValue);
  };

  const bottomNavigationIconHandler = (
    route: string,
    selectedIcon: string,
    notSelectedIcon: string,
    width = 25,
    height = 25,
  ) => {
    const isRoute = history.location;

    const icon = isRoute.pathname === route ? selectedIcon : notSelectedIcon;
    return GetIcon(icon, width, height);
  };

  const toggleDrawerOnClick = () => {
    dispatch(toggleDrawer());
  };

  return (
    <div className={classes.bottomDrawerContainer}>
      <StyledBottomNavigation
        value={item}
        onChange={onChangeEvent}
        showLabels={false}
        className={layoutClasses.mobileOnly}>
        <StyledBottomNavigationAction
          showLabel={false}
          value={routes.dashboard}
          icon={bottomNavigationIconHandler(routes.dashboard, DashboardIconWhite, DashboardIconGrey, 25, 22)}
        />
        <StyledBottomNavigationAction
          showLabel={false}
          value={routes.economy}
          icon={bottomNavigationIconHandler(routes.economy, EconomyIconWhite, EconomyIconGrey, 25, 20)}
        />
        <StyledBottomNavigationAction
          showLabel={false}
          value={routes.sustainableInitiatives}
          icon={bottomNavigationIconHandler(
            routes.sustainableInitiatives,
            GreenOptimizationIconWhite,
            GreenOptimizationIconGrey,
            22,
            33,
          )}
        />
        <StyledBottomNavigationAction
          showLabel={false}
          value={routes.energyConsumption}
          icon={bottomNavigationIconHandler(
            routes.energyConsumption,
            EnergyConsumptionIconWhite,
            EnergyConsumptionIconGrey,
            29,
            22,
          )}
        />
        <StyledMenuBottomNavigationAction
          value={history.location.pathname}
          icon={<Menu className={classes.menu} />}
          onClick={toggleDrawerOnClick}
        />
        {/* <StyledBottomNavigationAction
          showLabel={false}
          value={routes.contracts}
          icon={bottomNavigationIconHandler(routes.contracts, ContractIconWhite, ContractIconGrey)}
        />
        <StyledBottomNavigationAction
          showLabel={false}
          value={routes.documents}
          icon={bottomNavigationIconHandler(routes.documents, DocumentsIconWhite, DocumentsIconGrey)}
        />
        <StyledBottomNavigationAction
          showLabel={false}
          value={routes.news}
          icon={bottomNavigationIconHandler(routes.news, NewsIconWhite, NewsIconGrey)}
        />
        <StyledBottomNavigationAction
          showLabel={false}
          value={routes.eBooks}
          icon={bottomNavigationIconHandler(routes.eBooks, EbooksIconWhite, EbooksIconGrey)}
        />
        <StyledBottomNavigationAction
          showLabel={false}
          value={routes.contact}
          icon={bottomNavigationIconHandler(routes.contact, ContactIconGrey, ContactIconGrey)}
        />
        <StyledBottomNavigationAction
          showLabel={false}
          value={routes.userInfo}
          icon={
            <AccountCircleOutlinedIcon
              className={clsx({
                [classes.menuSelected]: history.location.pathname === routes.userInfo,
                [classes.menu]: history.location.pathname !== routes.userInfo,
              })}
            />
          }
        />

        {isAdmin() && (
          <StyledBottomNavigationAction
            showLabel={false}
            value={routes.userManagement}
            icon={
              <GroupIcon
                className={clsx({
                  [classes.menuSelected]: history.location.pathname === routes.userManagement,
                  [classes.menu]: history.location.pathname !== routes.userManagement,
                })}
              />
            }
          />
        )}
        {isGlobalAdmin() && (
          <StyledBottomNavigationAction
            showLabel={false}
            value={routes.organization}
            icon={
              <BusinessIcon
                className={clsx({
                  [classes.menuSelected]: history.location.pathname === routes.organization,
                  [classes.menu]: history.location.pathname !== routes.organization,
                })}
              />
            }
          />
        )} */}
      </StyledBottomNavigation>
    </div>
  );
});

export default AppDrawer;
