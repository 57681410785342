import {Box, BoxProps, createStyles, makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';
import React, {FC} from 'react';
import {basicCardBgColor, basicCardBoxShadow} from '../../../../styles/color-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    responseiveBox: {
      transition: 'color 0.1s ease-in-out, background-color 0.1s ease-in-out',
      [theme.breakpoints.down('sm')]: {
        margin: '5px 5px 5px 5px',
      },
      [theme.breakpoints.down('xs')]: {
        margin: '5px 0 5px 0',
      },
    },
  }),
);

export const BasicCard: FC<BoxProps> = React.memo((props) => {
  const classes = useStyles();
  return (
    <Box
      minWidth={40}
      position="relative"
      minHeight={40}
      //width="fit-content"
      bgcolor={basicCardBgColor}
      boxShadow={basicCardBoxShadow}
      p={2.5}
      m={2}
      borderRadius="10px"
      {...props}
      className={clsx(classes.responseiveBox, props.className)}>
      {props.children}
    </Box>
  );
});
