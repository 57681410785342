import {PaletteOptions} from '@material-ui/core/styles/createPalette';
import {
  backgroundColor,
  primaryColor,
  primaryTextColor,
  secondaryColor,
  secondaryTextColor,
} from '../../styles/color-constants';

export const DefaultPalette: PaletteOptions = {
  primary: {
    main: primaryColor,
  },
  secondary: {
    main: secondaryColor,
  },
  background: {
    default: backgroundColor,
  },
  text: {
    primary: primaryTextColor,
    secondary: secondaryTextColor,
  },
};
