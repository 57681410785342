import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import {ClassNameMap} from '@material-ui/styles';
import clsx from 'clsx';
import React, {FC} from 'react';
import {ListTableTextColor} from '../../../../styles/color-constants';
import {body2} from '../../../../styles/font-size-constants';
import {ListItemHeaderWidth, ListItemWidth} from '../../../../styles/styling-constants';
import {FlexRow} from '../../../components/default-components/flex-row';

interface PropsFromParent {
  columnSizes?: number[];
  headers: string[];
  maxWidth?: (number | undefined)[];
  minWidth?: number;
  width?: any;
  listHasScroll: boolean;
  textAlign?: 'center';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerContainer: (props: PropsFromParent) => ({
      height: 40,
      alignItems: '',
      width: props.listHasScroll ? ListItemHeaderWidth : ListItemWidth,
    }),
    headerText: {
      fontSize: body2,
      textAlign: (props: PropsFromParent) => (props.textAlign ? props.textAlign : 'left'),
      color: ListTableTextColor,
      fontWeight: 'bold',
    },
    firstColumnMargin: {
      margin: '0px -5px 0px 5px',
    },
    rightAlignColumMargin: {
      margin: '0px -30px 0px 30px',
    },
  }),
);

type Props = PropsFromParent;

export const ListTableHeader: FC<Props> = React.memo(
  ({columnSizes, headers, maxWidth, minWidth, listHasScroll, textAlign}) => {
    const classes = useStyles({listHasScroll, headers, textAlign});
    return (
      <FlexRow minWidth={minWidth} className={classes.headerContainer}>
        {headers.map(generateHeaders(columnSizes, maxWidth, headers, classes))}
      </FlexRow>
    );
  },
);
function generateHeaders(
  columnSizes: number[] | undefined,
  maxWidth: (number | undefined)[] | undefined,
  headers: string[],
  classes: ClassNameMap<'headerContainer' | 'headerText' | 'firstColumnMargin' | 'rightAlignColumMargin'>,
): (value: string, index: number, array: string[]) => JSX.Element {
  return (header, index) => {
    const flex = columnSizes && columnSizes[index];
    let width = maxWidth && maxWidth[index];
    if (!width && (index === 0 || index === headers.length - 1)) {
      width = 50;
    }
    return (
      <Box
        textAlign="left"
        key={index}
        className={clsx(
          classes.headerText,
          index === 0 ? classes.firstColumnMargin : null,
          index > 2 ? classes.rightAlignColumMargin : null,
        )}
        flex={flex}>
        {header}
      </Box>
    );
  };
}
