import {Box, Button, SvgIconTypeMap} from '@material-ui/core';
import {OverridableComponent} from '@material-ui/core/OverridableComponent';
import React, {FC, useCallback} from 'react';

interface PropsFromParent {
  text: string;
  onClick: (e: any) => void;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  containerClasses?: any;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  buttonColor?: 'inherit' | 'default' | 'primary' | 'secondary' | undefined;
}

type Props = PropsFromParent;

export const BasicButton: FC<Props> = React.memo(
  ({icon, onClick, text, containerClasses, disabled, type, buttonColor = 'primary'}) => {
    const onClickLocal = useCallback(
      (e) => {
        onClick(e);
      },
      [onClick],
    );

    return (
      <Box className={containerClasses}>
        <Button
          type={type}
          disabled={disabled}
          onClick={onClickLocal}
          variant="contained"
          color={buttonColor}
          startIcon={icon && React.createElement(icon)}>
          {text}
        </Button>
      </Box>
    );
  },
);
