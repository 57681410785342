import {createStyles, Grid, List, makeStyles, Theme, Typography} from '@material-ui/core';
import clsx from 'clsx';
import React, {FC, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {IContractDto} from '../../../api/api';
import {useLayoutStyles} from '../../../app-routes';
import {localized} from '../../../i18n/i18n';
import {TitleAndSubCard} from '../../../view/components/default-components/title-and-sub-card';
import {BasicCircularProgress} from '../basic-circular-progress';
import {BasicNoDocuments} from '../basic-no-documents';
import {BasicDocumentList} from '../document/basic-document-list';
import {BasicDocumentListHeader} from '../document/basic-document-list-header';
import {BasicDocumentListItemMapSpacer} from '../document/basic-document-list-item-map-spacer';
import {SortType} from '../document/basic-sort-type';
import {useDocumentFilter} from '../filterprovider';
import {ContractListItem} from './contract-list-item';

interface PropsFromParent {
  title: string;
  parentContracts: IContractDto[] | undefined;
  pending: boolean;
  dateFrom?: Date;
  dateTo?: Date;
  status?: boolean;
  cardHeight?: number;
  docuNumber?: number;
  contractPage?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstCategory: {
      margin: 0,
    },
    secoundCategory: {
      marginLeft: 20,
    },
    secoundCategoryContractPage: {
      marginLeft: 0,
    },
    thirdCategory: {
      marginLeft: 30,
    },
    thirdCategoryContractPage: {
      marginLeft: -10,
    },
    contractCardContainer: {
      paddingLeft: 5,
      paddingRight: 5,
      marginLeft: -5,
      marginRight: -5,
      maxHeight: '500px',
      overflow: 'auto',
    },
    spaceBetweenTitleAndData: {
      marginBottom: 20,
    },
  }),
);

export const ContractCard: FC<PropsFromParent> = React.memo(
  ({title, parentContracts, pending, dateFrom, dateTo, status, cardHeight, docuNumber, contractPage}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [filteredContracts, setFilteredContracts] = useState<IContractDto[] | undefined>(parentContracts);
    const {sortProp} = useDocumentFilter();
    const layoutStyle = useLayoutStyles();

    useEffect(() => {
      let tempArray: IContractDto[] | undefined;

      if (dateFrom === undefined && dateTo === undefined) {
        tempArray = parentContracts;
      } else {
        tempArray =
          parentContracts &&
          parentContracts.filter(
            (doc) =>
              (doc.contractStart.valueOf()! >= dateFrom?.valueOf()! &&
                doc.contractEnd.valueOf()! <= dateTo?.valueOf()!) ||
              (dateFrom?.valueOf()! < doc.contractEnd.valueOf()! && doc.contractEnd.valueOf()! <= dateTo?.valueOf()!),
          );
      }
      tempArray &&
        tempArray.sort(
          (a, b) =>
            (a.active === b.active ? 0 : a.active ? -1 : 1) || a.contractStart.valueOf()! - b.contractStart.valueOf()!,
        );

      setFilteredContracts(tempArray);

      if (!contractPage) {
        if (sortProp === 'date' || sortProp === '') {
          setFilteredContracts(
            tempArray && [...tempArray].sort((a, b) => b.contractEnd.valueOf()! - a.contractEnd.valueOf()!),
          );
        } else {
          setFilteredContracts(
            tempArray &&
              [...tempArray].sort(
                (a, b) =>
                  b.productCategory.toString().localeCompare(a.productCategory.toString()) ||
                  b.productSubcategory.toString().localeCompare(a.productSubcategory.toString()),
              ),
          );
        }
      }
    }, [dispatch, parentContracts, dateFrom, dateTo, sortProp, contractPage]);

    return (
      <BasicDocumentList>
        <Grid
          className={classes.spaceBetweenTitleAndData}
          container
          direction="row"
          justify="space-between"
          alignItems="center">
          <TitleAndSubCard title={title} />
          {!contractPage && <SortType />}
        </Grid>
        {pending ? (
          <BasicCircularProgress />
        ) : (
          <>
            {filteredContracts === undefined || filteredContracts!.length === 0 ? (
              <BasicNoDocuments />
            ) : (
              <>
                <BasicDocumentListHeader className={layoutStyle.desktopAndLargeMobile}>
                  <Grid item xs={contractPage ? 7 : 6}>
                    <Typography className={classes.firstCategory}>{localized('Document')} </Typography>
                  </Grid>
                  <Grid item xs={contractPage ? 4 : 3}>
                    <Typography
                      className={clsx(contractPage ? classes.secoundCategoryContractPage : classes.secoundCategory)}>
                      {localized('Period')}
                    </Typography>
                  </Grid>
                  <Grid item xs={contractPage ? 1 : 2}>
                    <Typography
                      className={clsx(contractPage ? classes.thirdCategoryContractPage : classes.thirdCategory)}>
                      {localized('Status')}
                    </Typography>
                  </Grid>
                </BasicDocumentListHeader>
                <BasicDocumentListItemMapSpacer documentNumber={docuNumber!}>
                  <List className={classes.contractCardContainer}>
                    {filteredContracts &&
                      filteredContracts.map((filteredContract) => {
                        return (
                          <ContractListItem
                            key={filteredContract.documentId}
                            contract={filteredContract}
                            contractPage={contractPage}
                          />
                        );
                      })}
                  </List>
                </BasicDocumentListItemMapSpacer>
              </>
            )}
          </>
        )}
      </BasicDocumentList>
    );
  },
);
