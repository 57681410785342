import { createAsyncThunk } from '@reduxjs/toolkit';
import { NewsClient } from '../../../api/api';
import { AsyncOperationHandler } from '../../../utilities/platform-helpers/api-helper';
import { newsClient } from '../../api-clients';
export const GetAllNewsItems = createAsyncThunk('news/getAllNewsItems', () => {
  return AsyncOperationHandler((client) => (client as NewsClient).getNews(), newsClient);
});

export const GetSingleBlog = createAsyncThunk('news/getSingleBlog', (blog: string) => {
  return AsyncOperationHandler((client) => (client as NewsClient).getSingleBlog(blog), newsClient);
});

