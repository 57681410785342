import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, {useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import QuestionMark from '../../../assets/icons/question_mark.svg';
import {localized} from '../../../i18n/i18n';
import {h5} from '../../../styles/font-size-constants';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subTextBorderBottom: {
      fontSize: h5,
      margin: '0 16px 0 5px',
      lineHeight: '26px',
      maxWidth: '648px',
      borderBottom: '1px solid black',
    },
    documentIcon: {
      width: '21px',
      height: '26px',
    },
    flexDirectionRow: {
      marginRight: 10,
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      '&:hover': {
        color: '#3333',
      },
    },
    dialog: {},
  }),
);

export const HowToReadInvoice = React.memo(() => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [numPages, setNumPages] = useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLoadSuccess = ({numPages}: any) => setNumPages(numPages)

  return (
    <>
      <div className={classes.flexDirectionRow} onClick={handleClickOpen}>
        <img src={QuestionMark} className={classes.documentIcon} alt={'Document-icon-black'} />
        <Typography className={classes.subTextBorderBottom}>{localized('HowToReadInvoice')}</Typography>
      </div>
      <Dialog
        maxWidth="xl"
        className={classes.dialog}
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">{localized('HowToReadInvoice')}</DialogTitle>
        <DialogContent>
          <Document file={'/Faktura_eksempel_december21.pdf'} onLoadSuccess={handleLoadSuccess}>
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((page) => (
                <Page pageNumber={page} scale={1.5} key="" />
              ))}
          </Document>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {localized('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
