import {useRouteMatch, Switch, Route} from 'react-router-dom';
import {Dashboard} from './dashboard';
import React, {FC} from 'react';

export const DashboardRoute: FC = React.memo(() => {
  let match = useRouteMatch();
  return (
    <Switch>
      <Route path={match.path}>
        <Dashboard />
      </Route>
    </Switch>
  );
});
