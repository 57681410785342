import {Button, createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {localized} from '../../../i18n/i18n';
import {renewContract} from '../../../state/features/contract/operation';
import {AppState} from '../../../state/store';
import {primaryColor, primaryTextColor, whiteTextColor} from '../../../styles/color-constants';
import {h5} from '../../../styles/font-size-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    renewButton: {
      backgroundColor: primaryColor,
      borderRadius: '10px',
      textTransform: 'none',
      padding: '0 20px',
      color: whiteTextColor,
      '&:hover': {
        color: primaryTextColor,
      },
    },
    buttonText: {
      fontSize: h5,
    },
    arrowIcon: {
      margin: '10px 0 0 0',
    },
  }),
);

export const RenewContractButton: FC = React.memo(({children}) => {
  const classes = useStyles();
  const {organizationId} = useSelector((store: AppState) => store.organizationReducer);
  const {selectedContract} = useSelector((store: AppState) => store.selectedContractsReducer);
  const handleButtonClick = () => {
    if (selectedContract) {
      if (organizationId) {
        renewContract(organizationId, selectedContract);
      }
    }
  };
  return (
    <Button className={classes.renewButton} onClick={handleButtonClick}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={10}>
          <Typography className={classes.buttonText}>{localized('RenewContract')}</Typography>
        </Grid>
        <Grid item xs={2}>
          <KeyboardArrowRightIcon className={classes.arrowIcon} />
        </Grid>
      </Grid>
      {children}
    </Button>
  );
});
