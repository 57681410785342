import {Action, configureStore, getDefaultMiddleware, Reducer, ThunkAction} from '@reduxjs/toolkit';
import {PersistConfig, persistReducer} from 'redux-persist';
import {PersistPartial} from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import {Monitor} from '../services/telemetry-service';
import {isTokenExpired} from '../utilities/platform-helpers/auth-helper';
import authReducerBase, {setTriggerSignOut} from './features/auth/auth-slice';
import contactsReducer from './features/contact/contact-slice';
import selectedContractsReducer from './features/contract/contract-selected-slice';
import contractsReducer from './features/contract/contract-slice';
import documentsReducer from './features/document/document-slice';
import energyConsumptionReducer from './features/energy-consumption/energy-consumption-slice';
import greenOptimizationReducer from './features/green-optimization/green-optimization-slice';
import debtorPostingReducer from './features/invoice/invoice-slice';
import organizationReducer from './features/organization/organization-slice';
import pageSettingReducerBase from './features/page-header-setting/page-header-setting-slice';
import snackbarReducer from './features/snackbar/snackbar-slice';
import statusMessageReducer from './features/status-message/status-messages-slice';
import userReducer from './features/user/user-slice';
import webAppReducerBase from './features/webapp/webapp-slice';
import newsReducer from './features/news/news-slice';
import marketingMaterialReducer from './features/marketing-material/marketing-material-slice';

export type AppState = ReturnType<typeof store.getState>;

export const authInterceptor = (dispatch: any) => (next: any) => (action: any) => {
  if (action.error && action.error.code === '401') {
    //Handle unauthorized
    if (isTokenExpired()) {
      Monitor.logTrace('Unauthorized: Token has expired');
    } else {
      Monitor.logTrace("Unauthorized: User don't have the requiered permissions");
    }
    store.dispatch(setTriggerSignOut(true));
    //store.dispatch(setNewToken(''));
  } else {
    return next(action);
  }
};

const createPersistReducer = <T extends Reducer<any, Action<any>>>(config: PersistConfig<any>, reducer: T) => {
  return persistReducer(config, reducer) as typeof reducer & PersistPartial;
};

const customizedMiddleware = getDefaultMiddleware({
  // This is needed because Date objects are considered non-serializable
  serializableCheck: false,
  immutableCheck: false,
});

// Make sure that authInterceptor is the first middleware to be executed to ensure unwanted sideeffects
customizedMiddleware.unshift(authInterceptor);

// Create persisted reducers
const webappPersistConfig: PersistConfig<any> = {
  key: 'webAppReducer',
  storage,
  whitelist: ['open'],
  blacklist: ['showOverlaySpinner'],
};
const webAppReducer = createPersistReducer(webappPersistConfig, webAppReducerBase);

const pageSettingPersistConfig: PersistConfig<any> = {
  key: 'pageSettingReducer',
  storage,
  whitelist: ['pageSettings'],
};

const pageSettingReducer = createPersistReducer(pageSettingPersistConfig, pageSettingReducerBase);

const authPersistConfig: PersistConfig<any> = {
  key: 'authReducer',
  storage,
  whitelist: ['token'],
};
const authReducer = createPersistReducer(authPersistConfig, authReducerBase);

export const store = configureStore({
  reducer: {
    snackbarReducer,
    authReducer,
    webAppReducer,
    pageSettingReducer,
    statusMessageReducer,
    greenOptimizationReducer,
    energyConsumptionReducer,
    organizationReducer,
    documentsReducer,
    contractsReducer,
    contactsReducer,
    selectedContractsReducer,
    userReducer,
    debtorPostingReducer,
    newsReducer,
    marketingMaterialReducer,
  },
  middleware: customizedMiddleware,
});
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>;
