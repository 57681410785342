import {Box, createStyles, Grid, Hidden, makeStyles, Theme, Typography} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import clsx from 'clsx';
import React, {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLayoutStyles} from '../../app-routes';
import {localized} from '../../i18n/i18n';
import {getContactsDetails, getCustomerServiceInfo} from '../../state/features/contact/operation';
import {AppState} from '../../state/store';
import {basicCardBgColor, secondaryTextColor, speechBubbleShadow} from '../../styles/color-constants';
import {h5, h6} from '../../styles/font-size-constants';
import PersonIcon from '@material-ui/icons/Person';
import {routes} from '../../state/routes';
import {useState} from 'react';
import {useLocation} from 'react-router-dom';
const useStylesBox = makeStyles((theme: Theme) =>
  createStyles({
    spacer: {
      height: 175,
    },
    box: {
      minWidth: 170,
      position: 'fixed',
      right: '40px',
      bottom: '60px',
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        bottom: '70px',
      },
      // [theme.breakpoints.up('md')]: {
      //   right: '40px',
      //   bottom: '60px',
      // },
    },
    speechBubbleBox: {
      margin: 0,
      padding: 10,
      boxShadow: speechBubbleShadow,
      borderRadius: 10,
      backgroundColor: basicCardBgColor,
    },
    smSpeechBubble: {
      width: 130,
      cursor: 'pointer',
    },
    image: {
      height: '110px',
      maxWidth: '165px',
      borderRadius: '10px',
      boxShadow: speechBubbleShadow,
      position: 'relative',
      alignSelf: 'flex-end',
      cursor: 'pointer',
    },
    arrowDown: {
      width: 0,
      height: 0,
      marginRight: 70,
      alignSelf: 'flex-end',
      borderLeft: '10px solid transparent',
      borderRight: '10px solid transparent',
      borderTop: '10px solid ' + basicCardBgColor,
    },
    cancelIcon: {
      fontSize: 'medium',
      color: secondaryTextColor,
      cursor: 'pointer',
    },
    personIcon: {
      fontSize: 50,
      color: '#D3D3D3',
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textHeader: {
      fontWeight: 'bold',
      fontSize: h5,
      color: secondaryTextColor,
      margin: '5px',
    },
    text: {
      fontSize: h6,
      color: secondaryTextColor,
      margin: '10px 5px 0 5px',
    },
    textBold: {
      fontSize: h6,
      color: secondaryTextColor,
      fontWeight: 'bold',
      margin: '0 0 0 5px',
    },
    textBoldUnderline: {
      fontSize: h6,
      color: secondaryTextColor,
      fontWeight: 'bold',
      textDecorationLine: 'underline',
      margin: '0 0 0 5px',
    },
  }),
);

interface contactData {
  selectedContact: number;
  contactText: string;
}

export const SupportContact: FC = () => {
  const boxClasses = useStylesBox();
  const textClasses = useStyles();
  const useLayout = useLayoutStyles();
  const location = useLocation();
  const [checked, setChecked] = React.useState(true);
  const [contactLayout, setContactLayout] = useState<contactData>({
    selectedContact: 0,
    contactText: localized('CustomerServiceIsReadyToHelpYou'),
  });
  const handleChange = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setChecked((prev) => !prev);
  };

  const dispatch = useDispatch();
  // We use this component on every page, therefore it needs data
  const {organizationId} = useSelector((store: AppState) => store.organizationReducer);
  useEffect(() => {
    if (organizationId) {
      dispatch(getContactsDetails(organizationId));
    }
    dispatch(getCustomerServiceInfo());
  }, [dispatch, organizationId]);

  const {contactsSlice} = useSelector((store: AppState) => store.contactsReducer);

  useEffect(() => {
    if (location.pathname === routes.economy) {
      setContactLayout({selectedContact: 1, contactText: localized('SettlementAndFinancesAreReadyToHelpYou')});
    } else {
      setContactLayout({selectedContact: 0, contactText: localized('CustomerServiceIsReadyToHelpYou')});
    }
  }, [location]);

  return (
    <Grid container direction="row" justify="flex-end" alignItems="center" className={boxClasses.spacer}>
      <Grid item xs>
        <Box className={boxClasses.box}>
          <>
            {checked ? (
              <Box className={clsx(boxClasses.speechBubbleBox, boxClasses.smSpeechBubble)} onClick={handleChange}>
                <Typography className={textClasses.textHeader}>{localized('NeedHelp')}</Typography>
              </Box>
            ) : (
              <Box className={boxClasses.speechBubbleBox}>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography className={textClasses.textHeader}>{localized('NeedHelp')}</Typography>
                  <div onClick={handleChange}>
                    <CancelIcon className={boxClasses.cancelIcon} />
                  </div>
                </Box>
                <Typography className={textClasses.text}>{contactLayout.contactText}</Typography>
                <Typography className={textClasses.text}>{'Tlf:'}</Typography>
                {contactsSlice[contactLayout.selectedContact]?.phoneNumber ? (
                  <Typography className={textClasses.textBold}>
                    {'+45 ' +
                      contactsSlice[contactLayout.selectedContact]?.phoneNumber?.slice(0, 4) +
                      ' ' +
                      contactsSlice[contactLayout.selectedContact]?.phoneNumber?.slice(4, 8)}
                  </Typography>
                ) : null}
                <Typography className={textClasses.text}>{'Email:'}</Typography>
                <Typography className={textClasses.textBoldUnderline}>{contactsSlice[contactLayout.selectedContact]?.email}</Typography>
              </Box>
            )}
            <div className={clsx(boxClasses.arrowDown, useLayout.desktopOnly)}></div>
            <Hidden only={['xs', 'sm']}>
              {!contactsSlice[0]?.image ? (
                <Box onClick={handleChange} alignSelf={'flex-end'}>
                  <PersonIcon className={clsx(boxClasses.personIcon, boxClasses.image)} />
                </Box>
              ) : (
                <img
                  src={`data:image/jpeg;base64,${contactsSlice[contactLayout.selectedContact]?.image}`}
                  className={boxClasses.image}
                  alt="Customer service"
                  onClick={handleChange}
                />
              )}
            </Hidden>
          </>
        </Box>
      </Grid>
    </Grid>
  );
};
