import {Box, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {localized} from '../../../i18n/i18n';
import {getContactsDetails, getCustomerServiceInfo} from '../../../state/features/contact/operation';
import { AppState } from '../../../state/store';
import {h5} from '../../../styles/font-size-constants';
import {PageContentContainer} from '../../../view/components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../view/components/default-components/page-header.tsx/page-header';
import {PageTitle} from '../../../view/components/default-components/page-title/page-title';
import {ContactCards} from '../../components/contact/contact-cards';
import {OpeningHoursCard} from '../../components/contact/opening-hours-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subText: {
      fontSize: h5,
      margin: '0 0 20px 0px',
      [theme.breakpoints.up('md')]: {
        marginLeft: '16px',
      },
    },
    flexBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
    },
    openingHoursCard: {
      textAlign: 'center',
      minWidth: 350,
      maxWidth: 350,
    },
    daysText: {
      fontWeight: 'bold',
    },
  }),
);

export const Contact = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // Fetch page data
  const {organizationId} = useSelector((store: AppState) => store.organizationReducer);
  useEffect(() => {
    if (organizationId) {
      dispatch(getContactsDetails(organizationId));
    }
    dispatch(getCustomerServiceInfo());
  }, [dispatch, organizationId]);

  return (
    <PageTitle title="Contact">
      <PageHeader area="template" title={localized('Contact')}></PageHeader>
      <PageContentContainer>
        <Box>
          <Typography className={classes.subText}>{localized('AtScanenergiWeStriveFor')}</Typography>
          <Typography className={classes.subText}>
            {localized('BelowYouCanSeeWhichDepartmentsYouCanContact')}
          </Typography>
          <Typography className={classes.subText}>{localized('AllDepartmentsAreOpenDuringThePeriod')}</Typography>
        </Box>
        <OpeningHoursCard />
        <ContactCards />
      </PageContentContainer>
    </PageTitle>
  );
});
