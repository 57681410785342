import {createMuiTheme} from '@material-ui/core/styles';
import {DefaultPalette} from '../utilities/platform-helpers/colors-helper';

export function colorTheme() {
  let theme = createMuiTheme({
    palette: DefaultPalette,
    typography: {
      fontFamily: ['Lato', 'Roboto'].join(','),
    },
    breakpoints: {
      keys: ['xs', 'sm', 'm', 'md', 'lg', 'xl', 'betweenlgandxl'],
      values: {
        xs: 0,
        sm: 600,
        m: 750,
        md: 960,
        lg: 1280,
        betweenlgandxl: 1700,
        xl: 1920,
      },
    },
  });
  return theme;
}
