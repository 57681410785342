import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useAuth } from "../../../../auth/general-auth-provider";
import { AppState } from '../../../../state/store';

export const LogoutAuth: FC = (props) => {
  const { logout } = useAuth();
  const signOut = () => logout({returnTo: window.location.origin});
  const shouldLogOut = useSelector((state: AppState) => state.authReducer.logout);

  if (shouldLogOut === true) {
    signOut();
  }

  return null;
};
