import {Box, Grid} from '@material-ui/core';
import clsx from 'clsx';
import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import people from '../../../assets/icons/people.svg';
import person_add from '../../../assets/icons/person_add.svg';
import {localized} from '../../../i18n/i18n';
import {GridWithCustomBreakpoints} from '../../../styles/custom-grid-breakpoints';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {TextButton} from '../../../view/components/default-components/buttons/text-button';
import {TitleAndSubCard} from '../../../view/components/default-components/title-and-sub-card';
import { useEconomyStyles } from '../economy/invoice-table-overview';
import {userInfoUseStyles} from '../user-info/user-info-helper';
import {CreateUserModal} from './create-user-modal';
import {UserManagementTable} from './user-management-table';

export const UserManagementCard: FC = React.memo(() => {
  const classes = userInfoUseStyles();
  const economyClasses = useEconomyStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(divRef.current?.clientWidth || 1000);

  const onCreateAccountClicked = useCallback(() => {
    setModalOpen(modalOpen ? false : true);
  }, [modalOpen, setModalOpen]);

   // handles scroll bar, to align tableheader and tablerow
   useEffect(() => {
    const handler = () => {
      if (divRef && divRef.current) {
        const w = divRef.current.clientWidth;

        // Set the minWidth, according to element width - 42px equals icon width with margin
        if (w >= 1000 && width !== w) {
          setWidth(w - 42);
        }
      }
    };

    handler();
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
    // eslint-disable-next-line
  }, [width, divRef]);

  return (
    <BasicCard className={classes.cardClass} overflow={'auto'}>
      <Box display={'flex'}>
        <Box className={classes.cardIcon}>
          <img src={people} width="32px" height="23px" alt="firm-icon" />
        </Box>
        <Box className={classes.cardText}>
          <Grid container direction={'row'}>
            <GridWithCustomBreakpoints item xs={6} sm={6} md={6}>
              <TitleAndSubCard title={localized('UserManagement')} subTitle={undefined} />
            </GridWithCustomBreakpoints>
            <GridWithCustomBreakpoints item xs={4} sm={6} md={6}>
              <Box className={classes.buttonFlexBox}>
                <div className={clsx(classes.button, classes.noTopMargin)} onClick={onCreateAccountClicked}>
                  <img src={person_add} width="20px" height="20px" alt="firm-icon" />
                  <TextButton>{localized('CreateUser')}</TextButton>
                </div>
              </Box>
            </GridWithCustomBreakpoints>
          </Grid>
        </Box>
      </Box>
      <Box className={clsx(classes.boxClass, economyClasses.boxStyle)} overflow={'auto'}>
        <UserManagementTable minWidth={width} />
      </Box>
      <CreateUserModal open={modalOpen} onClose={onCreateAccountClicked} />
    </BasicCard>
  );
});
