import {useRouteMatch, Switch, Route} from 'react-router-dom';
import {GreenOptimization} from './green-optimization';
import React, {FC} from 'react';

export const GreenOptimizationRoute: FC = React.memo(() => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path}>
        <GreenOptimization />
      </Route>
    </Switch>
  );
});
