import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IContractDto} from '../../../api/api';
import {contractNoEndDate, sameDate} from '../../../utilities/helpers/date-helpers';
import {ContractsState} from '../contract/types';
import {setSelectedOrganization} from '../organization/organization-slice';
import {getContractsDetails} from './operation';

const initialState: ContractsState = {
  contractsSlice: [] as IContractDto[],
  currentActiveContract: undefined,
  pending: false,
  isSuccess: false,
};

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    setCurrentContract: (state, action: PayloadAction<IContractDto>) => {
      if (action?.payload) {
        state.currentActiveContract = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    // Get
    builder.addCase(getContractsDetails.fulfilled, (state, action) => {
      state.pending = false;
      state.contractsSlice = action.payload ? action.payload : [];
      state.currentActiveContract =
        state.contractsSlice.length > 0
          ? state.contractsSlice
              .filter((a) => !sameDate(a.contractEnd, contractNoEndDate))
              .sort((a, b) => b.contractEnd.valueOf()! - a.contractEnd.valueOf()!)[0]
          : undefined;
    });

    builder.addCase(getContractsDetails.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getContractsDetails.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(setSelectedOrganization, (state, action) => {
      if (action.payload.previousOrganizationId !== undefined) {
        return initialState;
      }
    });
  },
});

// eslint-disable-next-line
export const {} = contractsSlice.actions;

export default contractsSlice.reducer;
