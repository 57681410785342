import React, {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {IUser, User} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {AppState} from '../../../state/store';
import {ScanEnergyTable} from '../table/table';
import {DeleteUserFromOrganizationButton} from './delete-user-from-organization-button';
import {ResendInvitationButton} from './resend-invitation-button';

const invoiceTableColumnSizes = () => [30, 50, 30, 20, 30, 30, 10];
const generateColumnHeaders = () => [
  localized('Name'),
  localized('Email'),
  localized('PhoneNumber'),
  localized('Title'),
  localized('InviteSent'),
  localized('InviteAccepted'),
  ' ',
];

const keysToRender = [
  'name',
  'email',
  'phonenumber',
  'title',
  'inviteSent',
  'inviteAccepted',
  'deleteButton',
  'resendInvitationButton',
] as (keyof User)[];

interface PropsFromParent {
  minWidth: number;
}

export const UserManagementTable: FC<PropsFromParent> = React.memo(({minWidth}) => {
  const userOrganizations = useSelector((state: AppState) => state.userReducer.userOrganizations);
  const [columnSizes] = useState(invoiceTableColumnSizes());
  const [columnHeaders] = useState(generateColumnHeaders());
  const [items, setItems] = useState<UserOrganizationsTableItem[]>([]);

  useEffect(() => {
    const itemsToUse: UserOrganizationsTableItem[] = [];
    if (userOrganizations) {
      [...userOrganizations].forEach((elemen: UserOrganizationsTableItem) => {
        const elementCopy = {...elemen};
        elementCopy.deleteButton = <DeleteUserFromOrganizationButton user={elemen} />;
        elementCopy.resendInvitationButton = <ResendInvitationButton user={elemen as User} />;
        itemsToUse.push(elementCopy);
      });
    }
    setItems(itemsToUse);
  }, [userOrganizations, setItems]);

  return (
    <>
      {items && (
        <ScanEnergyTable
          maxHeight={200}
          minWidth={minWidth}
          items={items}
          textAlign={'center'}
          keysToRender={keysToRender}
          columnSizes={columnSizes}
          columnHeaders={columnHeaders}
        />
      )}
    </>
  );
});

interface UserOrganizationsTableItem extends IUser {
  deleteButton?: JSX.Element;
  resendInvitationButton?: JSX.Element;
}
