import {Box, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import clsx from 'clsx';
import React, {Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IUnitDto} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {energyConsumptionSlice} from '../../../state/features/energy-consumption/energy-consumption-slice';
import {AppState} from '../../../state/store';
import {
  basicCardBgColor,
  primaryColor,
  tooltipPrimaryTextColor,
  tooltipSecondaryTextColor,
} from '../../../styles/color-constants';
import {h6} from '../../../styles/font-size-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {BasicTooltip} from './basic-tooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sortFlexbox: {
      cursor: 'pointer',
      margin: 6,
      padding: 0,
      display: 'flex',
      textAlign: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
    },
    selected: {
      cursor: 'pointer',
      backgroundColor: primaryColor,
      color: basicCardBgColor,
      '&:hover': {
        backgroundColor: primaryColor,
        color: basicCardBgColor,
      },
    },
    toolTipValueText: {
      fontWeight: 'bold',
      color: tooltipPrimaryTextColor,
    },
    toolTipDateText: {
      fontSize: h6,
      color: tooltipSecondaryTextColor,
    },
    toolTipBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  }),
);

export const getConnectionIds = (units: IUnitDto[]) => {
  let ids: string[] = [];
  units
    .filter((thing, i, arr) => arr.findIndex((t) => t.connectionid! === thing.connectionid!) === i)
    .forEach((element) => ids.push(element.connectionid!));
  return ids;
};

interface PropsFromParent {
  numberOfUnitsCallback: Dispatch<SetStateAction<number>>;
}

export const ConsumptionUnits: FC<PropsFromParent> = React.memo(({children, ...props}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firstUpdate = useRef(true);
  const {
    selectedMeasuringPointIds,
    units,
    idsInConsumption,
    idsInConsumptionOneYearPrior,
    yearPriorSelected,
  } = useSelector((store: AppState) => store.energyConsumptionReducer);

  const handleCheckSinglePressed = useCallback(
    (newId: string) => {
      if (selectedMeasuringPointIds.includes(newId)) {
        let newIds = selectedMeasuringPointIds.filter((item) => {
          return item !== newId;
        });
        dispatch(energyConsumptionSlice.actions.setSelectedMeasuringPointIds(newIds));
      } else {
        let newIds: string[] = [...selectedMeasuringPointIds, newId];
        dispatch(energyConsumptionSlice.actions.setSelectedMeasuringPointIds(newIds));
      }
    },
    [dispatch, selectedMeasuringPointIds],
  );

  const unitsToShow = useMemo(() => {
    let ids = yearPriorSelected ? [...idsInConsumption, ...idsInConsumptionOneYearPrior] : idsInConsumption;
    let relevantUnits = units
      .filter(
        (unit, i, unitList) =>
          unitList.findIndex((t) => t.connectionid === unit.connectionid) === i && ids.includes(unit.connectionid!),
      )
      .sort((a, b) => (a.street! < b.street! ? -1 : a.houseNum! > b.houseNum! ? 1 : 0));

    props.numberOfUnitsCallback(relevantUnits.length);
    // Todo: this list in store when reset.
    return relevantUnits;
  }, [units, idsInConsumption, idsInConsumptionOneYearPrior, yearPriorSelected, props]);

  // First time select all units
  useEffect(() => {
    if (firstUpdate.current && [...idsInConsumption].length !== 0) {
      firstUpdate.current = false;

      dispatch(energyConsumptionSlice.actions.setSelectedMeasuringPointIds([...idsInConsumption]));
    }
  }, [dispatch, idsInConsumption]);

  return (
    <Box maxHeight={440} overflow={'auto'}>
      {unitsToShow &&
        unitsToShow.map((unit, index) => {
          return (
            <BasicTooltip
              key={index}
              placement="right"
              title={
                <Box className={classes.toolTipBox}>
                  <Typography className={classes.toolTipValueText}>{unit.eanId}</Typography>
                  <Typography className={classes.toolTipDateText}>{localized('EanNumber')}</Typography>
                </Box>
              }>
              <BasicCard
                className={clsx(
                  classes.sortFlexbox,
                  selectedMeasuringPointIds.includes(unit.connectionid!) ? classes.selected : null,
                )}
                onClick={() => handleCheckSinglePressed(unit.connectionid!)}>
                {unit.street + ' ' + unit.houseNum}
              </BasicCard>
            </BasicTooltip>
          );
        })}
    </Box>
  );
});
