import {createAsyncThunk} from '@reduxjs/toolkit';
import {MarketingMaterialsClient} from '../../../api/api';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {marketingMaterialsClient} from '../../api-clients';

export const getMarketingMaterials = createAsyncThunk(
  'MarketingMaterials/getMarketingMaterialsByOrganizationId',

  (input: {organizationId: number}) => {
    return AsyncOperationHandler(
      (client) => (client as MarketingMaterialsClient).getMarketingMaterialsByOrganizationId(input.organizationId),
      marketingMaterialsClient,
    );
  },
);

export async function downloadMarketingMaterial(MaterialIds: number[], organizationId: number) {
  return AsyncOperationHandler(
    (client) => (client as MarketingMaterialsClient).getMarketingMaterialsByIds(MaterialIds, organizationId),
    marketingMaterialsClient,
  );
}
