import React, {FC, useCallback, useContext} from 'react';

interface PropsFromParent {
  validationMethod: () => boolean;
}

export const RequiredContext = React.createContext({} as any);
export const useRequired = () => useContext(RequiredContext);

export const RequiredWrapper: FC<PropsFromParent> = React.memo(({children, validationMethod}) => {
  const [showError, setShowError] = React.useState(false);

  const validate = useCallback(() => {
    const isValid = validationMethod();
    setShowError(!isValid);
    return isValid;
  }, [validationMethod]);

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };
  return (
    <RequiredContext.Provider value={{validate, showError}}>
      <form onSubmit={submitHandler}>{children}</form>
    </RequiredContext.Provider>
  );
});
