import { SerializedError } from '@reduxjs/toolkit';
import { localizedDynamic } from '../../i18n/i18n';
import { ApiClient } from '../../state/api-clients';
import { showErrorSnackbar } from '../../state/features/snackbar/snackbar-slice';
import { store } from '../../state/store';
import { isJson } from './json-helper';

// Used for createAsyncThunk
export const AsyncOperationHandler = async <TApiReturn>(
  apiCall: (cl: ApiClient) => Promise<TApiReturn>,
  client: ApiClient,
  showResponseMessage: boolean = false,
  callbackOnSuccess?: () => void,
) => {
  const dispatch = store.dispatch;
  try {
    const result = await apiCall(client);

    // Call when api call returned successfully
    if(callbackOnSuccess){
      callbackOnSuccess();
    }
    return result;
  } catch (error) {
    const customError = {
      code: error.status?.toString(), // serializable
    } as SerializedError;
    if (error.status === 401) {
      customError.message = error.message;
      customError.name = 'Unauthorized';
      if (showResponseMessage) {
        dispatch(showErrorSnackbar(localizedDynamic('Unauthorized')));
      }
    } else {
      if (error.response) {
        customError.message = error.response;
        customError.name = 'Error';
        if (showResponseMessage && customError.message) {
          dispatch(showErrorSnackbar(localizedDynamic(getErrorMessage(customError.message))));
        }
      }
    }
    throw error;
  }
};

export const getErrorMessage = (errorResponse: string) => {
  if (isJson(errorResponse)) {
    const jsonError = JSON.parse(errorResponse);
    const errorMessage = jsonError.message ? jsonError.message : jsonError;
    return errorMessage;
  }
  return errorResponse;
};
