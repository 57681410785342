import {Box, createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Consumption from '../../../assets/icons/consumption_01.svg';
import Contract from '../../../assets/icons/contract_01.svg';
import Creditcard from '../../../assets/icons/creditcard_01.svg';
import {localized} from '../../../i18n/i18n';
import {getContractsDetails} from '../../../state/features/contract/operation';
import {getConsumption} from '../../../state/features/energy-consumption/operation';
import {getDebtorPostings} from '../../../state/features/invoice/operation';
import {getStatusMessages} from '../../../state/features/status-message/operation';
import {routes} from '../../../state/routes';
import {AppState} from '../../../state/store';
import {secondaryTextColor} from '../../../styles/color-constants';
import {GridWithCustomBreakpoints} from '../../../styles/custom-grid-breakpoints';
import {h1} from '../../../styles/font-size-constants';
import {formatToLocalDateAndLeadingZeros} from '../../../utilities/platform-helpers/date-helpers';
import {PageContentContainer} from '../../../view/components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../view/components/default-components/page-header.tsx/page-header';
import {PageTitle} from '../../../view/components/default-components/page-title/page-title';
import {DashboardCard} from '../../components/dashboard/dashboard-card';
import {NewsComponent} from '../../components/dashboard/dashboard-news';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    highlightedText: {
      textAlign: 'center',
      fontWeight: 'bold',
      padding: '20px',
      fontSize: h1,
      color: secondaryTextColor,
      minWidth: 150,
    },
  }),
);

export const Dashboard = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();

  let history = useHistory();

  // Fetch page data
  const {organizationId} = useSelector((store: AppState) => store.organizationReducer);
  const {currentActiveContract} = useSelector((store: AppState) => store.contractsReducer);
  const [consumptionInputs, setConsumptionInputs] = useState(generateComsumptionInputs(organizationId));

  useEffect(() => {
    if (consumptionInputs.organizationId !== organizationId) {
      setConsumptionInputs(generateComsumptionInputs(organizationId));
    }
  }, [consumptionInputs, organizationId]);

  useEffect(() => {
    if (organizationId) {
      dispatch(getContractsDetails(organizationId));
      if (consumptionInputs.organizationId && consumptionInputs.toDate && consumptionInputs.fromDate) {
        dispatch(getConsumption(consumptionInputs));
      }
      dispatch(getDebtorPostings(organizationId));
      // get News is not implemented yet
      dispatch(getStatusMessages());
    }
  }, [dispatch, consumptionInputs, organizationId]);

  const goToEconomyPage = () => {
    history.push(routes.economy);
  };
  const goToContractPage = () => {
    history.push(routes.contracts);
  };
  const goToConsumptionPage = () => {
    history.push(routes.energyConsumption);
  };

  const {saldo, thisMonth} = useSelector((state: AppState) => state.debtorPostingReducer);
  const {monthlyConsumption} = useSelector((state: AppState) => state.energyConsumptionReducer);

  return (
    <PageTitle title="Overview">
      <PageHeader area="template" title={localized('Overview')} />
      <PageContentContainer>
        <Grid container direction="row" alignItems="center" wrap={'wrap'}>
          <GridWithCustomBreakpoints item xs={12} sm={6} md={4} betweenlgandxl={4}>
            <DashboardCard
              icon={<img src={Creditcard} width="33px" height="22px" alt="Creditcard-icon" />}
              title={localized('Balance')}
              redirect={goToEconomyPage}>
              <Box className={classes.highlightedText}>
                {saldo &&
                  saldo.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) + ' kr'}
              </Box>
            </DashboardCard>
          </GridWithCustomBreakpoints>
          <GridWithCustomBreakpoints item xs={12} sm={6} md={4} betweenlgandxl={4}>
            <DashboardCard
              icon={<img src={Contract} width="22px" height="30px" alt="Contract-icon" />}
              title={localized('Agreement')}
              subTitle={localized('YourCurrentAgreementIsValidFor')}
              redirect={goToContractPage}>
              <Box className={classes.highlightedText}>
                {currentActiveContract?.contractEnd &&
                  formatToLocalDateAndLeadingZeros(currentActiveContract?.contractEnd)}
              </Box>
            </DashboardCard>
          </GridWithCustomBreakpoints>
          <GridWithCustomBreakpoints item xs={12} sm={6} md={4} betweenlgandxl={4}>
            <DashboardCard
              icon={<img src={Consumption} width="33px" height="25px" alt="Graph-icon" />}
              title={localized('Consumption')}
              subTitle={thisMonth!}
              redirect={goToConsumptionPage}>
              <Box className={classes.highlightedText}>
                {monthlyConsumption.toLocaleString(undefined, {
                  maximumFractionDigits: monthlyConsumption > 10 ? 0 : 2,
                }) + ' kWh'}
              </Box>
            </DashboardCard>
          </GridWithCustomBreakpoints>
        </Grid>
        <NewsComponent />
      </PageContentContainer>
    </PageTitle>
  );
});

const generateComsumptionInputs = (organizationId: number | undefined) => ({
  fromDate: moment().add(-100, 'days').toDate(),
  toDate: new Date(),
  organizationId: organizationId,
});
