import {Backdrop} from '@material-ui/core';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../state/store';
import {BasicSpinner} from './basic-spinner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

export const OverlaySpinner: FC = React.memo(() => {
  const classes = useStyles();
  const showOverlaySpinner = useSelector((store: AppState) => store.webAppReducer.showOverlaySpinner);
  return (
    <Backdrop className={classes.backdrop} open={showOverlaySpinner}>
      <BasicSpinner />
    </Backdrop>
  );
});
