import {Box, createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import GetAppIcon from '@material-ui/icons/GetApp';
import clsx from 'clsx';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {IContractDto, ProductCategory, ProductSubcategory} from '../../../api/api';
import {useLayoutStyles} from '../../../app-routes';
import DocumentIcon from '../../../assets/icons/document.png';
import {getContractPDF} from '../../../state/features/contract/operation';
import {AppState} from '../../../state/store';
import {arrowColor, checkmarkColor} from '../../../styles/color-constants';
import {h5} from '../../../styles/font-size-constants';
import {promptUserWithFileOptions} from '../../../utilities/file-helper';
import {contractNoEndDate, sameDate} from '../../../utilities/helpers/date-helpers';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {TitleAndSubCard} from '../../../view/components/default-components/title-and-sub-card';
import {BasicCardButton} from '../basic-card-button';
import {
  getContractEnd,
  getContractStart,
  getEmptySpace,
  getExpectedCeilingYear1,
  getExpectedCeilingYear2,
  getExpectedCeilingYear3,
  getExpectedCeilingYear4,
  getExpectedCeilingYear5,
  getExpectedCeilingYear6,
  getOriginalCeilingYear1,
  getOriginalCeilingYear2,
  getOriginalCeilingYear3,
  getOriginalCeilingYear4,
  getOriginalCeilingYear5,
  getOriginalCeilingYear6,
  getPremium,
  getPrice,
  getPriceDistrict,
  getPriceMwh,
  getSupplement,
} from './contract-info-helper';
import {subProductEnumToString} from './contract-list-item';
import {RenewContractButton} from './renew-contract-button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    documentIcon: {
      height: '31px',
      width: '36',
    },
    downloadText: {
      fontSize: h5,
      marginRight: 10,
    },
    statusActive: {
      opacity: 1,
      color: checkmarkColor,
      fontSize: 25,
      alignSelf: 'center',
      top: 45,
      left: 50,
    },
    statusInactive: {
      opacity: 1,
      color: arrowColor,
      fontSize: 25,
      alignSelf: 'center',
      top: 45,
      left: 50,
    },
    center: {
      display: 'flex',
    },
    titleLeftMargin: {
      marginLeft: 20,
    },
    threeInfoItemsWidth: {
      maxWidth: 750,
    },
    twoInfoItemsWidth: {
      maxWidth: 500,
    },
  }),
);
export const ContractInfoCard: FC = React.memo(() => {
  const classes = useStyles();
  const useLayoyut = useLayoutStyles();
  const {selectedContract} = useSelector((store: AppState) => store.selectedContractsReducer);
  const [selectedContractInfo, setSelectedContractInfo] = useState<IContractDto>(selectedContract!);
  const [contractInfoCardItems, setContractInfoCardItems] = useState<Array<JSX.Element>>();

  const downloadDocument = async () => {
    let file;
    if (selectedContract) {
      file = await getContractPDF(selectedContract?.documentId);
    }
    if (file && selectedContract?.document?.fileName) {
      promptUserWithFileOptions(file, selectedContract?.document?.fileName);
    }
  };

  useEffect(() => {
    setSelectedContractInfo(selectedContract!);
  }, [selectedContract]);

  const contractPeriod = useMemo(() => {
    if (selectedContractInfo) {
      if (sameDate(selectedContractInfo.contractEnd, contractNoEndDate)) {
        return [getContractStart(selectedContractInfo.contractStart)];
      } else {
        return [getContractStart(selectedContractInfo.contractStart), getContractEnd(selectedContractInfo.contractEnd)];
      }
    } else return [];
  }, [selectedContractInfo]);

  useEffect(() => {
    if (
      selectedContractInfo?.productSubcategory === ProductSubcategory.Spottime ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.SMO ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.NONstop ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.FLEXvalg
    ) {
      setContractInfoCardItems([
        ...contractPeriod,
        getPriceDistrict(selectedContractInfo.priceDistrict!),
        getPremium(selectedContractInfo.premium!),
        getSupplement(selectedContractInfo.supplement!),
      ]);
    }

    if (
      selectedContractInfo?.productSubcategory === ProductSubcategory.FASTfast ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.FASTfastUp
    ) {
      setContractInfoCardItems([
        ...contractPeriod,
        getPriceDistrict(selectedContractInfo.priceDistrict!),
        getPriceMwh(selectedContractInfo.fixedPricePremium!),
        getSupplement(selectedContractInfo.supplement!),
      ]);
    }

    if (
      selectedContractInfo?.productSubcategory === ProductSubcategory.FASTfastSPOTtime ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.FASTfastExclProfilSPOTtime
    ) {
      setContractInfoCardItems([
        ...contractPeriod,
        getPriceDistrict(selectedContractInfo.priceDistrict!),
        getPriceMwh(selectedContractInfo.fixedPricePremium!),
        getPremium(selectedContractInfo.premium!),
        getSupplement(selectedContractInfo.supplement!),
      ]);
    }

    if (
      selectedContractInfo?.productSubcategory === ProductSubcategory.FASTloft ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.FLEXloft ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.FLEXloftSPOTtime ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.FLEXfastExclProfile ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.FLEXfastExclProfilSPOTtime ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.SpottimeNonStopWithWindPower
    ) {
      setContractInfoCardItems([
        ...contractPeriod,
        getPriceDistrict(selectedContractInfo.priceDistrict!),
        getPremium(selectedContractInfo.premium!),
        getSupplement(selectedContractInfo.supplement!),
        getEmptySpace(),
        getOriginalCeilingYear1(selectedContractInfo.originalCeilingYear1!),
        getExpectedCeilingYear1(selectedContractInfo.currentCeilingYear1!),
        getEmptySpace(),
        getOriginalCeilingYear2(selectedContractInfo.originalCeilingYear2!),
        getExpectedCeilingYear2(selectedContractInfo.currentCeilingYear2!),
        getEmptySpace(),
        getOriginalCeilingYear3(selectedContractInfo.originalCeilingYear3!),
        getExpectedCeilingYear3(selectedContractInfo.currentCeilingYear3!),
        getEmptySpace(),
        getOriginalCeilingYear4(selectedContractInfo.originalCeilingYear4!),
        getExpectedCeilingYear4(selectedContractInfo.currentCeilingYear4!),
        getEmptySpace(),
        getOriginalCeilingYear5(selectedContractInfo.originalCeilingYear5!),
        getExpectedCeilingYear5(selectedContractInfo.currentCeilingYear5!),
        getEmptySpace(),
        getOriginalCeilingYear6(selectedContractInfo.originalCeilingYear6!),
        getExpectedCeilingYear6(selectedContractInfo.currentCeilingYear6!),
      ]);
    }

    if (
      selectedContractInfo?.productSubcategory === ProductSubcategory.FLEXfast ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.FLEXfastFLEXSpot
    ) {
      setContractInfoCardItems([
        ...contractPeriod,
        getPriceDistrict(selectedContractInfo.priceDistrict!),
        getEmptySpace(),
        getPremium(selectedContractInfo.premium!),
        getSupplement(selectedContractInfo.supplement!),
        getOriginalCeilingYear1(selectedContractInfo?.originalCeilingYear1!),
        getExpectedCeilingYear1(selectedContractInfo.currentCeilingYear1!),
        getEmptySpace(),
        getOriginalCeilingYear2(selectedContractInfo.originalCeilingYear2!),
        getExpectedCeilingYear2(selectedContractInfo.currentCeilingYear2!),
        getEmptySpace(),
        getOriginalCeilingYear3(selectedContractInfo.originalCeilingYear3!),
        getExpectedCeilingYear3(selectedContractInfo.currentCeilingYear3!),
        getEmptySpace(),
        getOriginalCeilingYear4(selectedContractInfo.originalCeilingYear4!),
        getExpectedCeilingYear4(selectedContractInfo.currentCeilingYear4!),
        getEmptySpace(),
        getOriginalCeilingYear5(selectedContractInfo.originalCeilingYear5!),
        getExpectedCeilingYear5(selectedContractInfo.currentCeilingYear5!),
        getEmptySpace(),
        getOriginalCeilingYear6(selectedContractInfo.originalCeilingYear6!),
        getExpectedCeilingYear6(selectedContractInfo.currentCeilingYear6!),
      ]);
    }

    if (
      selectedContractInfo?.productSubcategory === ProductSubcategory.VINDenergy ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.ClimateAction ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.ClimateForest
    ) {
      setContractInfoCardItems([...contractPeriod, getPremium(selectedContractInfo.premium!)]);
    }

    if (
      selectedContractInfo?.productSubcategory === ProductSubcategory.CO2Mapping ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.EnergyCalculation ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.EnergyLabeling ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.EnergySparring ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.EnergySupervision ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.OmegaEMS ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.PriorityList ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.RealizationHelp ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.RealizationStart ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.SubsidiesCase ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.HourCase ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.SolarCell ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.SubsidiesHelp ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.EnergyCheck ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.EsgReport ||
      selectedContractInfo?.productSubcategory === ProductSubcategory.TariffCheck
    ) {
      setContractInfoCardItems([...contractPeriod, getPrice(selectedContractInfo.price!)]);
    }

    // Pris alert
    if (selectedContractInfo?.productSubcategory === ProductSubcategory.PriceAlert) {
      setContractInfoCardItems([
        ...contractPeriod,
        getEmptySpace(),
        getEmptySpace(),
        getSupplement(selectedContractInfo.supplement!),
      ]);
    }

    // Default for new product (255)
    if (selectedContractInfo?.productSubcategory === ProductSubcategory.NewProduct) {
      switch (selectedContractInfo.productCategory) {
        case ProductCategory.ElectricitySales:
          setContractInfoCardItems([...contractPeriod, getPriceDistrict(selectedContractInfo.priceDistrict!)]);
          break;
        case ProductCategory.GreenEnergy:
          setContractInfoCardItems([...contractPeriod, getPremium(selectedContractInfo.premium!)]);
          break;
        case ProductCategory.Counseling:
          setContractInfoCardItems([...contractPeriod, getPrice(selectedContractInfo.price!)]);
          break;

        default:
          break;
      }
    }

    if (!selectedContractInfo) {
      setContractInfoCardItems([]);
    }
  }, [selectedContractInfo, contractPeriod]);

  return (
    <BasicCard className={classes.threeInfoItemsWidth}>
      <Grid container direction="row" justify="flex-start">
        <Grid item xs={9}>
          <Box className={classes.center}>
            <img src={DocumentIcon} className={classes.documentIcon} alt={'Document-icon-black'}></img>
            {selectedContract?.active ? (
              <CheckCircleTwoToneIcon className={classes.statusActive} />
            ) : (
              <CheckCircleTwoToneIcon className={classes.statusInactive} />
            )}
            <div className={classes.titleLeftMargin}>
              <TitleAndSubCard
                title={subProductEnumToString(selectedContract?.productSubcategory!)}
                subTitle={selectedContract?.contractStart.getFullYear().toString()}
              />
            </div>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Grid container direction="row" justify="flex-end" alignItems="center">
            <Typography className={clsx(classes.downloadText, useLayoyut.desktopAndLargeMobile)}>
              {'Download pdf'}
            </Typography>
            <BasicCardButton onClick={downloadDocument}>
              <GetAppIcon />
            </BasicCardButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row">
        {contractInfoCardItems}
      </Grid>
      <Grid container direction="row" justify="flex-end" alignItems="flex-start">
        <RenewContractButton />
      </Grid>
    </BasicCard>
  );
});
