import {useEffect, useState} from 'react';
import {useAuth} from '../../auth/general-auth-provider';
import {AccessTokenKey, AuthRoles} from '../constants';
import { TOKEN_ROLE_IDENTIFIER } from '../../auth/auth-constants';

export function decodeToken(token: string | null) {
  if (token === null || token === '') {
    return {upn: ''};
  }
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('JWT must have 3 parts');
  }
  const decoded = urlBase64Decode(parts[1]);
  if (!decoded) {
    throw new Error('Cannot decode the token');
  }
  return JSON.parse(decoded);
}

function urlBase64Decode(str: string) {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += '==';
      break;
    case 3:
      output += '=';
      break;
    default:
      throw new Error('Illegal base64url string!');
  }

  return decodeURIComponent((window as any).escape(window.atob(output)));
}

type TokenProps = 'Email' | 'sub';

export function getAccessTokenFromLocalStorage() {
  return '';
}

function getFieldFromToken(field: TokenProps, token?: string | null) {
  if (!token) {
    token = localStorage.getItem(AccessTokenKey);
  }

  const decodedToken = decodeToken(token);
  let tokenField = '';

  if (decodedToken) {
    tokenField = decodedToken[field] || '';
  }

  return tokenField;
}

export function getEmailFromToken(token?: string | null) {
  return getFieldFromToken('Email', token);
}

export function getTokenExpiresAt(tokenId: string) {
  let token = localStorage.getItem(tokenId);
  if (token) {
    let jwtToken = decodeToken(token);
    let expiresAt = jwtToken.exp;
    let expiresAtDate = new Date(expiresAt * 1000);
    return expiresAtDate;
  }
  return null;
}

export function isAdmin(tokenId: string = 'token') {
  let token = localStorage.getItem(tokenId);
  if (token) {
    let jwtToken = decodeToken(token);
    if (jwtToken[TOKEN_ROLE_IDENTIFIER]) {
      return jwtToken[TOKEN_ROLE_IDENTIFIER].includes(AuthRoles.Admin) || jwtToken[TOKEN_ROLE_IDENTIFIER].includes(AuthRoles.GlobalAdmin);
    }
  }
  return false;
}

export function isGlobalAdmin(tokenId: string = 'token') {
  let token = localStorage.getItem(tokenId);
  if(token) {
    let jwtToken = decodeToken(token);
    if(jwtToken[TOKEN_ROLE_IDENTIFIER]) {
      return jwtToken[TOKEN_ROLE_IDENTIFIER].includes(AuthRoles.GlobalAdmin)
    }
  }
  return false;
}

export function getUserRoles(tokenId: string = 'token') {
  let token = localStorage.getItem(tokenId);

  if (token) {
    let jwtToken = decodeToken(token);

    if (jwtToken.roles && jwtToken.roles.length > 0) {
      return jwtToken.roles;
    }
  }
  return '';
}

export function getUseRoleByToken(token: string) {
  if (token) {
    let jwtToken = decodeToken(token);

    if (jwtToken.roles && jwtToken.roles.length > 0) {
      return jwtToken.roles[0];
    }
  }
  return '';
}

export function isTokenExpired(tokenId: string = 'token') {
  let expireDate = getTokenExpiresAt(tokenId);
  let currentTime = new Date();
  if (expireDate) {
    return currentTime > expireDate;
  }
  return true;
}

export type AuthHelperType = {
  isLoading: any;
  isAuthenticated: any;
  loginWithRedirect: any;
  getToken: any;
  logout: any;
  hasRole: boolean;
};

export function useAuthHelper() {
  const [isLoading, setIsLoading] = useState(true);
  const [IsAuthenticated, setIsAuthenticated] = useState();
  const [hasRole, setHasRole] = useState(false);

  const {loading, isAuthenticated, loginWithRedirect, getTokenSilently, logout, role} = useAuth();
  useEffect(() => {
    setIsLoading(loading);
    setIsAuthenticated(isAuthenticated);
    setHasRole(role ? true : false);
  }, [loading, isAuthenticated, role]);

  return {
    isLoading: isLoading,
    isAuthenticated: IsAuthenticated,
    loginWithRedirect: loginWithRedirect,
    getToken: getTokenSilently,
    logout: logout,
    hasRole: hasRole,
  } as AuthHelperType;
}
