import {createStyles, Hidden, makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';
import React, {FC} from 'react';
import { useHistory } from 'react-router-dom';
import {useLayoutStyles} from '../../../app-routes';
import nrgi_scan_logo from '../../../assets/NRGiScanGreen.png';
import { routes } from '../../../state/routes';
import OrganizationDropdown from '../../../view-scan-energi/components/organization/organization-dropdown';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },

    organizationDropDown: {
      maxWidth: 200,
      margin: '16px',
      padding: '0px 20px 0px 20px',
      flex: 1,
    },
    dropdownContainer: {
      flex: 1,
    },

    headerLogo: {
      // padding: '50px 0 0 0px',
      width: 115,
    },
    cardWidth: {display: 'flex', alignItems: 'center', marginBottom: theme.spacing(1.5)},
  }),
);

const HeaderBar: FC = React.memo((props) => {
  const classes = useStyles();
  const layoutClasses = useLayoutStyles();
  let history = useHistory();
  const goToHomePage = () => {
    history.push(routes.dashboard);
  };

  return (
    <Hidden mdUp>
      <div className={clsx(classes.container, layoutClasses.mobileOnly)}>
        <div className={classes.cardWidth}>
          <img src={nrgi_scan_logo} alt="Logo" className={classes.headerLogo} onClick={goToHomePage} />
        </div>
        <div className={classes.cardWidth}>
          <OrganizationDropdown />
        </div>
      </div>
    </Hidden>
  );
});

export default HeaderBar;
