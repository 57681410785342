import {Box, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import React, {FC} from 'react';
import {IContactDto} from '../../../api/api';
import {basicCardBoxShadow} from '../../../styles/color-constants';
import {h5} from '../../../styles/font-size-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
    },
    daysText: {
      fontWeight: 'bold',
    },
    contactInfoCard: {},
    contactInfoTextBox: {
      alignSelf: 'flex-start',
    },
    contactInfoHeader: {
      margin: 0,
    },
    contactInfoText: {
      fontSize: h5,
    },
    underline: {
      textDecorationLine: 'underline',
      fontSize: h5,
    },
    image: {
      height: '110px',
      maxWidth: '165px',
      borderRadius: '10px',
      boxShadow: basicCardBoxShadow,
      position: 'relative',
      alignSelf: 'center',
    },
    personIcon: {
      fontSize: 90,
      color: '#D3D3D3',
    },
    imageContainer: {
      marginRight: 20,
    },
  }),
);
interface PropsFromParent {
  contact: IContactDto;
}

export const ContactCard: FC<PropsFromParent> = React.memo(({...props}) => {
  const classes = useStyles();

  return (
    <BasicCard className={classes.contactInfoCard}>
      <Box className={classes.flexBox}>
        <div className={classes.imageContainer}>
          {props.contact.image ? (
            <img src={`data:image/jpeg;base64,${props.contact.image}`} className={classes.image} alt="Contact_image" />
          ) : (
            <PersonIcon className={classes.personIcon} />
          )}
        </div>

        <Box className={classes.contactInfoTextBox}>
          <h3 className={classes.contactInfoHeader}>
            {props.contact.name ? props.contact.name : props.contact.contactTitle}
          </h3>
          <Typography className={classes.contactInfoText}>
            {props.contact.contactTitle ? props.contact.contactTitle : ''}
          </Typography>
          {props.contact.phoneNumber && (
            <Typography className={classes.contactInfoText}>{'Tlf: ' + props.contact.phoneNumber}</Typography>
          )}
          {props.contact.email && (
            <Box flexDirection="row" display="flex">
              <Typography className={classes.contactInfoText}>{'Email:\u00A0'}</Typography>
              <Typography className={classes.underline}>{props.contact.email ? props.contact.email : ''}</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </BasicCard>
  );
});
