import {Tooltip, TooltipProps, withStyles} from '@material-ui/core';
import React, {FC} from 'react';

const TooltipSetup = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
  },
  arrow: {
    '&::before': {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[2],
    },
  },
}))(Tooltip);

interface PropsFromParent {}

export const BasicTooltip: FC<PropsFromParent & TooltipProps> = React.memo(({children, ...props}) => {
  return (
    <TooltipSetup placement={props.placement} arrow title={props.title} interactive>
      <div>{children}</div>
    </TooltipSetup>
  );
});
