import {createSlice} from '@reduxjs/toolkit';
import {createUser, deleteUserFromOrganization, getUser, getUsersByOrganizationId, SetUserActive} from './operation';
import {UserState} from './types';

const initialState: UserState = {
  user: undefined,
  pending: false,
  isSuccess: false,
  userOrganizations: [

  ],
};

export const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get

    builder.addCase(SetUserActive.fulfilled, (state, action) => {
      state.userPageActive = action.payload;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.pending = false;
      state.user = action.payload;
    });
    builder.addCase(getUser.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getUser.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(getUsersByOrganizationId.fulfilled, (state, action) => {
      state.userOrganizations =  action.payload? action.payload: []
    });
    builder.addCase(getUsersByOrganizationId.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getUsersByOrganizationId.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.userOrganizations = action.payload? state.userOrganizations
        ? [...state.userOrganizations, action.payload]
        : [action.payload] : state.userOrganizations
    });
    builder.addCase(createUser.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(createUser.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(deleteUserFromOrganization.fulfilled, (state, action) => {
      state.userOrganizations = state.userOrganizations?state.userOrganizations.filter(uo=> uo.id !== action.meta.arg.userId) :[] 
    });
    builder.addCase(deleteUserFromOrganization.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(deleteUserFromOrganization.rejected, (state, action) => {
      state.pending = false;
    });
  },
});

// eslint-disable-next-line
export const {} = usersSlice.actions;

export default usersSlice.reducer;
