import {createSlice} from '@reduxjs/toolkit';
import {AuthState} from './types';

export const initialState: AuthState = {
  token: '',
  logout: false,
  triggerSignOut: false,
  isInitialized: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setNewToken: (state, action) => {
      const token = action.payload ? action.payload : '';

      window.localStorage.setItem('token', token);
      state.token = token;
    },
    logOutAuth: (state) => {
      console.log("logout")
      localStorage.removeItem('token');
      state.logout = true;
      state.token = '';
    },
    setTriggerSignOut: (state, action) => {
      state.triggerSignOut = action.payload;
    },
    setIsInitialized: (state, action) => {
      state.isInitialized = action.payload;
    }
  },
});

export const {setNewToken, logOutAuth, setTriggerSignOut, setIsInitialized} = authSlice.actions;

export default authSlice.reducer;
