import { createStyles, FormControl, makeStyles, MenuItem, Select, Theme } from '@material-ui/core';
import React, { FC } from 'react';
import { localized } from '../../../i18n/i18n';
import { useDocumentFilter } from '../filterprovider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      //marginRight: theme.spacing(1),
      minWidth: 120,
    },
  }),
);
export const SortType: FC = React.memo(() => {
  const classes = useStyles();
  let {sortProp, setSortProp}= useDocumentFilter();
  const sortTypeChanged =(event: React.ChangeEvent<{value: unknown}>) => {
    setSortProp(event?.target.value as string)
  }

  return (
    <FormControl className={classes.formControl}>
      <Select value={sortProp} onChange={sortTypeChanged}>
        <MenuItem value="name">{localized('Name')}</MenuItem>
        <MenuItem value="date">{localized('Latest')}</MenuItem>
      </Select>
    </FormControl>
  );
});
