import {Button, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import React, {useCallback} from 'react';
import {localized} from '../../../i18n/i18n';
import {secondaryColor} from '../../../styles/color-constants';
import {h2, h5} from '../../../styles/font-size-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subText: {
      fontSize: h5,
      margin: '0 0 0 16px',
      lineHeight: '26px',
      maxWidth: '648px',
    },
    footerText: {
      fontSize: h5,
      margin: '0 0 0 16px',
      color: '#5D5D5D',
      lineHeight: '26px',
      maxWidth: '648px',
    },
    headerText: {
      fontSize: h2,
      fontWeight: 'bold',
      margin: '0 0 0 16px',
      lineHeight: '26px',
      maxWidth: '648px',
    },
    root: {
      minWidth: '80px',
      minHeight: '32px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: secondaryColor,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 15,
      marginBottom: 15,
      color: '#fff',
      boxShadow: '3px 3px 10px #838D9B33',
      border: '1px solid #E0E0E0',
      fontSize: h5,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
      '&.Mui-disabled': {
        backgroundColor: secondaryColor,
        color: '#FFF',
      },
    },
  }),
);

export const SignUpNewsRedirectCard = React.memo(() => {
  const classes = useStyles();
  const onSignUpClicked = useCallback(() => {
    const newWindow = window.open('https://www.scanenergi.dk/nyhedsbrev', '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
    // eslint-disable-next-line
  }, []);
  return (
    <BasicCard>
      <Typography className={classes.headerText}>{localized('NewsLetter')}</Typography>
      <Typography className={classes.subText}>{localized('NewsDefaultText')}</Typography>
      <Button className={classes.root} onClick={onSignUpClicked}>
        {localized('SignUp')}
      </Button>
      <Typography className={classes.footerText}>{localized('SignUpNewsLetterSubText')}</Typography>
      <Typography className={classes.footerText}>{localized('SignUpNewsLetterSubSubText')}</Typography>
    </BasicCard>
  );
});
