import {createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IContractDto} from '../../../api/api';
import {useLayoutStyles} from '../../../app-routes';
import ContractIconGrey from '../../../assets/icons/menu-icons/contract_grey.png';
import ContractIconWhite from '../../../assets/icons/menu-icons/contract_white.png';
import {localized} from '../../../i18n/i18n';
import {selectedContractSlice} from '../../../state/features/contract/contract-selected-slice';
import {getContractPDF} from '../../../state/features/contract/operation';
import {AppState} from '../../../state/store';
import {arrowColor, checkmarkColor, primaryTextColor} from '../../../styles/color-constants';
import {promptUserWithFileOptions} from '../../../utilities/file-helper';
import {BasicDocumentListItem} from '../document/basic-document-list-item';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusActive: {
      opacity: 1,
      color: checkmarkColor,
      fontSize: 'large',
    },
    statusInactive: {
      opacity: 1,
      color: arrowColor,
      fontSize: 'large',
    },
    documentIcon: {
      width: '21px',
      height: '26px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& :first-child': {
        marginRight: 20,
        marginLeft: 10,
      },
    },
    textOverflow: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    downloadIcon: {
      opacity: 1,
      cursor: 'pointer',
      color: arrowColor,
      '&:hover': {
        color: primaryTextColor,
      },
    },
    downloadIconContainer: {
      height: '24px',
      marginLeft: 10,
      marginRight: 15,
    },
    downloadIconContainerMobile: {
      height: '24px',
      marginLeft: 10,
    },
    marginLeft30: {
      marginLeft: 30,
    },
    marginLeftt10: {
      marginLeft: 10,
    },
  }),
);

export const productEnumToString = (param: number) => {
  switch (param) {
    case 0:
      return 'Elsalg';
    case 1:
      return 'Grøn Energi';
    case 2:
      return 'Rådgivning';
    default:
      return 'Elsalg';
  }
};

export const subProductEnumToString = (param: number) => {
  switch (param) {
    case 0:
      return localized('Spottime');
    case 1:
      return localized('SMO');
    case 2:
      return 'FASTfast';
    case 3:
      return localized('FASTfastUp');
    case 4:
      return 'FASTloft';
    case 5:
      return 'FLEXloft';
    case 6:
      return 'FLEXfast';
    case 7:
      return 'FLEXvalg';
    case 8:
      return 'NONstop';
    case 9:
      return 'VINDenergi';
    case 10:
      return localized('ClimateAction');
    case 11:
      return localized('FASTfastSPOTtime');
    case 12:
      return localized('FASTfastExclProfilSPOTtime');
    case 13:
      return localized('FLEXloftSPOTtime');
    case 14:
      return localized('CO2Mapping');
    case 15:
      return localized('EnergyCalculation');
    case 16:
      return localized('EnergyLabeling');
    case 17:
      return localized('EnergySupervision');
    case 18:
      return localized('EnergySparring');
    case 19:
      return 'OmegaEMS';
    case 20:
      return localized('PriorityList');
    case 21:
      return localized('RealizationHelp');
    case 22:
      return localized('RealizationStart');
    case 23:
      return localized('SubsidiesCase');
    case 24:
      return localized('HourCase');
    case 25:
      return localized('FLEXfastExclProfile');
    case 26:
      return localized('FLEXfastExclProfilSPOTtime');
    case 27:
      return localized('CLIMATEForest');
    case 28:
      return localized('SolarCellCalculation');
    case 29:
      return localized('SubsidiesHelp');
    case 30:
      return localized('SpottimeNonStopWithWindPower');
    case 31:
      return localized('EnergyCheck');
    case 32:
      return localized('EsgReport');
    case 33:
      return localized('PriceAlert');
    case 34:
      return localized('FLEXfastFLEXSpot');
    case 35:
      return localized('TariffCheck');
    case 255:
      return localized('NewProduct');
    default:
      return localized('NewProduct');
  }
};

interface PropsFromParent {
  contract: IContractDto;
  contractPage?: boolean;
}
export const ContractListItem: FC<PropsFromParent> = React.memo(({contract, contractPage}) => {
  const classes = useStyles();
  const layout = useLayoutStyles();
  const dispatch = useDispatch();
  const {selectedContract} = useSelector((store: AppState) => store.selectedContractsReducer);
  const [selected, setSelected] = useState<boolean>(false);
  const [compare] = useState<Boolean>();

  useEffect(() => {
    if (JSON.stringify(selectedContract) === JSON.stringify(contract)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedContract, compare, contract]);

  const downloadDocument = async () => {
    let file;
    if (contract) {
      file = await getContractPDF(contract?.documentId);
    }
    if (file && contract?.document?.fileName) {
      promptUserWithFileOptions(file, contract?.document?.fileName);
    }
  };

  const handleContractSelected = (e: any) => {
    e.preventDefault();
    if (contractPage) {
      dispatch(selectedContractSlice.actions.setSelectedContract(contract));
    }
  };

  const getContractEnd = useMemo(() => {
    return contract.contractEnd.getFullYear() === 9999 ? '' : contract.contractEnd.getFullYear();
  }, [contract]);

  const showInfoHorizontal = () => {
    return (
      <Grid
        className={layout.desktopAndLargeMobile}
        container
        direction="row"
        justify="space-between"
        alignItems="center">
        <Grid item xs={6}>
          <div className={classes.flexRow}>
            <img
              src={selected ? ContractIconWhite : ContractIconGrey}
              className={classes.documentIcon}
              alt={'Document-icon-black'}
            />
            <Typography className={classes.textOverflow}>
              {subProductEnumToString(contract.productSubcategory)}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={3}>
          <Typography>{contract.contractStart.getFullYear() + ' - ' + getContractEnd}</Typography>
        </Grid>
        <Grid item xs={1} className={classes.marginLeft30}>
          {contract.active === true ? (
            <CheckCircleTwoToneIcon className={classes.statusActive} />
          ) : (
            <CheckCircleTwoToneIcon className={classes.statusInactive} />
          )}
        </Grid>
        {!contractPage && (
          <Grid item xs={1}>
            <div onClick={downloadDocument} className={classes.downloadIconContainer}>
              <GetAppIcon className={classes.downloadIcon} />
            </div>
          </Grid>
        )}
      </Grid>
    );
  };

  const showInfoVertical = () => {
    return (
      <Grid className={layout.smallMobileOnly} container direction="row" alignItems="center">
        <Grid item xs={2}>
          <img
            src={selected ? ContractIconWhite : ContractIconGrey}
            className={classes.documentIcon}
            alt={'Document-icon-black'}
          />
        </Grid>
        <Grid item xs={7}>
          <Typography className={classes.textOverflow}>
            {subProductEnumToString(contract.productSubcategory)}
          </Typography>
          <Typography>
            {localized('Period')}: {contract.contractStart.getFullYear() + ' - ' + contract.contractEnd.getFullYear()}
          </Typography>
        </Grid>
        <Grid item className={classes.marginLeftt10}>
          {contract.active === true ? (
            <CheckCircleTwoToneIcon className={classes.statusActive} />
          ) : (
            <CheckCircleTwoToneIcon className={classes.statusInactive} />
          )}
        </Grid>
        {!contractPage && (
          <Grid item>
            <div onClick={downloadDocument} className={classes.downloadIconContainerMobile}>
              <GetAppIcon className={classes.downloadIcon} />
            </div>
          </Grid>
        )}
      </Grid>
    );
  };

  return (
    <BasicDocumentListItem selected={selected} contractPage={contractPage}>
      <Grid container direction="row" justify="space-between" alignItems="center" onClick={handleContractSelected}>
        {showInfoVertical()}
        {showInfoHorizontal()}
      </Grid>
    </BasicDocumentListItem>
  );
});
