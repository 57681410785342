import React, {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {DebtorPostingDto, DebtorPostingType, IDebtorPostingDto} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {DebtorPostingFilter} from '../../../state/features/invoice/types';
import {AppState} from '../../../state/store';
import {ScanEnergyTable} from '../table/table';

const invoiceTableColumnSizes = () => [10, 18, 10, 10, 10, 10];

// Invoices and Currency commented out until Scanenergi finds out what to do with the scroll bar.
const generateColumnHeaders = () => [
  localized('Date'),
  //localized('Invoices'),
  localized('Description'),
  localized('Maturity'),
  //localized('Currency'),
  localized('Debit'),
  localized('Credit'),
  localized('Balance'),
];

const keysToRender = [
  'date',
  //'invoiceNumber',
  'description',
  'dueDate',
  //'currency',
  'debet',
  'credit',
  'balance',
] as (keyof DebtorPostingDto)[];

interface PropsFromParent {
  minWidth: number;
}

export const InvoiceTable: FC<PropsFromParent> = React.memo(({minWidth}) => {
  const {debtorPostings, currentFilter} = useSelector((state: AppState) => state.debtorPostingReducer);
  const [columnSizes] = useState(invoiceTableColumnSizes());
  const [columnHeaders] = useState(generateColumnHeaders());
  const [debtorPostingsToUse, setDebtorPostingsToUse] = useState<IDebtorPostingDto[]>([]);

  useEffect(() => {
    const filteredDebtorPostings = debtorPostings && debtorPostings.filter(debtorPostingsFilter(currentFilter));
    setDebtorPostingsToUse(filteredDebtorPostings!);
    
    // eslint-disable-next-line
  }, [debtorPostings, currentFilter, setDebtorPostingsToUse]);

  return (
    <>
      {debtorPostingsToUse && (
        <ScanEnergyTable
          maxHeight={442}
          minWidth={minWidth}
          items={debtorPostingsToUse}
          keysToRender={keysToRender}
          columnSizes={columnSizes}
          columnHeaders={columnHeaders}
        />
      )}
    </>
  );
});

const debtorPostingsFilter = (debtorPostingsFilter: DebtorPostingFilter) => (debtorPosting: IDebtorPostingDto) => {
  let useDebtorPosting =
    debtorPosting.debtorPostingType === DebtorPostingType.Invoice ||
    DebtorPostingType.Credit ||
    DebtorPostingType.Other;
  if (
    debtorPostingsFilter.DebtorPostingType !== undefined &&
    debtorPostingsFilter.DebtorPostingType !== debtorPosting.debtorPostingType
  ) {
    return false;
  }
  if (debtorPostingsFilter.InvoiceNumber) {
    const docNameLowerCase = debtorPosting.invoiceNumber?.toLowerCase();
    const invoiceNameLowerCase = debtorPostingsFilter.InvoiceNumber.toLowerCase();
    if (!docNameLowerCase?.includes(invoiceNameLowerCase)) {
      //Since we dont know what facturaNumber referers to, but need to be added
      return false;
    }
  }
  if (debtorPostingsFilter.DateTo! < debtorPosting.date || debtorPosting.date < debtorPostingsFilter.DateFrom!) {
    return false;
  }
  return useDebtorPosting;
};
