import {createAsyncThunk} from '@reduxjs/toolkit';
import {DocumentClient} from '../../../api/api';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {documentClient} from '../../api-clients';

export const getDocumentDetails = createAsyncThunk('documents/getDocuments', (organizationId: number) => {
  return AsyncOperationHandler((client) => (client as DocumentClient).getDocumentDetails(organizationId), documentClient);
});

export const getDebtorPostingDocumentDetails = createAsyncThunk('documents/getDebtorPostingDocuments', (organizationId: number) => {
  return AsyncOperationHandler((client) => (client as DocumentClient).getDebtorPostingDocumentDetails(organizationId), documentClient);
});
