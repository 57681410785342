import React, {FC, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSnackbar} from 'notistack';
import {AppState} from '../../../../state/store';
import {removeSnackbar} from '../../../../state/features/snackbar/snackbar-slice';

// Inspiration from https://codesandbox.io/s/github/iamhosseindhv/notistack/tree/master/examples/redux-example?file=/redux/reducers.js
export const Snackbar: FC = React.memo(() => {
  const dispatch = useDispatch();
  const notifications = useSelector((store: AppState) => store.snackbarReducer.snackbars || []);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const displayed = useRef<number[]>([]);

  const storeDisplayed = (id: number) => {
    displayed.current = [...displayed.current, id];
  };

  const removeDisplayed = (id: number) => {
    displayed.current = [...displayed.current.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({key, message, options = {}, dismissed = false}) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.current.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey: string | number) => {
          // remove this snackbar from redux store
          dispatch(removeSnackbar(myKey as number));
          removeDisplayed(myKey as number);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
});
