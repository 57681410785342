import {Badge, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import clsx from 'clsx';
import React, {FC, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {MarketingMaterialCategories} from '../../../api/api';
import {AppState} from '../../../state/store';
import {basicCardBgColor, disabledColor, primaryTextColor, secondaryColor} from '../../../styles/color-constants';
import {h5} from '../../../styles/font-size-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sortFlexbox: {
      cursor: 'pointer',
      marginLeft: 5,
      marginRight: 5,
      minWidth: 140,
      maxHeight: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: primaryTextColor,
      boxShadow: (active: boolean) => (active ? `0px 0px 0px 2px ${secondaryColor}` : 'none'),
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
    },
    selected: {
      backgroundColor: secondaryColor,
      color: basicCardBgColor,
    },

    typografi: {
      fontSize: h5,
      fontFamily: 'Lato',
      fontWeight: 'normal',
    },

    badge: {display: 'flex', transform: 'translate(24px, -20px)'},
  }),
);

interface PropsFromParent {
  MaterialCategory: MarketingMaterialCategories;
  btnText: string;
  handleFilterChange: (category: MarketingMaterialCategories) => void;
  selectedCategory: MarketingMaterialCategories;
}

export const MaterialFilterButton: FC<PropsFromParent> = React.memo(({children, ...props}) => {
  const {activeCategorys, notifications} = useSelector((store: AppState) => store.marketingMaterialReducer);
  const isCategoryActive = activeCategorys.includes(props.MaterialCategory);
  const classes = useStyles(isCategoryActive);
  const handleFilterButtonPressed = useCallback(() => {
    props.handleFilterChange(props.MaterialCategory);
  }, [props]);

  const notificationsForCategory = notifications.includes(props.MaterialCategory);

  return (
    <BasicCard
      color={isCategoryActive ? primaryTextColor : disabledColor}
      className={clsx(props.selectedCategory === props.MaterialCategory ? classes.selected : null, classes.sortFlexbox)}
      onClick={handleFilterButtonPressed}>
      <Typography className={classes.typografi}>{props.btnText}</Typography>
      <Badge className={classes.badge} color={'secondary'} variant={'standard'} invisible={!notificationsForCategory} />
    </BasicCard>
  );
});
