import {Checkbox, CheckboxProps, withStyles} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import React from 'react';
import {checkboxChecked, checkBoxUnChecked} from '../../../../styles/color-constants';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const BasicCheckbox = withStyles({
  root: {
    color: checkBoxUnChecked,
    '&$checked': {
      color: checkboxChecked,
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox icon={icon} checkedIcon={checkedIcon} color="default" {...props} />);

BasicCheckbox.defaultProps = {
  checked: false,
};
