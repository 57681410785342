import {
  createStyles,
  FormControl,
  Grid,
  GridDirection,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IContractDto, ProductCategory} from '../../../api/api';
import {useLayoutStyles} from '../../../app-routes';
import {localized} from '../../../i18n/i18n';
import {selectedContractSlice} from '../../../state/features/contract/contract-selected-slice';
import {getContractsDetails} from '../../../state/features/contract/operation';
import {AppState} from '../../../state/store';
import {h5} from '../../../styles/font-size-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {PageContentContainer} from '../../../view/components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../view/components/default-components/page-header.tsx/page-header';
import {PageTitle} from '../../../view/components/default-components/page-title/page-title';
import {ContractCard} from '../../components/contract/contract-card';
import {ContractInfoCard} from '../../components/contract/contract-info-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subText: {
      fontSize: h5,
      width: '650px',
      margin: '0 0 0 16px',
    },
    dropDownSort: {
      width: 150,
      padding: '5px 20px',
    },
    contractInfo: {
      width: '950px',
      height: '320px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 95,
    },
    cardGrid: {
      // Don't know why, but without this, the two cards will have a big margin
      width: '100%',
    },
  }),
);

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export const Contracts = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {pending, contractsSlice} = useSelector((store: AppState) => store.contractsReducer);
  const [contractDocs, setContractDocs] = useState<IContractDto[]>();
  const [greenDocs, setGreenDocs] = useState<IContractDto[]>();
  const [sortType, setSortType] = useState<string>('all');
  const [direction, setDirection] = useState<GridDirection | undefined>('column');
  const [width] = useWindowSize();
  const layoutClasses = useLayoutStyles();

  // Fetch page data
  const {organizationId} = useSelector((store: AppState) => store.organizationReducer);
  useEffect(() => {
    if (organizationId) {
      dispatch(getContractsDetails(organizationId));
    }
  }, [dispatch, organizationId]);

  const sortTypeChanged = (event: React.ChangeEvent<{value: unknown}>) => {
    setSortType(event?.target.value as string);
  };
  useEffect(() => {
    if (960 < width && width < 1920) {
      setDirection('row');
    } else {
      setDirection('column');
    }
  }, [width]);

  useEffect(() => {
    return () => {
      dispatch(selectedContractSlice.actions.setSelectedContract(undefined));
    };
  }, [dispatch]);

  useEffect(() => {
    if (sortType === 'active') {
      setContractDocs(
        contractsSlice.filter(
          (d) =>
            (d.productCategory === ProductCategory.ElectricitySales ||
              d.productCategory === ProductCategory.Counseling) &&
            d.active === true,
        ),
      );
      setGreenDocs(
        contractsSlice.filter((d) => d.productCategory === ProductCategory.GreenEnergy && d.active === true),
      );
    }
    if (sortType === 'inactive') {
      setContractDocs(
        contractsSlice.filter(
          (d) =>
            (d.productCategory === ProductCategory.ElectricitySales ||
              d.productCategory === ProductCategory.Counseling) &&
            d.active === false,
        ),
      );
      setGreenDocs(
        contractsSlice.filter((d) => d.productCategory === ProductCategory.GreenEnergy && d.active === false),
      );
    }
    if (sortType === 'all') {
      setContractDocs(contractsSlice.filter((d) => d.productCategory === ProductCategory.ElectricitySales ||
        d.productCategory === ProductCategory.Counseling));
      setGreenDocs(contractsSlice.filter((d) => d.productCategory === ProductCategory.GreenEnergy));
    }
  }, [contractsSlice, sortType]);

  useEffect(() => {
    if (contractDocs && contractDocs[0]) {
      dispatch(selectedContractSlice.actions.setSelectedContract(contractDocs[0]));
    }
  }, [dispatch, contractDocs]);

  return (
    <PageTitle title="Contracts">
      <PageHeader area="template" title={localized('Agreements')}></PageHeader>
      <PageContentContainer>
        <Typography className={clsx(classes.subText, layoutClasses.desktopOnly)}>
          {localized('BelowYouWillFindAnOverviewOfAllTheCurrentAgreementsYouHaveWithUs')}
        </Typography>
        <BasicCard className={classes.dropDownSort}>
          <FormControl className={classes.formControl}>
            <Select onChange={sortTypeChanged} value={sortType}>
              <MenuItem value="all">{localized('All')}</MenuItem>
              <MenuItem value="active">{localized('Active')}</MenuItem>
              <MenuItem value="inactive">{localized('Inactive')}</MenuItem>
            </Select>
          </FormControl>
        </BasicCard>
        <Grid container direction="row">
          <Grid item xs={12} lg={5} xl={5}>
            <Grid container direction={direction}>
              <Grid className={classes.cardGrid} item xs={12}>
                <ContractCard
                  title={localized('Agreements')}
                  parentContracts={contractDocs}
                  pending={pending}
                  cardHeight={540}
                  docuNumber={8}
                  contractPage={true}
                />
              </Grid>
              <Grid className={classes.cardGrid} item xs={12}>
                <ContractCard
                  title={localized('GreenEnergy')}
                  parentContracts={greenDocs}
                  pending={pending}
                  cardHeight={540}
                  docuNumber={8}
                  contractPage={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={7} xl={7}>
            <ContractInfoCard />
          </Grid>
        </Grid>
      </PageContentContainer>
    </PageTitle>
  );
});
