import {Box, CircularProgress, createStyles, Grid, Hidden, makeStyles, Theme} from '@material-ui/core';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
import {Chart} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import React, {FC, useEffect, useState} from 'react';
import {Bar} from 'react-chartjs-2';
import IsEqual from 'react-fast-compare';
import {useDispatch, useSelector} from 'react-redux';
import {IDailyConsumptionDto} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {getOneYearPriorSortingDays} from '../../../state/features/energy-consumption/energy-consumption-helper';
import {energyConsumptionSlice} from '../../../state/features/energy-consumption/energy-consumption-slice';
import {SortingDays} from '../../../state/features/energy-consumption/types';
import {AppState} from '../../../state/store';
import {
  basicCardBoxShadow,
  primaryAverageChartColor,
  primaryAverageLabelColor,
  primaryChartColor,
  primaryTextColor,
  secondaryChartColor,
  whiteTextColor,
} from '../../../styles/color-constants';
import {getDateByStringFormatDDMMYYYY, getOneYearPrior} from '../../../utilities/helpers/date-helpers';
import {
  countNumberOfDaysBetweenDates,
  formatToLocalDateAndLeadingZeros,
} from '../../../utilities/platform-helpers/date-helpers';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {TitleAndSubCard} from '../../../view/components/default-components/title-and-sub-card';

Chart.register(annotationPlugin);

interface DataPointAccumulated {
  date: Date;
  consumption: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardClass: {
      maxWidth: 'calc(100vw - 64px)',
      minHeight: '94%',
      overflow: 'hidden',
    },
    yAxisUnitText: {
      transform: 'rotate(-90deg)',
    },
    graphFlexbox: {
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      padding: '10px',
      width: '94%',
    },
    circularProgressBox: {
      minHeight: '100%',
      position: 'absolute',
    },
    graphContainer: {overflowX: 'auto'},
  }),
);
const firstHidden = ['sm', 'md', 'lg', 'betweenlgandxl', 'xl'] as Breakpoint[];
const secondHidden = ['xs'] as Breakpoint[];
const marginLeftTen = {marginLeft: 10};
const marginZero = {margin: 0};

export const ConsumptionGraph: FC = React.memo(({children, ...props}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const cardHeight = 430;
  const pending = useSelector((store: AppState) => store.energyConsumptionReducer.pending);
  const {
    consumption,
    consumptionOneYearPrior,
    sortingDays,
    selectedMeasuringPointIds,
    periodAverage,
    yearPriorSelected,
  } = useSelector((store: AppState) => store.energyConsumptionReducer);

  const [data, setData] = useState<GraphData>();
  let generateOptions = {
    animation: {
      duration: 0, // general animation time
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
      y: {
        grid: {
          display: false,
          drawBorder: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
    },

    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        backgroundColor: whiteTextColor,
        titleColor: primaryTextColor,
        bodyColor: primaryTextColor,
        position: 'average',
        displayColors: false,
        titleAlign: 'center',
        bodyAlign: 'center',
        bodyFontSize: 30,
        bodyFontStyle: 'bold',
        borderColor: basicCardBoxShadow,
        borderWidth: 0.5,
        callbacks: {
          label: function (context: any) {
            var label = context.dataset.label || '';
            if (context.parsed.y !== null) {
              label += ': ' + context.parsed.y + ' kWh';
            }
            return label;
          },
        },
      },
      annotation: {
        annotations: [
          {
            type: 'line',
            mode: 'horizontal',
            yMin: periodAverage,
            yMax: periodAverage,
            borderColor: primaryAverageChartColor,
            borderWidth: 3,
            label: {
              enabled: true,
              content: periodAverage.toFixed(2) + ' kWh',
              position: 'end',
              backgroundColor: primaryAverageLabelColor,
            },
          },
        ],
      },
    },
  };

  const [options, setOptions] = useState(generateOptions);
  useEffect(() => {
    const filteredDataPoints = generateFilteredDataPoints(consumption, selectedMeasuringPointIds, sortingDays);
    const filteredDataPointsOneYearPrior = generateFilteredDataPoints(
      consumptionOneYearPrior,
      selectedMeasuringPointIds,
      getOneYearPriorSortingDays(sortingDays),
    );

    let total = 0;
    filteredDataPoints.forEach((dataPoint) => (total += dataPoint.consumption));
    dispatch(energyConsumptionSlice.actions.setTotalConsumption(total));
    const dataToSet = generateDataFromFilteredDataPoints(
      filteredDataPoints,
      filteredDataPointsOneYearPrior,
      sortingDays,
      yearPriorSelected,
    );

    if (!IsEqual(data, dataToSet)) {
      setData(dataToSet);
    }
  }, [dispatch, consumption, sortingDays, selectedMeasuringPointIds, yearPriorSelected, consumptionOneYearPrior, data]);

  useEffect(() => {
    setOptions(generateOptions);
    // eslint-disable-next-line
  }, [dispatch, periodAverage]);

  return (
    <BasicCard className={classes.cardClass}>
      <TitleAndSubCard title={localized('Consumption')} />
      {pending ? (
        <Grid container direction="column" justify="center" alignItems="center" className={classes.circularProgressBox}>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <div className={classes.graphContainer}>
          <Hidden only={firstHidden}>
            <h4 style={marginLeftTen}>kWh</h4>
          </Hidden>
          <Box width={'100%'} display={'flex'} flexDirection={'row'} height={cardHeight} alignItems={'flex-end'}>
            <Hidden only={secondHidden}>
              <Box justifyContent={'center'} display={'flex'} minHeight={'100%'} alignItems={'center'}>
                <h4 className={classes.yAxisUnitText}>kWh</h4>
              </Box>
            </Hidden>
            <Box className={classes.graphFlexbox}>{data && <Bar type="bar" data={data} options={options} />}</Box>
          </Box>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
            <h4 style={marginZero}>{localized('Date')}</h4>
          </Box>
          {children}
        </div>
      )}
    </BasicCard>
  );
});

interface GraphData {
  type: string;
  labels: string[];
  datasets: {data: string[]; backgroundColor: string}[];
}

const generateFilteredDataPoints = (
  consumption: IDailyConsumptionDto[],
  selectedMeasuringPointIds: string[],
  sortingDays: SortingDays,
) => {
  let chosenDataPoints = consumption.filter((item) => {
    return selectedMeasuringPointIds.includes(item.connectionId!) || item.connectionId === null;
  });

  let accumulatedDataPoints: DataPointAccumulated[] = [];
  chosenDataPoints.forEach(function (a) {
    if (!accumulatedDataPoints[a.date!.valueOf()]) {
      accumulatedDataPoints[a.date!.valueOf()] = {date: a.date!, consumption: 0};
      accumulatedDataPoints.push(accumulatedDataPoints[a.date!.valueOf()]);
    }
    accumulatedDataPoints[a.date!.valueOf()].consumption += a.consumptionValue!;
  }, Object.create(null));

  let filteredDataPoints: DataPointAccumulated[] = [];

  if (countNumberOfDaysBetweenDates(sortingDays.fromDate, sortingDays.toDate) === 1) {
    filteredDataPoints = accumulatedDataPoints!.sort((a, b) => a.date?.valueOf()! - b.date?.valueOf()!);
  } else {
    filteredDataPoints = accumulatedDataPoints!
      .slice()
      .filter(
        (dataPoint) =>
          dataPoint.date.setHours(0, 0, 0, 0)! >= sortingDays.fromDate?.setHours(0, 0, 0, 0)! &&
          dataPoint.date.setHours(0, 0, 0, 0)! <= sortingDays.toDate?.setHours(0, 0, 0, 0)!,
      )
      .sort((a, b) => a.date?.valueOf()! - b.date?.valueOf()!);
  }

  return filteredDataPoints;
};

const generateDataFromFilteredDataPoints = (
  filteredDataPoints: DataPointAccumulated[],
  filteredDataPointsOneYearPrior: DataPointAccumulated[],
  sortingDays: SortingDays,
  yearPriorSelected: boolean,
) => {
  let filteredConsumptionData: string[] = [];
  let filteredConsumptionDataOneYearPrior: string[] = [];
  let filteredDatesData: string[] = [];
  let labelForOneDaySelected: Date = new Date();
  let useLabelForOneDay: boolean = false;

  if (countNumberOfDaysBetweenDates(sortingDays.fromDate, sortingDays.toDate) === 1) {
    filteredDataPoints.forEach((item) => {
      filteredConsumptionData.push(item.consumption.toFixed(1));
      filteredDatesData.push(item.date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}));
    });
    filteredDataPointsOneYearPrior.forEach((item) => {
      filteredConsumptionDataOneYearPrior.push(item.consumption.toFixed(1));
    });

    labelForOneDaySelected = sortingDays.fromDate;
    useLabelForOneDay = true;
  } else {
    filteredDataPoints.forEach((item) => {
      filteredConsumptionData.push(item.consumption.toFixed(1));
      filteredDatesData.push(formatToLocalDateAndLeadingZeros(item.date));
    });
    filteredDataPointsOneYearPrior.forEach((item) => {
      filteredConsumptionDataOneYearPrior.push(item.consumption.toFixed(1));
    });
  }

  const dataToSet = generateDataForGraph(
    filteredDatesData,
    filteredConsumptionData,
    filteredConsumptionDataOneYearPrior,
    yearPriorSelected,
    labelForOneDaySelected,
    useLabelForOneDay,
  );

  return dataToSet;
};

const generateDataForGraph = (
  filteredDates: string[],
  filteredConsumption: string[],
  filteredConsumptionOneYearPrior: string[],
  yearPriorSelected: boolean,
  dateLabel: Date,
  useLabelForOneDay: boolean,
):
  | {type: string; labels: string[]; datasets: any[]}
  | (() => {type: string; labels: string[]; datasets: {data: string[]; backgroundColor: string}[]}) => {
  // Cant only use filteredDates because when day is selected it contains the hours.
  if (!useLabelForOneDay) {
    dateLabel = getDateByStringFormatDDMMYYYY(filteredDates[0]);
  }

  if (yearPriorSelected) {
    return {
      type: 'bar',
      labels: filteredDates,
      datasets: [
        {
          label: dateLabel.getFullYear() ? dateLabel.getFullYear() : '',
          data: filteredConsumption,
          backgroundColor: primaryChartColor,
        },
        {
          label: dateLabel.getFullYear() ? getOneYearPrior(dateLabel).getFullYear() : '',
          data: filteredConsumptionOneYearPrior,
          backgroundColor: secondaryChartColor,
        },
      ],
    };
  } else {
    return {
      type: 'bar',
      labels: filteredDates,
      datasets: [
        {
          label: dateLabel.getFullYear() ? dateLabel.getFullYear() : '',
          data: filteredConsumption,
          backgroundColor: primaryChartColor,
        },
      ],
    };
  }
};
