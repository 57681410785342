import * as React from 'react';
import {connect, useSelector} from 'react-redux';
import {Route, RouteProps} from 'react-router-dom';
import {setNewToken} from '../state/features/auth/auth-slice';
import {AppState} from '../state/store';
import {RedirectUriStorageKey} from '../utilities/constants';
import {AuthHelperType, isTokenExpired} from '../utilities/platform-helpers/auth-helper';

interface PrivateRouteProps extends RouteProps {
  component: any;
  path: string;
  authHelper: () => AuthHelperType;
}

const mapStateToProps = (state: AppState) => {
  return {
    token: state.authReducer.token,
    isLogout: state.authReducer.logout,
  };
};

const mapDispatchToProps = {setNewToken};

type Props = PrivateRouteProps & typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>;

const RENEWAL_TIME_MS = 1800 * 1000; // Renew 30min before

const PrivateRoute = (props: Props) => {
  const {isLoading, isAuthenticated, loginWithRedirect, getToken} = props.authHelper();
  const {component: Component, ...rest} = props;
  const isInitialized = useSelector((state: AppState) => state.authReducer.isInitialized);

  React.useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }
    const fn = async () => {
      window.sessionStorage.setItem(RedirectUriStorageKey, window.location.pathname);
      if (loginWithRedirect !== undefined) {
        await loginWithRedirect({});
      }
    };
    fn();
  }, [isLoading, isAuthenticated, loginWithRedirect, props.path]);

  React.useEffect(() => {
    if (props.token) {
      scheduleTokenRenewal(props.token);
    }
    // eslint-disable-next-line
  }, [props.token]);

  const GetToken = async () => {
    console.log('GetToken');
    let token = await getToken();
    props.setNewToken(token);
  };

  const render = () => {
    if (!isInitialized) return null;

    if (!isAuthenticated || props.isLogout) return null;
    if (!props.token || isTokenExpired('token')) {
      GetToken();
    }

    return <Component {...props} />;
  };

  function scheduleTokenRenewal(token: string) {
    unscheduleRenewal();

    if (!isAuthenticated) {
      return;
    }

    let jwtToken = decodeToken(token);
    let expiresAt = jwtToken.exp;
    let expiresAtDate = new Date(expiresAt * 1000);

    if (expiresAt && expiresAtDate) {
      let timeToRenewal = expiresAtDate.getTime() - new Date().getTime() - RENEWAL_TIME_MS;
      if (timeToRenewal < 0) {
        timeToRenewal = 0;
      }

      renewalTimeout = setTimeout(() => {
        if (expiresAtDate.getTime() - new Date().getTime() - RENEWAL_TIME_MS < 0) {
          GetToken();
        }
      }, timeToRenewal);
    }
  }

  let renewalTimeout: NodeJS.Timeout | undefined;
  function unscheduleRenewal() {
    if (renewalTimeout) {
      clearTimeout(renewalTimeout);
      renewalTimeout = undefined;
    }
  }

  return <Route {...rest} path={props.path} render={render} />;
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

function decodeToken(token: string | null) {
  if (token === null || token === '') {
    return {upn: ''};
  }
  const parts = token.split('.');
  if (parts.length !== 3) {
    throw new Error('JWT must have 3 parts');
  }
  const decoded = urlBase64Decode(parts[1]);
  if (!decoded) {
    throw new Error('Cannot decode the token');
  }
  return JSON.parse(decoded);
}

function urlBase64Decode(str: string) {
  let output = str.replace(/-/g, '+').replace(/_/g, '/');
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += '==';
      break;
    case 3:
      output += '=';
      break;
    default:
      throw new Error('Illegal base64url string!');
  }

  return decodeURIComponent((window as any).escape(window.atob(output)));
}
