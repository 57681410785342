import {CircularProgress, createStyles, makeStyles, Theme} from '@material-ui/core';
import Box from '@material-ui/core/Box/Box';
import Typography from '@material-ui/core/Typography';
import React, {FC, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {MarketingMaterialCategories} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {userClient} from '../../../state/api-clients';
import {removeNotification} from '../../../state/features/marketing-material/marketing-material-slice';
import {
  DefaultMarketingMaterialPageData,
  GetCategoryName,
  MarketingMaterialPageData,
} from '../../../state/features/marketing-material/types';
import {AppState} from '../../../state/store';
import {PageContentContainer} from '../../../view/components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../view/components/default-components/page-header.tsx/page-header';
import {PageTitle} from '../../../view/components/default-components/page-title/page-title';
import {ContactFooter} from '../../components/marketing-material/contact-footer';
import {DownloadAllButton} from '../../components/marketing-material/download-all-button';
import {MarketingMaterialGridHolder} from '../../components/marketing-material/marketing-material-grid-holder';
import {MaterialFilterMenu} from '../../components/marketing-material/material-filter-holder';
import {NotAvailableCard} from '../../components/marketing-material/not-available-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    responseiveBox: {
      transition: 'color 0.1s ease-in-out, background-color 0.1s ease-in-out',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
      [theme.breakpoints.down('xs')]: {
        margin: '0 -5px 0 -5px ',
      },
    },
    headerText: {
      marginLeft: '8px ',
      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(2),
      },
    },
    title: {
      marginLeft: '8px ',
      fontWeight: 600,
      fontSize: '1.5rem',
    },
    loading: {
      display: 'flex',
      marginLeft: '16px ',
      justifyContent: 'start',
    },
  }),
);
export const MarketingMaterial: FC = React.memo(() => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {marketingMaterials, activeCategorys, activeMaterials, materialDownloaded} = useSelector(
    (store: AppState) => store.marketingMaterialReducer,
  );

  const {organizationId} = useSelector((store: AppState) => store.organizationReducer);

  const [selectedCategory, setSelectedCategory] = useState<MarketingMaterialCategories>(
    MarketingMaterialCategories.WindPower,
  );
  const [selectedMarketingMaterials, setSelectedMarketingMaterials] = useState<MarketingMaterialPageData>({
    ...DefaultMarketingMaterialPageData(selectedCategory),
  });
  const isSelectedCategoryActive = activeCategorys.includes(selectedCategory);

  useEffect(() => {
    if (activeCategorys.find((category) => category === selectedCategory) === undefined)
      activeCategorys[0] && setSelectedCategory(activeCategorys[0]);
    //eslint-disable-next-line
  }, [activeCategorys]);

  const [accessMarketingMaterialIds, setAccessMarketingMaterialsIds] = useState<number[]>([]);

  useEffect(() => {
    const accessMarketingMaterialIds = activeMaterials.filter(
      (ActiveMaterials) => ActiveMaterials.Category === selectedCategory,
    );
    setAccessMarketingMaterialsIds(accessMarketingMaterialIds[0]?.ActiveIds);
  }, [selectedCategory, activeMaterials]);

  useEffect(() => {
    setSelectedMarketingMaterials(
      marketingMaterials.find((mm) => mm.Category === selectedCategory)?.MarketingMaterialPageData ??
        DefaultMarketingMaterialPageData(selectedCategory),
    );
  }, [selectedCategory, marketingMaterials]);

  useEffect(() => {
    dispatch(removeNotification(selectedCategory));
    organizationId && userClient.updateLastOpenedMarketingPage(organizationId);
  }, [selectedCategory, selectedMarketingMaterials, dispatch, organizationId]);

  const handleCategoryChange = useCallback((category: MarketingMaterialCategories) => {
    setSelectedCategory(category);
  }, []);

  return (
    <PageTitle title={localized('MarketingMaterial')}>
      <Box className={classes.responseiveBox}>
        <PageHeader area="template" title={localized('MarketingMaterial')} />
        <Typography className={classes.headerText} color={'textSecondary'} variant="body1">
          {localized('MarketingMaterialDescription')}
        </Typography>
      </Box>

      <PageContentContainer>
        {materialDownloaded && (
          <>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} flexWrap={'wrap'}>
              <MaterialFilterMenu selectedCategory={selectedCategory} handleFilterChange={handleCategoryChange} />
              {isSelectedCategoryActive ? (
                <DownloadAllButton
                  name={GetCategoryName(selectedCategory)}
                  organizationId={organizationId ? organizationId : 0}
                  availableIds={accessMarketingMaterialIds}></DownloadAllButton>
              ) : (
                ''
              )}
            </Box>
            {isSelectedCategoryActive ? (
              <MarketingMaterialGridHolder
                organizationId={organizationId ?? 0}
                selectedCategory={selectedCategory}
                selectedMarketingMaterials={selectedMarketingMaterials}></MarketingMaterialGridHolder>
            ) : (
              <NotAvailableCard selectedCategory={selectedCategory} organizationId={organizationId ?? 0} />
            )}
            <ContactFooter />
          </>
        )}
        {!materialDownloaded && (
          <Box className={classes.loading}>
            <CircularProgress />
          </Box>
        )}
      </PageContentContainer>
    </PageTitle>
  );
});
