import {Box, Grid} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {localized} from '../../../i18n/i18n';
import {getCustomerServiceInfo} from '../../../state/features/contact/operation';
import {getDebtorPostingDocumentDetails, getDocumentDetails} from '../../../state/features/document/operation';
import {getDebtorPostings} from '../../../state/features/invoice/operation';
import {AppState} from '../../../state/store';
import {PageContentContainer} from '../../../view/components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../view/components/default-components/page-header.tsx/page-header';
import {PageTitle} from '../../../view/components/default-components/page-title/page-title';
import {EconomyHeader} from '../../components/economy/economy-header';
import {EconomyHeaderHowTo} from '../../components/economy/economy-header-how-to';
import {FilterInvoices} from '../../components/economy/filter-invoices';
import {DebtorPostingDocumentCard} from '../../components/economy/invoice-document-card';
import {InvoiceTableOverview} from '../../components/economy/invoice-table-overview';
import {NextInvoiceCard} from '../../components/economy/next-invoice-card';
import {AnnualStatementDocumentCard} from '../../components/economy/annual-statement-document-card';

export const Economy = React.memo(() => {
  const dispatch = useDispatch();

  // Fetch page data
  const {organizationId} = useSelector((store: AppState) => store.organizationReducer);

  useEffect(() => {
    if (organizationId) {
      dispatch(getDebtorPostings(organizationId));
      dispatch(getDebtorPostingDocumentDetails(organizationId));
      dispatch(getDocumentDetails(organizationId));
    }

    dispatch(getCustomerServiceInfo());
  }, [dispatch, organizationId]);
  return (
    <PageTitle title="Economy">
      <PageHeader area="template" title={localized('Economy')}></PageHeader>

      <PageContentContainer>
        <Grid container direction={'row'} wrap={'wrap'}>
          <Box flexDirection={'column'} style={{minWidth: '58.333333%'}}>
            <EconomyHeader />
            <NextInvoiceCard />
          </Box>
          <Box flexDirection={'column'} flexGrow={1}>
            <EconomyHeaderHowTo />
            <FilterInvoices />
          </Box>
        </Grid>
        <Grid container direction={'row'}>
          <InvoiceTableOverview />
          <DebtorPostingDocumentCard />
        </Grid>
        <Grid container justify={'flex-end'}>
          <AnnualStatementDocumentCard />
        </Grid>
      </PageContentContainer>
    </PageTitle>
  );
});
