import React from 'react';
import {localized} from '../../../i18n/i18n';
import {formatToLocalDateAndLeadingZeros} from '../../../utilities/platform-helpers/date-helpers';
import {ContractInfoCardItem} from './contract-info-card-item';

export const getContractStart = (date: Date) => {
  return <ContractInfoCardItem category={localized('StartDate')} valueText={formatToLocalDateAndLeadingZeros(date)} />;
};
export const getContractEnd = (date: Date) => {
  return <ContractInfoCardItem category={localized('EndDate')} valueText={formatToLocalDateAndLeadingZeros(date)} />;
};
export const getPriceDistrict = (priceDistrict: string) => {
  return <ContractInfoCardItem category={localized('PriceDistrict')} valueText={priceDistrict} />;
};
export const getPrice = (price: number) => {
  return <ContractInfoCardItem category={localized('Price')} valuePrice={price} />;
};
export const getPriceMwh = (price: number) => {
  return <ContractInfoCardItem category={localized('PriceMwh')} valuePrice={price} />;
};
export const getPremium = (premium: string) => {
  return <ContractInfoCardItem category={localized('PremiumMwh')} valueText={premium} />;
};

// Original Ceiling
export const getOriginalCeilingYear1 = (ceilingValue: number) => {
  return <ContractInfoCardItem category={localized('OriginalCeilingYear1')} valueCeiling={ceilingValue} />;
};
export const getOriginalCeilingYear2 = (ceilingValue: number) => {
  return <ContractInfoCardItem category={localized('OriginalCeilingYear2')} valueCeiling={ceilingValue} />;
};
export const getOriginalCeilingYear3 = (ceilingValue: number) => {
  return <ContractInfoCardItem category={localized('OriginalCeilingYear3')} valueCeiling={ceilingValue} />;
};
export const getOriginalCeilingYear4 = (ceilingValue: number) => {
  return <ContractInfoCardItem category={localized('OriginalCeilingYear4')} valueCeiling={ceilingValue} />;
};
export const getOriginalCeilingYear5 = (ceilingValue: number) => {
  return <ContractInfoCardItem category={localized('OriginalCeilingYear5')} valueCeiling={ceilingValue} />;
};
export const getOriginalCeilingYear6 = (ceilingValue: number) => {
  return <ContractInfoCardItem category={localized('OriginalCeilingYear6')} valueCeiling={ceilingValue} />;
};

// Current Ceiling
export const getExpectedCeilingYear1 = (ceilingValue: number) => {
  return <ContractInfoCardItem category={localized('ExpectedCeilingYear1')} valueCeiling={ceilingValue} />;
};
export const getExpectedCeilingYear2 = (ceilingValue: number) => {
  return <ContractInfoCardItem category={localized('ExpectedCeilingYear2')} valueCeiling={ceilingValue} />;
};
export const getExpectedCeilingYear3 = (ceilingValue: number) => {
  return <ContractInfoCardItem category={localized('ExpectedCeilingYear3')} valueCeiling={ceilingValue} />;
};
export const getExpectedCeilingYear4 = (ceilingValue: number) => {
  return <ContractInfoCardItem category={localized('ExpectedCeilingYear4')} valueCeiling={ceilingValue} />;
};
export const getExpectedCeilingYear5 = (ceilingValue: number) => {
  return <ContractInfoCardItem category={localized('ExpectedCeilingYear5')} valueCeiling={ceilingValue} />;
};
export const getExpectedCeilingYear6 = (ceilingValue: number) => {
  return <ContractInfoCardItem category={localized('ExpectedCeilingYear6')} valueCeiling={ceilingValue} />;
};


export const getSupplement = (ceilingValue: number) => {
  return <ContractInfoCardItem category={localized('Supplement')} valueCeiling={ceilingValue} />;
};

export const getEmptySpace = () => {
  return <ContractInfoCardItem emptySpace={true} />;
};


