import {Box, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import React, {FC} from 'react';
import {localized} from '../../../i18n/i18n';
import {disabledColor, disabledImageColor} from '../../../styles/color-constants';
import {h5} from '../../../styles/font-size-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';

interface PropsFromParent {
  description?: string;
  ImgSrc: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    squareBanner: {
      display: 'flex',
      gap: 9,
      aspectRatio: '343 / 80',
      margin: '10px',
      marginBottom: '0px',
      marginTop: '30px',
      padding: '10px',
      overflow: 'auto',
      justifyContent: 'space-between',
    },
    imgBox: {
      height: '100%',
      aspectRatio: '1 / 1',
      objectFit: 'cover',
    },
    activeTypography: {
      color: 'inherit',
      fontSize: h5,
      fontFamily: 'Lato',
      fontWeight: 'normal',
    },
    inActiveTypography: {
      color: disabledColor,
      fontSize: h5,
      fontFamily: 'Lato',
      fontWeight: 'normal',
    },
  }),
);
export const SquareBanner: FC<PropsFromParent> = React.memo((PropsFromParent) => {
  const classes = useStyles();
  return (
    <BasicCard className={classes.squareBanner}>
      <Box flex={1} display="flex" justifyContent="space-between" alignItems="center">
        {PropsFromParent.description ? (
          <Typography
            className={PropsFromParent.ImgSrc === 'grey' ? classes.inActiveTypography : classes.activeTypography}>
            {PropsFromParent.description}
          </Typography>
        ) : (
          <Typography
            className={PropsFromParent.ImgSrc === 'grey' ? classes.inActiveTypography : classes.activeTypography}>
            {localized('SquareBanner')}
          </Typography>
        )}
        {PropsFromParent.ImgSrc === 'grey' ? (
          <Box bgcolor={disabledImageColor} className={classes.imgBox}></Box>
        ) : (
          <img src={PropsFromParent.ImgSrc} alt="SquareBanner" className={classes.imgBox} />
        )}
      </Box>
    </BasicCard>
  );
});
