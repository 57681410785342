import React, {FC} from 'react';
import {Box, BoxProps} from '@material-ui/core';

export const FlexRow: FC<BoxProps> = React.memo((props) => {
  return (
    <Box display="flex" flexDirection="row" width={1} {...props}>
      {props.children}
    </Box>
  );
});
