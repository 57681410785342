import {createAsyncThunk} from '@reduxjs/toolkit';
import {DebtorPostingClient} from '../../../api/api';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {debtorPostingClient} from '../../api-clients';
import {DebtorPostingFilter} from './types';

export const getDebtorPostings = createAsyncThunk('debtorPostings/getDebtorPostings', (organizationId: number | undefined) => {
  return AsyncOperationHandler((client) => (client as DebtorPostingClient).getDebtorPostingsDetails(organizationId), debtorPostingClient);
});

export const SetCurrentFilter = createAsyncThunk('debtorPostings/SetCurrentFilter', (filter: DebtorPostingFilter) => {
  return filter;
});
