import { createAsyncThunk } from '@reduxjs/toolkit';
import {ContactClient, CustomerServiceClient} from '../../../api/api';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {contactClient, customerServiceClient} from '../../api-clients';

export const getCustomerServiceInfo = createAsyncThunk('customerServiceInfo/getCustomerServiceInfo', () => {
  return AsyncOperationHandler(
    (client) => (client as CustomerServiceClient).getCustomerServiceInfo(),
    customerServiceClient,
  );
});

export const getContactsDetails = createAsyncThunk('contacts/getContacts', (organizationId: number) => {
  return AsyncOperationHandler((client) => (client as ContactClient).getContacts(organizationId), contactClient);
});
