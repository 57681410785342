import {createSlice} from '@reduxjs/toolkit';
import {IStatusMessageDto} from '../../../api/api';
import {deleteStausMessages, getStatusMessages, saveStausMessages} from './operation';
import {StatusMessagesState} from './types';

const initialState: StatusMessagesState = {
  statusMessageSlice: [] as IStatusMessageDto[],
  pending: false,
  isSuccess: false,
};

export const statusMessagesSlice = createSlice({
  name: 'statusMessages',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get
    builder.addCase(getStatusMessages.fulfilled, (state, action) => {
      state.pending = false;
      if (action.payload) {
        state.statusMessageSlice = action.payload;
      }
    });

    builder.addCase(getStatusMessages.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getStatusMessages.rejected, (state, action) => {
      state.pending = false;
    });

    // Save
    builder.addCase(saveStausMessages.fulfilled, (state, action) => {
      state.pending = false;
      state.isSuccess = true;
      if (action.payload) {
        state.statusMessageSlice.push(action.payload);
      }
    });

    builder.addCase(saveStausMessages.pending, (state, action) => {
      state.isSuccess = false;
      state.pending = true;
    });

    builder.addCase(saveStausMessages.rejected, (state, action) => {
      state.isSuccess = false;
      state.pending = false;
    });

    // Delete
    builder.addCase(deleteStausMessages.fulfilled, (state, action) => {
      state.pending = false;
      state.isSuccess = true;
      state.statusMessageSlice.splice(
        state.statusMessageSlice.findIndex((s) => s.id === action.meta.arg),
        1,
      );
    });

    builder.addCase(deleteStausMessages.pending, (state, action) => {
      state.isSuccess = false;
      state.pending = true;
    });

    builder.addCase(deleteStausMessages.rejected, (state, action) => {
      state.isSuccess = false;
      state.pending = false;
    });
  },
});

// eslint-disable-next-line
export const {} = statusMessagesSlice.actions;
export default statusMessagesSlice.reducer;
