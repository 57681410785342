import {createAsyncThunk} from '@reduxjs/toolkit';
import {IStatusMessageDto, StatusMessageClient, StatusMessageDto} from '../../../api/api';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {statusMessagesClient} from '../../api-clients';

export const getStatusMessages = createAsyncThunk('statusMessages/getStatusMessages', () => {
  return AsyncOperationHandler((client) => (client as StatusMessageClient).getStatusMessages(), statusMessagesClient);
});

export const saveStausMessages = createAsyncThunk(
  'statusMessages/saveStatusMessages',
  (statusMessage: IStatusMessageDto) => {
    return AsyncOperationHandler(
      (client) => (client as StatusMessageClient).postNewStatusMessages(StatusMessageDto.fromJS(statusMessage)),
      statusMessagesClient,
      true,
    );
  },
);

export const deleteStausMessages = createAsyncThunk(
  'statusMessages/deleteStatusMessages',
  (statusMessageId: number) => {
    return AsyncOperationHandler(
      (client) => (client as StatusMessageClient).deleteStatusMessages(statusMessageId),
      statusMessagesClient,
    );
  },
);
