import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {arrowColor, primaryTextColor} from '../../../styles/color-constants';

export const userInfoUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardClass: {
      minWidth: 240,
      maxWidth:  'calc(100vw - 64px)',
      minHeight: 380,
    },
    boxClass: {
      marginTop: 20
    },
    cardText: {
      flexGrow: 1,
    },
    cardIcon: {
      marginRight: 10,
    },
    arrow: {
      opacity: 1,
      color: arrowColor,
      '&:hover': {
        color: primaryTextColor,
      },
    },
    contentContainer: {display: 'flex', flexDirection: 'row', marginTop: 20},
    flexDirectionRow: {display: 'flex', flexDirection: 'row', justifyContent: 'space-between'},
    defaultContent: {marginTop: 10, minWidth: 200},
    minWidth70: {minWidth: 70},
    minWidth150: {minWidth: 150},
    minWidth180: {minWidth: 180},
    minWidth200: {minWidth: 200},
    minWidth250: {
      minWidth: 250,
      [theme.breakpoints.down('sm')]: {
        minWidth: 200,
      },
    },
    marginTopTen: {marginTop: 10},
    button: {
      display: 'flex',
      marginTop: 5,
      marginBottom: 5,
      width: 160,
      justifyContent: 'center',
      backgroundColor: '#fff',
      borderRadius: 10,
      color: primaryTextColor,
      boxShadow: 'rgb(131 141 155 / 20%) 3px 3px 10px',
      alignItems: 'center',
      flexDirection: 'row',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
    },
    buttonFlexBox: {
      display: 'flex',
      justifyContent: 'flex-start',
      '@media (min-width: 570px)': {
        justifyContent: 'flex-end',
      },
    },
    infoContainer: {
      marginLeft: 10,
    },
    contactCardWrapper: {
      [theme.breakpoints.up('sm')]: {
        width: 500,
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    noTopMargin: {
      marginTop: 0,
    }
  }),
);
