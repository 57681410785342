import {Grid} from '@material-ui/core';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IContractDto, IDocumentDto, ProductCategory, DocumentType} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {getContractsDetails} from '../../../state/features/contract/operation';
import {getDocumentDetails} from '../../../state/features/document/operation';
import {AppState} from '../../../state/store';
import {DateRangePicker} from '../../../view/components/default-components/date-range-picker/date-range-picker';
import {PageContentContainer} from '../../../view/components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../view/components/default-components/page-header.tsx/page-header';
import {PageTitle} from '../../../view/components/default-components/page-title/page-title';
import {ContractCard} from '../../components/contract/contract-card';
import {DocumentCard} from '../../components/document/document-card';
import {FilterProvider} from '../../components/filterprovider';

export const Documents = React.memo(() => {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(moment().add(-1, 'years').toDate());
  const [toDate, setToDate] = useState(
    moment()
      .add(+10, 'years')
      .toDate(),
  );
  const {pending, documentsSlice} = useSelector((store: AppState) => store.documentsReducer);
  const {contractsSlice} = useSelector((store: AppState) => store.contractsReducer);
  const [contractDocs, setContractDocs] = useState<IContractDto[]>();
  const [greenDocs, setGreenDocs] = useState<IContractDto[]>();
  const [reportsDocs, setReportsDocs] = useState<IDocumentDto[]>();

  // Fetch page data
  const {organizationId} = useSelector((store: AppState) => store.organizationReducer);
  useEffect(() => {
    if (organizationId) {
      dispatch(getDocumentDetails(organizationId));
      dispatch(getContractsDetails(organizationId));
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    setContractDocs(contractsSlice.filter((d) => d.productCategory === ProductCategory.ElectricitySales));
    setGreenDocs(contractsSlice.filter((d) => d.productCategory === ProductCategory.GreenEnergy));
    setReportsDocs(documentsSlice.filter((d) => d.documentType === DocumentType.GreenOptimizationReports));
  }, [contractsSlice, documentsSlice]);

  const handleFromDateChanged = (event: Date) => {
    setFromDate(event);
  };
  const handleToDateChanged = (event: Date) => {
    setToDate(event);
  };

  return (
    <PageTitle title="Documents">
      <PageHeader area="template" title={localized('Documents')}></PageHeader>
      <PageContentContainer>
        <DateRangePicker
          dateFrom={fromDate}
          dateFromChanged={handleFromDateChanged}
          dateTo={toDate}
          dateToChanged={handleToDateChanged}
        />
        <Grid container direction="row">
          <Grid item xs={12} sm={12} lg={6} xl={4}>
            <FilterProvider>
              <ContractCard
                title={localized('Agreements')}
                parentContracts={contractDocs}
                pending={pending}
                cardHeight={540}
                dateFrom={fromDate}
                dateTo={toDate}
                docuNumber={8}
                contractPage={false}
              />
            </FilterProvider>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={4}>
            <FilterProvider>
              <ContractCard
                title={localized('GreenEnergy')}
                parentContracts={greenDocs}
                pending={pending}
                cardHeight={540}
                dateFrom={fromDate}
                dateTo={toDate}
                docuNumber={8}
                contractPage={false}
              />
            </FilterProvider>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={4}>
            <FilterProvider>
              <DocumentCard
                title={localized('Reports')}
                parentDocuments={reportsDocs!}
                pending={pending}
                dateFrom={fromDate}
                dateTo={toDate}
                docuNumber={10}
              />
            </FilterProvider>
          </Grid>
        </Grid>
      </PageContentContainer>
    </PageTitle>
  );
});
