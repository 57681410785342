import MomentUtils from '@date-io/moment';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {MaterialUiPickersDate} from '@material-ui/pickers/typings/date';
import React, {FC, useCallback} from 'react';
import {localized} from '../../../../i18n/i18n';
import {DateFormat} from '../../../../utilities/constants';
import {makeStyles, Theme, createStyles, Box} from '@material-ui/core';
import {basicCardBgColor, basicCardBoxShadow} from '../../../../styles/color-constants';

interface PropsFromParent {
  dateFromChanged: (DateFrom: Date) => void;
  dateToChanged: (DateTo: Date) => void;
  dateFrom: Date;
  dateTo: Date;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datepickerContainer: {
      display: 'flex',

      maxHeight: '40px',
    },
    alignCenterPaddingTop20: {
      alignSelf: 'center',
      lineHeight: '40px',
    },
    datePickerToDate: {
      marginTop: '0px',
      marginBottom: '0px',
      marginRight: '5px',
      alignSelf: 'center',
      '& >p': {
        display: 'none',
      },
      '&>div': {
        minWidth: 'fit-content',
        flexDirection: 'row-reverse',
        '&.MuiInput-underline:before': {
          border: 'none !important',
        },
        '&.MuiInput-underline:after': {
          border: 'none !important',
        },
        '& .MuiInputBase-input': {
          width: '70px',
        },
        '& .MuiInputAdornment-positionEnd': {
          marginLeft: '0px',
        },
      },
    },
    datePickerFromDate: {
      marginTop: '0px',
      marginBottom: '0px',
      marginRight: '2px',
      alignSelf: 'center',
      '& >p': {
        display: 'none',
      },
      '&>div': {
        minWidth: 'fit-content',
        flexDirection: 'row-reverse',
        '&.MuiInput-underline:before': {
          border: 'none !important',
        },
        '&.MuiInput-underline:after': {
          border: 'none !important',
        },
        '& .MuiInputBase-input': {
          width: '70px',
        },
        '& .MuiInputAdornment-positionEnd': {
          marginLeft: '0px',
        },
      },
    },
    documentLayerCard: {
      overflow: 'hidden',
      maxHeight: '40px',
      maxWidth: '260px',
      minWidth: '210px',
      backgroundColor: basicCardBgColor,
      boxShadow: basicCardBoxShadow,
      borderRadius: '10px',
      position: 'relative',

      padding: '0px 0px 5px',
      [theme.breakpoints.up('md')]: {
        margin: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '5px 5px 5px 5px',
      },
      [theme.breakpoints.down('xs')]: {
        margin: '5px 0 5px 0',
      },
    },
  }),
);

export const DateRangePicker: FC<PropsFromParent> = React.memo(({dateFrom, dateFromChanged, dateTo, dateToChanged}) => {
  const classes = useStyles();

  const dateFromChangedLocal = useCallback(
    (d: MaterialUiPickersDate) => {
      if (d) {
        dateFromChanged(d.toDate());
      }
    },
    [dateFromChanged],
  );

  const dateToChangedLocal = useCallback(
    (d: MaterialUiPickersDate) => {
      if (d) {
        dateToChanged(d.toDate());
      }
    },
    [dateToChanged],
  );

  return (
    <Box className={classes.documentLayerCard}>
      <div className={classes.datepickerContainer}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            margin="normal"
            className={classes.datePickerFromDate}
            format={DateFormat}
            maxDate={dateTo}
            value={dateFrom}
            onChange={dateFromChangedLocal}
          />

          <span className={classes.alignCenterPaddingTop20}>{localized('To')}</span>

          <KeyboardDatePicker
            margin="normal"
            className={classes.datePickerToDate}
            format={DateFormat}
            maxDate={new Date()}
            value={dateTo}
            onChange={dateToChangedLocal}
          />
        </MuiPickersUtilsProvider>
      </div>
    </Box>
  );
});
