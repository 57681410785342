// Theme colors
export const primaryColor = '#006e64';
export const primaryTextColor = '#000000';
export const primaryColorLighter = '#4B887E';


export const secondaryColor = primaryColor;
export const secondaryColorActive = primaryColorLighter;
export const secondaryTextColor = '#103933';
export const whiteTextColor = '#FCFCFC';

export const disabledColor = '#C6C6C6';
export const disabledImageColor = '#F2F2F2';

export const backgroundColor = '#F2F2F2';

export const environmentGreen = '#00C370';
export const validColor = primaryColor;
export const invalidColor = '#f44336';
export const warningColor = '#FF9E02';
export const warningIconColor = '#FF9E02';

// Component colors

// Headline and sub - card
export const cardHeadline = primaryTextColor;
export const cardSubText = '#5D5D5D';

// Header
export const headerBackgroundColor = backgroundColor;
export const headerIconColor = secondaryTextColor;
export const headerTextColor = secondaryTextColor;
export const headerSelectedTextColor = primaryTextColor;
export const headerSelectedIconColor = primaryTextColor;

// Sidebar
export const sidebarBackgroundColor = backgroundColor;
export const sidebarIconBorder = '2px solid #3333331';
export const sidebarTextColor = primaryTextColor;
export const sidebarBackgroundSelectedColor = primaryColor;
export const sidebarItemSelectedColor = whiteTextColor;

// Content Wrapper
export const contentWrapperBackgroundColor = secondaryColor;
export const contentWrapperTextColor = secondaryTextColor;

// Basic Card
export const basicCardBgColor = '#FCFCFC';
export const basicCardBoxShadow = '3px 3px 10px #838D9B33';
export const basicCardTextColor = '#5D5D5D';

// Tooltip
export const tooltipPrimaryTextColor = primaryTextColor;
export const tooltipSecondaryTextColor = '#959595';

// Customer Service Help
export const speechBubbleShadow = '3px 3px 3px 3px #838D9B33';

// Button
export const btnBackgroundColor = primaryColor;
export const btnTextColor = '#5D5D5D';
export const btnDisabledColor = '#838D9B33';
export const btnHoverBackgroundColor = btnDisabledColor;
export const btnHoverTextColor = primaryTextColor;
export const btnBorderColor = '1px solid #E0E0E0'

// Arrow Button
export const arrowColor = '#959595'

// Input
export const inputTextColor = secondaryTextColor;

// Toggle
export const toggleButtonColor = primaryTextColor;
export const toggleButtonColorSelected = primaryColor;
export const toggleButtonTextColor = '#FFFFFF';

// Switch
export const switchThumbOnColor = '#FFFFFF';
export const switchThumbOffColor = primaryTextColor;
export const switchTrackOnColor = primaryColor;
export const switchTrackOffColor = '#FFFFFF';
export const switchBorderOnColor = primaryColor;
export const switchBorderOffColor = primaryTextColor;

// Info item
export const infoItemBackgroundColor = secondaryColor;
export const infoItemTextColor = secondaryTextColor;

// Textfield
export const textFieldInputColor = secondaryTextColor;
export const textFieldLabelColor = primaryTextColor;
export const textFieldSuffixColor = primaryTextColor;
export const textFieldPrefixColor = primaryTextColor;

// Checkbox Filter
export const checkboxFilterBg = '#FAFAFA';
export const checkboxChecked = primaryColor;
export const checkBoxUnChecked = primaryTextColor;

// SearchInput
export const searchBgColor = '#E6E6E6';
export const searchTextColor = secondaryTextColor;

// List Table
export const ListTableBgColor = secondaryColor;
export const ListTableTextColor = secondaryTextColor;

// Info item 2
export const infoItem2BackgroundColor = '#FAFAFA';
export const infoItem2TextColor = secondaryTextColor;

// Navigation icon color
export const navigationIconColor = '#c6c6c6'

// Chart
export const primaryChartColor = primaryColor;
export const secondaryChartColor = '#93B7B1';
export const primaryAverageChartColor = primaryTextColor;
export const secondaryAverageChartColor = primaryColorLighter;
export const primaryAverageLabelColor = primaryColorLighter;
export const secondaryAverageLabelColor = '#FFD873';

// Code input
export const inputCodeBoarderColor = navigationIconColor
export const inputCodeBackgroundColor = checkboxFilterBg
export const inputCodeTextColor = primaryTextColor

// Checkmark
export const checkmarkColor = '#00C370';
