// src/react-auth0-spa.js
import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {logOutAuth, setNewToken, setTriggerSignOut, setIsInitialized} from '../state/features/auth/auth-slice';
import {AppState, store} from '../state/store';
import {usePrevious} from '../utilities/use-previous-hook';
import {
  clientGetTokenSilently,
  clientGetUser,
  clientHandleRedirectCallback,
  clientIsAuthenticated,
  clientLoginWithPopup,
  clientLoginWithRedirect,
  clientLogout,
  getUserRole,
  initAuthSettings,
} from './b2c/ad-b2c-auth-helper';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);
let getTokenCounter = 0;
export const AuthContext = React.createContext({} as any);
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions}: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<any>();
  const [user, setUser] = useState();
  const [role, setRole] = useState('');
  const [authSettings, setAuthSettings] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [waitForResponse, setWaitForResponse] = useState(false);
  const {triggerSignOut} = useSelector((store: AppState) => store.authReducer);
  const prevTriggerSignOut = usePrevious(triggerSignOut);

  const dispatch = useDispatch();
  const logout = (p: any) => {
    store.dispatch(logOutAuth());
    clientLogout(authSettings, p);
  };
  useEffect(() => {
    const initAuth = async () => {
      try {
        const tmpAuthSettings = await initAuthSettings(setWaitForResponse);
        setAuthSettings(tmpAuthSettings);
      } catch (error) {}
    };
    initAuth();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (triggerSignOut && triggerSignOut !== prevTriggerSignOut) {
      logout({returnTo: window.location.origin});
      dispatch(setTriggerSignOut(false));
    }
    // eslint-disable-next-line
  }, [dispatch, logout, setTriggerSignOut, triggerSignOut, prevTriggerSignOut]);

  useEffect(() => {
    const loadingChanged = async () => {
      if (waitForResponse && authSettings) {
        const tmpIsAuthenticated = await clientIsAuthenticated(authSettings);
        setIsAuthenticated(tmpIsAuthenticated);
        if (tmpIsAuthenticated) {
          const tmpUser = await clientGetUser(authSettings);
          setUser(tmpUser);
          getTokenSilently(authSettings).then((newToken) => {
            dispatch(setNewToken(newToken));
            setRole(getUserRole());
            dispatch(setIsInitialized(true));
          });
        }

        setLoading(false);
      }
    };

    loadingChanged();

    // eslint-disable-next-line
  }, [waitForResponse, authSettings]);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await clientLoginWithPopup(authSettings, params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }

    const tmpUser = await clientGetUser(authSettings);
    setUser(tmpUser);
    setRole(getUserRole());
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    await clientHandleRedirectCallback(authSettings);
  };

  const loginWithRedirect = (p: any) => {
    clientLoginWithRedirect(authSettings, p);
  };

  const getIdTokenClaims = (p: any) => authSettings.getIdTokenClaims(p);

  const getTokenSilently = async (p: any) => {
    // This secures endless get access tokens retry. e.g if frontend Auth doesn't match backend Auth
    resetTokenCounter();
    getTokenCounter++;
    if (getTokenCounter > 15) {
      logout(p);
    }

    let item = await clientGetTokenSilently(authSettings, p);
    setRole(getUserRole());
    const tmpUser = await clientGetUser(authSettings);
    setUser(tmpUser);
    return item;
  };

  const getTokenWithPopup = (p: any) => authSettings.getTokenWithPopup(p);

  let getTokenTimeout: NodeJS.Timeout | undefined;
  function resetTokenCounter() {
    if (getTokenTimeout) {
      clearTimeout(getTokenTimeout);
      getTokenTimeout = undefined;
    }
    getTokenTimeout = setTimeout(() => {
      getTokenCounter = 0;
    }, 2000);
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        role,
        loading,
        popupOpen,
        waitForResponse,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims,
        loginWithRedirect,
        getTokenSilently,
        getTokenWithPopup,
        logout,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
