import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';
import React, {FC, useCallback} from 'react';
import {ListTableTextColor} from '../../../../styles/color-constants';
import {body2} from '../../../../styles/font-size-constants';
import {FlexRow} from '../../../components/default-components/flex-row';

interface PropsFromsParent<T> {
  columnSizes?: number[];
  objectValues: any[];
  itemSelected?: (item: T) => void;
  contentItem?: T;
  maxWidth?: (number | undefined)[];
  textAlign?: 'center';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemContainer: {
      height: 30,
      alignItems: 'center',
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
    itemText: {
      fontSize: body2,
      color: ListTableTextColor,
    },
    firstColumnMargin: {
      margin: '0px -5px 0px 5px',
    },
    lastColumnMargin: {
      margin: '0px 15px 0px -30px',
    },
    rightAlignColumMargin: {
      margin: '0px 30px 0px -30px',
    },
    showCursor: {
      cursor: 'pointer',
    },
  }),
);

type Props = PropsFromsParent<any>;

export const ListTableItem: FC<Props> = React.memo(
  ({columnSizes, objectValues, itemSelected, contentItem, maxWidth, textAlign}) => {
    const classes = useStyles();
    const itemSelectedLocal = useCallback(() => {
      if (itemSelected && contentItem) {
        itemSelected(contentItem);
      }
    }, [itemSelected, contentItem]);

    return (
      <FlexRow
        onClick={itemSelectedLocal}
        className={clsx(classes.itemContainer, {[classes.showCursor]: itemSelected})}>
        {objectValues.map((objectValue, index) => {
          const flex = columnSizes && columnSizes[index];
          let width = maxWidth && maxWidth[index];
          if (!width && (index === 0 || index === objectValues.length - 1)) {
            width = 50;
          }
          return (
            <Box
              key={index}
              textAlign={index > 2 ? 'right' : textAlign}
              flex={flex}
              className={clsx(
                classes.itemText,
                index === 0 ? classes.firstColumnMargin : null,
                index > 2 ? (index === 5 ? classes.lastColumnMargin : classes.rightAlignColumMargin) : null,
              )}>
              {objectValue}
            </Box>
          );
        })}
      </FlexRow>
    );
  },
);
