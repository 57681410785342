import {ListItem, ListItemIcon, ListItemText, createStyles, makeStyles, Theme, Badge} from '@material-ui/core';
import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {
  primaryColor,
  sidebarTextColor,
  sidebarItemSelectedColor,
  sidebarIconBorder,
} from '../../../styles/color-constants';
import {h5} from '../../../styles/font-size-constants';
import {useDispatch, useSelector} from 'react-redux';
import {toggleDrawer} from '../../../state/features/webapp/webapp-slice';
import {AppState} from '../../../state/store';
import {useCallback} from 'react';

interface PropsFromParent {
  selected: boolean;
  icon?: JSX.Element;
  iconSelected?: JSX.Element;
  identifier: any;
  linkTo: string;
  text?: string;
  onMenuItemClicked?: (identifier: string) => void;
  unReadNotification?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fixFirefox: {
      color: sidebarTextColor,
      textDecoration: 'none',
    },
    icon: {
      minWidth: 50,
      maxWidth: 50,
      margin: '0 5px 0 0',
      color: sidebarIconBorder,
      tintColor: sidebarIconBorder,
    },
  }),
);

const StyledListItem = withStyles({
  root: {
    margin: '5px 0px',
    padding: '10px 10px',
    borderRadius: `10px`,
    '&$selected': {
      borderRadius: `10px`,
      backgroundColor: primaryColor,
      color: sidebarItemSelectedColor,
      '& .makeStyles-icon-32': {
        color: sidebarItemSelectedColor,
      },
      '& .WithStyles(ForwardRef(ListItem))-root-33.WithStyles(ForwardRef(ListItem))-selected-34': {
        color: sidebarItemSelectedColor,
      },
      '&:hover': {
        borderRadius: `10px`,
        backgroundColor: primaryColor,
        color: sidebarItemSelectedColor,
      },
    },
  },
  selected: {},
})(ListItem);

const StyledBadge = withStyles({
  badge: {
    position: 'relative',
    left: '-45%',
  },
})(Badge);

const StyledListItemText = withStyles({
  root: {
    marginLeft: '10px',
    whiteSpace: 'nowrap',
    '& span': {
      fontSize: h5,
    },
  },
})(ListItemText);

export const DrawerItem: FC<PropsFromParent> = React.memo((props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentToggleState = useSelector((state: AppState) => state.webAppReducer.open);
  const onMenuItemClicked = useCallback(() => {
    props.onMenuItemClicked && props.onMenuItemClicked(props.identifier);
    if (currentToggleState) {
      dispatch(toggleDrawer());
    }
  }, [dispatch, currentToggleState, props]);

  // if there is no unread notification, then the badge is invisible
  const invisible = props.unReadNotification ? false : true;

  return (
    <Link to={props.linkTo} className={classes.fixFirefox}>
      <StyledListItem selected={props.selected} onClick={onMenuItemClicked} button={true} key={props.identifier}>
        <StyledBadge style={{}} color="secondary" variant="dot" invisible={invisible}>
          <ListItemIcon className={classes.icon}>{props.selected ? props.iconSelected : props.icon}</ListItemIcon>
        </StyledBadge>
        <StyledListItemText primary={props.text} />
      </StyledListItem>
    </Link>
  );
});
