import {createStyles, makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';
import React, {FC, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {User} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {createUser} from '../../../state/features/user/operation';
import {AppState} from '../../../state/store';
import {basicCardBgColor, btnTextColor, secondaryColor, secondaryColorActive} from '../../../styles/color-constants';
import {h5} from '../../../styles/font-size-constants';
import {TextButton} from '../../../view/components/default-components/buttons/text-button';
import {BasicModal} from '../../../view/components/default-components/modal/basic-modal';
import {BasicTextField} from '../../../view/components/default-components/text-fields/basic-text-field';
import {TitleAndSubCard} from '../../../view/components/default-components/title-and-sub-card';

interface PropsFromParent {
  open: boolean;
  onClose: () => void;
}

export const CreateUserModalStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexDirectionRow: {flexDirection: 'row', justifyContent: 'space-between', display: 'flex', marginTop: '10px'},
    buttonActiveStyle: {
      minWidth: '80px',
      minHeight: '32px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: secondaryColor,
      margin: '10px 0px 10px 10px',
      paddingLeft: '10px',
      paddingRight: '10px',
      color: '#fff',
      boxShadow: '3px 3px 10px #838D9B33',
      border: '1px solid #E0E0E0',
      fontSize: h5,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: secondaryColorActive,
        cursor: 'pointer',
        color: '#FFF',
      },
    },
    buttonDisableStyle: {
      minWidth: '80px',
      minHeight: '32px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: basicCardBgColor,
      margin: '10px 0px 10px 10px',
      paddingLeft: '10px',
      paddingRight: '10px',
      color: btnTextColor,
      boxShadow: '3px 3px 10px #838D9B33',
      border: '1px solid #E0E0E0',
      fontSize: h5,
      textTransform: 'none',
    },
    buttonStyle: {
      minWidth: '80px',
      minHeight: '32px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: basicCardBgColor,
      margin: '10px 0px 10px 10px',
      color: btnTextColor,
      boxShadow: '3px 3px 10px #838D9B33',
      border: '1px solid #E0E0E0',
      fontSize: h5,
      textTransform: 'none',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#3333',
        color: '#FFF',
      },
    },
    textField: {
      margin: '10px 0px',
      border: '1px solid #000',
      '& .MuiInput-underline:before': {
        border: 'none',
      },
      '& .MuiInput-underline:after': {
        border: 'none',
      },
      '& .MuiInput-underline:hover:not(.mui-disabled):before': {
        border: 'none',
      },
      '& .MuiFormHelperText-root': {display: 'none'},
      '& .MuiInputBase-input': {
        lineHeight: '40px',
        height: '40px',
        padding: '0px 0px 0px 10px',
      },
    },
  }),
);

interface INewUser {
  name?: string;
  phoneNumber?: string;
  email?: string;
  title?: string;
}

export const CreateUserModal: FC<PropsFromParent> = React.memo((props: PropsFromParent) => {
  const [newUser, setNewUser] = useState<INewUser | undefined>(undefined);
  const selectedOrganizationId = useSelector(
    (state: AppState) => state.organizationReducer.organization?.organizationId,
  );
  const dispatch = useDispatch();
  const classes = CreateUserModalStyles();

  const handleNameChanged = (e: string) => {
    setNewUser({...newUser, name: e});
  };
  const handleEmailChanged = (e: string) => {
    setNewUser({...newUser, email: e});
  };
  const handlePhonenumberChanged = (e: string) => {
    setNewUser({...newUser, phoneNumber: e});
  };
  const handleTitleChanged = (e: string) => {
    setNewUser({...newUser, title: e});
  };

  const createUserClicked = useCallback(() => {
    if (selectedOrganizationId) {
      const data = {
        user: {
          email: newUser?.email,
          name: newUser?.name,
          phonenumber: newUser?.phoneNumber,
          title: newUser?.title,
          isDeleted: false,
        } as User,
        organizationId: selectedOrganizationId,
      };
      dispatch(createUser(data));
      setNewUser(undefined);
      props.onClose();
    }
  }, [newUser, selectedOrganizationId, dispatch, props]);

  const disableButton = () => {};

  return (
    <BasicModal onClose={props.onClose} open={props.open}>
      <TitleAndSubCard titleAlign={'center'} title={localized('CreateUser')} subTitle={undefined} />
      <BasicTextField
        text={newUser?.name}
        className={classes.textField}
        textChanged={handleNameChanged}
        placeholder={localized('Name')}></BasicTextField>
      <BasicTextField
        text={newUser?.email}
        className={classes.textField}
        textChanged={handleEmailChanged}
        placeholder={localized('Email') + '*'}
        required={true}
        requiredText={'Email is requried.'}></BasicTextField>
      <BasicTextField
        text={newUser?.phoneNumber}
        className={classes.textField}
        textChanged={handlePhonenumberChanged}
        placeholder={localized('PhoneNumber')}></BasicTextField>
      <BasicTextField
        text={newUser?.title}
        className={classes.textField}
        textChanged={handleTitleChanged}
        placeholder={localized('Title')}></BasicTextField>

      <div className={classes.flexDirectionRow}>
        <TextButton onClick={props.onClose} className={classes.buttonStyle}>
          {localized('Close')}
        </TextButton>
        <TextButton
          onClick={newUser?.email ? createUserClicked : disableButton}
          className={clsx(newUser?.email ? classes.buttonActiveStyle : classes.buttonDisableStyle)}>
          {localized('CreateUser')}
        </TextButton>
      </div>
    </BasicModal>
  );
});
