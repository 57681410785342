import {Box, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLayoutStyles} from '../../../app-routes';
import {localized} from '../../../i18n/i18n';
import {getOrganizationById} from '../../../state/features/organization/operation';
import {getUser, getUsersByOrganizationId, SetUserActive} from '../../../state/features/user/operation';
import {AppState} from '../../../state/store';
import {h5} from '../../../styles/font-size-constants';
import {PageContentContainer} from '../../../view/components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../view/components/default-components/page-header.tsx/page-header';
import {PageTitle} from '../../../view/components/default-components/page-title/page-title';
import {UserInfoCard} from '../../components/user-info/user-info-card';
import {UserOrganizationCard} from '../../components/user-info/user-organization-card';
import { UserManagementCard } from '../../components/user-management/user-management-card';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subText: {
      fontSize: h5,
      margin: '0 0 0 16px',
    },
  }),
);

export const UserInfo = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const layoutClasses = useLayoutStyles();
  const organizationId = useSelector((state: AppState) => state.organizationReducer.organization?.organizationId);
  
  useEffect(() => {
    dispatch(SetUserActive(true));
    return () => {
      dispatch(SetUserActive(false));
    };
  }, [dispatch]);
  const [accountNum] = useState<string>();
  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getUsersByOrganizationId(organizationId));
  }, [dispatch, organizationId]);
  useEffect(() => {
    if (accountNum) {
      dispatch(getOrganizationById(accountNum));
    }
  }, [accountNum, dispatch]);

  return (
    <PageTitle title="My information">
      <PageHeader area="template" title={localized('MyInformation')} />
      <Typography className={clsx(classes.subText, layoutClasses.desktopOnly)}>
        {localized('UserInformationDefaultText')}
      </Typography>
      <PageContentContainer>
        <Box display={'flex'} alignItems="center" flexWrap={'wrap'}>
          <UserOrganizationCard />
          <UserInfoCard />
          <UserManagementCard/>
        </Box>
      </PageContentContainer>
    </PageTitle>
  );
});
