import {Box, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import React, {FC, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {MarketingMaterialCategories} from '../../../api/api';
import Co2Kortlaegning from '../../../assets/icons/green-optimization/Co2Kortlaegning.svg';
import KLIMAskov from '../../../assets/icons/green-optimization/KLIMAskov.svg';
import KlimaTiltag from '../../../assets/icons/green-optimization/KlimaTiltag.svg';
import Raadgivning from '../../../assets/icons/green-optimization/Raadgivning.svg';
import VINDenergi from '../../../assets/icons/green-optimization/VINDenergi.svg';
import {localized, localizedInterpolation} from '../../../i18n/i18n';
import {chooseGreenOptimization} from '../../../state/features/green-optimization/operation';
import {GetCategoryName} from '../../../state/features/marketing-material/types';
import {showErrorSnackbar} from '../../../state/features/snackbar/snackbar-slice';
import {basicCardBgColor, primaryColor} from '../../../styles/color-constants';
import {h3, h5} from '../../../styles/font-size-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';

interface PropsFromParent {
  selectedCategory: MarketingMaterialCategories;
  organizationId: number;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    notAvailableCard: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '700px',
    },
    contactButton: {
      cursor: 'pointer',
      marginLeft: 0,
      marginRight: 5,
      minWidth: 40,
      maxWidth: 120,
      maxHeight: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: '#999',
        color: '#FFF',
        flex: 1,
      },
    },
    selected: {
      backgroundColor: primaryColor,
      color: basicCardBgColor,
      '&:hover': {
        backgroundColor: primaryColor,
        color: basicCardBgColor,
      },
    },
    activeTypo: {
      fontSize: h5,
      fontFamily: 'Lato',
      fontWeight: 'bold',
    },
    headerTypo: {
      fontSize: h3,
      fontFamily: 'Lato',
      fontWeight: 'bold',
    },
    bodyTypo: {
      fontSize: h5,
      fontFamily: 'Lato',
      fontWeight: 'normal',
    },

    image: {
      padding: '20%',
      flex: 1,
      height: '300px',
      objectFit: 'contain',
    },
  }),
);
export const NotAvailableCard: FC<PropsFromParent> = React.memo(({selectedCategory, organizationId}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const getIcon = useCallback(() => {
    switch (selectedCategory) {
      case MarketingMaterialCategories.Co2Mapping:
        return Co2Kortlaegning;
      case MarketingMaterialCategories.ClimateAction:
        return KlimaTiltag;
      case MarketingMaterialCategories.EnergyConsulting:
        return Raadgivning;
      case MarketingMaterialCategories.WindPower:
        return VINDenergi;
      case MarketingMaterialCategories.ClimateForest:
        return KLIMAskov;
      default:
        return Co2Kortlaegning;
    }
  }, [selectedCategory]);

  const [linkDisabled, setLinkDisabled] = React.useState(false);
  const handleOnClick = async () => {
    if (linkDisabled) return;
    if (organizationId) {
      setLinkDisabled(true);
      try {
        await chooseGreenOptimization(organizationId, selectedCategory?.toString() ?? '');
      } catch (error) {
        dispatch(showErrorSnackbar(''));
      }
      setLinkDisabled(false);
    }
  };

  return (
    <BasicCard className={classes.notAvailableCard}>
      <Box>
        <Typography className={classes.headerTypo}>
          {localizedInterpolation('MarketingNotAvailable', {product: GetCategoryName(selectedCategory)})}
        </Typography>
        <Typography className={classes.bodyTypo}>{localizedInterpolation('YouNeedToHaveTheRightProduct', {product: GetCategoryName(selectedCategory)})}</Typography>
        <Box display={'flex'} justifyContent={'center'}>
          {!linkDisabled && (
            <BasicCard className={classes.contactButton} onClick={handleOnClick}>
              <Typography className={classes.activeTypo}>{localized('ContactMe')}</Typography>
            </BasicCard>
          )}
        </Box>
      </Box>
      <Box display={'flex'} flex={1} maxWidth={'50%'} justifyContent={'center'}>
        <img src={getIcon()} alt="NotAvailableCard" className={classes.image} />
      </Box>
    </BasicCard>
  );
});
