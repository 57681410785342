import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {DocumentType, IDocumentDto} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {AppState} from '../../../state/store';
import {GridWithCustomBreakpoints} from '../../../styles/custom-grid-breakpoints';
import {DocumentCard} from '../../components/document/document-card';
import {FilterProvider} from '../../components/filterprovider';

export const AnnualStatementDocumentCard = React.memo(() => {
  const [annualStatementDocs, setAnnualStatementDocs] = useState<IDocumentDto[]>();
  const {pending, documentsSlice} = useSelector((store: AppState) => store.documentsReducer);
  const {currentFilter} = useSelector((state: AppState) => state.debtorPostingReducer);
  useEffect(() => {
    setAnnualStatementDocs(documentsSlice.filter((d) => d.documentType === DocumentType.AnnualStatement));
  }, [documentsSlice]);

  return (
    <GridWithCustomBreakpoints item xs={12} sm={12} md={12} lg={12} betweenlgandxl={5}>
      <FilterProvider>
        <DocumentCard
          title={localized('AnnualStatement')}
          parentDocuments={annualStatementDocs!}
          pending={pending}
          dateFrom={currentFilter.DateFrom}
          dateTo={currentFilter.DateTo}
          docuNumber={10}
        />
      </FilterProvider>
    </GridWithCustomBreakpoints>
  );
});
