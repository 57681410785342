import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {ClassNameMap} from '@material-ui/styles';
import React, {useCallback} from 'react';
import {ListRowProps} from 'react-virtualized';
import {localized} from '../../../i18n/i18n';
import {formatToLocalDateAndLeadingZeros} from '../../../utilities/platform-helpers/date-helpers';
import {ListTableItem} from '../../../view/components/default-components/list-table/list-table-item';
import {VirtualizedListTable} from '../../../view/components/default-components/virtual-list-table/virtual-list-table';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rowEven: {backgroundColor: '#e4e4e4', minWidth: (props: PropsFromParent) => props.minWidth},
    rowOdd: {minWidth: (props: PropsFromParent) => props.minWidth},
    
  }),
);

interface PropsFromParent {
  items: any[];
  keysToRender: string[];
  columnHeaders: string[];
  columnSizes: number[];
  maxHeight: number;
  minWidth: number;
  textAlign?: 'center';
}

export const ScanEnergyTable = React.memo((props: PropsFromParent) => {
  const classes = useStyles(props);
  const renderRow = useCallback(
    () => rowRenderer(props.items ? props.items : [], props.keysToRender, classes, props.columnSizes, props),
    [classes, props],
  );

  return (
    <>
      {props.items && (
        <VirtualizedListTable
          maxHeight={props.maxHeight}
          minWidth={props.minWidth}
          rowRenderer={renderRow()}
          textAlign={props.textAlign}
          items={props.items}
          columnSizes={props.columnSizes}
          columnHeaders={props.columnHeaders}
        />
      )}
    </>
  );
});

function rowRenderer<T>(
  data: T[],
  keys: string[],
  classes: ClassNameMap<'rowEven' | 'rowOdd'>,
  columnSizes: number[],
  propsFromParent: PropsFromParent,
) {
  return (props: ListRowProps) => (
    <div key={props.key} style={props.style} className={props.index % 2 ? classes.rowEven : classes.rowOdd}>
      {generateData<T>(data, props, keys as (keyof T)[], columnSizes, propsFromParent)}
    </div>
  );
}

function generateData<T>(
  data: T[],
  props: ListRowProps,
  keys: (keyof T)[],
  columnSizes: number[],
  propsFromParent: PropsFromParent,
) {
  return (
    <ListTableItem
      textAlign={propsFromParent.textAlign}
      contentItem={data[props.index]}
      {...props}
      columnSizes={columnSizes}
      objectValues={keys.map(generateListItems<T>(data, props))}
    />
  );
}
function generateListItems<T>(
  data: T[],
  props: ListRowProps,
): (value: keyof T, index: number, array: (keyof T)[]) => string | T[keyof T] {
  return (dataKey) => {
    if (data[props.index][dataKey] === undefined || data[props.index][dataKey] === null) {
      return '';
    }

    return data[props.index][dataKey] instanceof Date
      ? formatToLocalDateAndLeadingZeros((data[props.index][dataKey] as unknown) as Date)
      : data[props.index][dataKey] instanceof Boolean
      ? data[props.index][dataKey]
        ? localized('Yes')
        : localized('No')
      : data[props.index][dataKey] &&
        !isNaN(data[props.index][dataKey] as any)
      ? ((data[props.index][dataKey] as unknown) as number).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      : data[props.index][dataKey];
  };
}
