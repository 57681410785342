import {ThemeProvider} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import {SnackbarProvider} from 'notistack';
import React, {FC} from 'react';
import {Provider} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import {AppRoutes} from './app-routes';
import Callback from './auth/callback';
import {AuthProvider} from './auth/general-auth-provider';
import PrivateRoute from './auth/private-route';
import {store} from './state/store';
import {colorTheme} from './styles/color-theme';
import {useAuthHelper} from './utilities/platform-helpers/auth-helper';
import {LogoutAuth} from './view/components/default-components/logout-auth/logout-auth';
import {Snackbar} from './view/components/default-components/snackbar/snackbar';
import {SnackbarCloseButton} from './view/components/default-components/snackbar/snackbar-close-button';
import {NotificationProvider} from './utilities/helpers/notification-provider';

// Persist the whole store
const persistor = persistStore(store);

const App: FC = () => {
  const getCloseButton = (key: number | string) => {
    return <SnackbarCloseButton snackKey={key as number} />;
  };

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={colorTheme()}>
          <AuthProvider>
            <SnackbarProvider maxSnack={4} action={getCloseButton}>
              <NotificationProvider>
                <CssBaseline />
                <Snackbar />
                <LogoutAuth />
                <BrowserRouter>
                  <Switch>
                    <PrivateRoute path="/" component={AppRoutes} authHelper={useAuthHelper} />
                    <Route path="/callback" component={Callback} />
                  </Switch>
                </BrowserRouter>
              </NotificationProvider>
            </SnackbarProvider>
          </AuthProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
