import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/da'; // Import languages so the moment locale is set correctly
import 'moment/locale/en-gb';
import danishTranslation from './da.json';
import englishTranslation from './en.json';
import danishPlatformTranslation from './platform-translations/da.json';
import englishPlatformTranslation from './platform-translations/en.json';

export const Languages = {
  da: 'Dansk',
  en: 'English',
} as const;

export type LanguageType = keyof typeof Languages;
enum LanguageEnum {
  da = 'da',
  en = 'en',
}

export type Translation = typeof i18nResources.en.translation;

// NB. When adding a NEW TRANSLATION, REMEMBER to IMPORT the corresponding MOMENT LOCALE at the top!
const i18nResources = {
  da: {
    translation: {...danishPlatformTranslation, ...danishTranslation},
  },
  en: {
    translation: {...englishPlatformTranslation, ...englishTranslation},
  },
};

export type i18nTranslationKey = any;

i18n.use(detector);
i18n.init(
  {
    fallbackLng: LanguageEnum.en,
    resources: i18nResources,
    // have a common namespace used around the full app
    ns: ['translation'],
    defaultNS: 'translation',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it does escape per default to prevent xss!
    },
    react: {
      wait: false,
      //withRef: false,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default',
    },
  },
  (err, t) => {
    let userLang = navigator.language;
    if (userLang === LanguageEnum.da) {
      i18n.changeLanguage(LanguageEnum.da);
    }

    moment.locale([i18n.language, userLang === LanguageEnum.da ? LanguageEnum.da : LanguageEnum.en]);
  },
);

// type-safe translation lookup
export const localized = (key: keyof Translation): string => {
  return i18n.t(key);
};

export const localizedDynamic = (key: keyof Translation | string): string => {
  return i18n.t(key);
};

//fx:  localizedInterpolation('MarketingNotAvailable', {valueToReplace: "dynamic Value"})
export const localizedInterpolation = (key: keyof Translation, keysToReplace: any): string => {
  return i18n.t(key, keysToReplace);
};

export const getTranslation = (language: keyof typeof Languages) => i18n.getFixedT(language);

export const getLanguage = () => {
  return i18n.language === 'en-US' ? LanguageEnum.en : i18n.language;
};

export const changeLanguage = async (language: keyof typeof Languages) => {
  await i18n.changeLanguage(language);

  moment.locale([language, LanguageEnum.en]);
};

export const getTranslationNamespace = () => i18n.getFixedT('translation');

export const getLocaleData = () => {
  return moment.localeData(i18n.language);
};
