import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import { basicCardBoxShadow, primaryTextColor } from '../../styles/color-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root:{
      minWidth: '40px',
      minHeight: '40px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor:  (props: PropsFromParent)=> props.backgroundColor?props.backgroundColor: '#fff',
      color: primaryTextColor, 
      boxShadow: basicCardBoxShadow,
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
    },
  }),
);
interface PropsFromParent {
  onClick: (e: any) => void;
  backgroundColor?: string;
}

export const BasicCardButton: FC<PropsFromParent> = React.memo(({children, onClick, backgroundColor}) => {
  const classes = useStyles({backgroundColor} as PropsFromParent);

  const onClickLocal = useCallback(
    (e) => {
      onClick(e);
    },
    [onClick],
  );

  return (
    <Button className={classes.root} onClick={onClickLocal}>
      {children}
    </Button>
  );
});
