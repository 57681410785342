import {Box, createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {INews} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {GetAllNewsItems} from '../../../state/features/news/operation';
import {routes} from '../../../state/routes';
import {AppState} from '../../../state/store';
import {primaryColor, primaryTextColor} from '../../../styles/color-constants';
import {GridWithCustomBreakpoints} from '../../../styles/custom-grid-breakpoints';
import {h2, h4, h6} from '../../../styles/font-size-constants';
import {DashboardFilesCard} from '../../components/dashboard/dashboard-files-card';
import {DashboardFilesCardFooter} from '../../components/dashboard/dashboard-files-card-footer';
import {DashboardStatusMessageCard} from '../../components/dashboard/dashboard-status-message-card';

const useDocumentStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: primaryTextColor,
    },
    documentOrder: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignContent: 'stretch',
      height: 'fit-content',
      cursor: 'pointer',
    },
    documentHeader: {
      fontSize: h2,
      textAlign: 'left',
      fontWeight: 'bold',
    },
    documentImage: {
      justifyContent: 'center',
      maxWidth: '100%',
      width: '100%',
    },
    documentText: {
      fontSize: h4,
      flexGrow: 1,
    },
    documnetFooter: {
      fontSize: h6,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    iconFooter: {
      color: primaryColor,
      fontSize: '20',
      margin: '0 3px 0 0',
    },
  }),
);

export const NewsComponent = React.memo(() => {
  const docuClasses = useDocumentStyles();

  let history = useHistory();
  let dispatch = useDispatch();
  const goToNewsPage = () => {
    history.push(routes.news);
  };
  const news = useSelector((state: AppState) => state.newsReducer.newsItems);
  const fetchedAllNews = useSelector((state: AppState) => state.newsReducer.fetchedAll);
  useEffect(() => {
    if (!fetchedAllNews) {
      dispatch(GetAllNewsItems());
    }
  }, [dispatch, fetchedAllNews]);
  const newsToUse = news.length > 0 ? news[0] : undefined;
  return (
    <Grid container direction="row">
      <GridWithCustomBreakpoints item xs={12} sm={6} md={6} betweenlgandxl={6}>
        <DashboardFilesCard title={localized('News')} redirectButton={goToNewsPage}>
          <Box className={docuClasses.documentOrder} onClick={goToNewsPage}>
            {newsToUse && newsToUse.headerImageSrc && (
              <img src={newsToUse.headerImageSrc} className={docuClasses.documentImage} alt="GreenEnergy" />
            )}
            {newsToUse && newsToUse.summary && (
              <div className="Container" dangerouslySetInnerHTML={setInnerHtml(newsToUse)}></div>
            )}

            <DashboardFilesCardFooter
              category={newsToUse && newsToUse.category !== undefined ? newsToUse.category.toString() : ''}
              date={newsToUse && newsToUse.publishDate ? newsToUse.publishDate : undefined}
              author={newsToUse && newsToUse.authorName ? newsToUse.authorName : ''}
            />
          </Box>
        </DashboardFilesCard>
      </GridWithCustomBreakpoints>
      <GridWithCustomBreakpoints item xs={12} sm={6} md={6} lg={6} xl={6}>
        <DashboardStatusMessageCard />
      </GridWithCustomBreakpoints>
    </Grid>
  );
});

function setInnerHtml(newsToUse: INews) {
  return {__html: newsToUse.summary ? newsToUse.summary : ''};
}
