import {createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';
import React, {FC} from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      margin: '20px 0 0 0',
    },
  }),
);

interface Props{
  className:string;
}

export const BasicDocumentListHeader: FC<Props> = React.memo(({children, className}) => {
  const classes = useStyles();
  return (
    <Grid container direction="row" alignItems="center" className={clsx(classes.header, className)}>
      {children}
    </Grid>
  );
});
