import {Box, Grid} from '@material-ui/core';
import React, {FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import firmIcon from '../../../assets/icons/firm_icon.svg';
import {localized} from '../../../i18n/i18n';
import {AppState} from '../../../state/store';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {TitleAndSubCard} from '../../../view/components/default-components/title-and-sub-card';
import {TitleAndSubTypography} from '../basic-card-title-subtext';
import {userInfoUseStyles} from './user-info-helper';

export const UserOrganizationCard: FC = React.memo(() => {
  const classes = userInfoUseStyles();
  const orginasation = useSelector((state: AppState) => state.organizationReducer.organization);
  const [firmInformation, setfirmInformation] = useState<string[]>([]);
  const [addressInformation, setAddressInformation] = useState<string[]>([]);
  const [phoneInformation, setPhoneInformation] = useState<string[]>([]);

  useEffect(() => {
    if (orginasation) {
      if (orginasation.cvr || orginasation.homepage) {
        setfirmInformation([
          orginasation.cvr ? 'CVR: ' + orginasation.cvr : '',
          orginasation.homepage ? orginasation.homepage : '',
        ]);
      }
      if (orginasation.street || orginasation.houseNum || orginasation.city || orginasation.zipcode) {
        setAddressInformation([
          (orginasation.street ? orginasation.street + ' ' : '') + (orginasation.houseNum ? orginasation.houseNum : ''),
          (orginasation.zipcode ? orginasation.zipcode + ' ' : '') + (orginasation.city ? orginasation.city : ''),
        ]);
      }
      if (orginasation.phonenumber || orginasation.fax) {
        setPhoneInformation([
          orginasation.phonenumber ? localized('PhoneShort') + ': ' + orginasation.phonenumber : '',
          orginasation.fax ? localized('Fax') + ': ' + orginasation.fax : '',
        ]);
      }
    }
  }, [orginasation]);

  return (
    <BasicCard className={classes.cardClass}>
      <Box display={'flex'}>
        <Box className={classes.cardIcon}>
          <img src={firmIcon} width="32px" height="23px" alt="firm-icon" />
        </Box>
        <Box className={classes.cardText}>
          <TitleAndSubCard title={orginasation?.accountName} subTitle={orginasation?.accountNum} />
          <div className={classes.contentContainer}>
            <Grid container direction="row" wrap={'wrap'}>
              {addressInformation.length > 0 && (
                <TitleAndSubTypography
                  className={classes.minWidth250}
                  title={'Address'}
                  subTexts={addressInformation}
                />
              )}
              {firmInformation.length > 0 && (
                <TitleAndSubTypography
                  className={classes.minWidth200}
                  title={'FirmInformation'}
                  subTexts={firmInformation}
                />
              )}
            </Grid>
          </div>
          <div className={classes.marginTopTen}>
            <Grid container direction="row" wrap={'wrap'}>
              {orginasation?.email && (
                <TitleAndSubTypography className={classes.minWidth250} title={'Email'} subText={orginasation?.email} />
              )}
              {orginasation?.accountNum && (
                <TitleAndSubTypography
                  className={classes.minWidth200}
                  title={'CustomerNumber'}
                  subText={orginasation?.accountNum}
                />
              )}
            </Grid>
          </div>
          {phoneInformation?.length > 0 && (
            <TitleAndSubTypography className={classes.defaultContent} title={'Phone'} subTexts={phoneInformation} />
          )}
        </Box>
      </Box>
    </BasicCard>
  );
});
