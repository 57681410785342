import {Tooltip} from '@material-ui/core';
import MailOutline from '@material-ui/icons/MailOutline';
import React, {FC, useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {User} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {resendInvitationEmail} from '../../../state/features/user/operation';

interface Props {
  user: User;
}
export const ResendInvitationButton: FC<Props> = ({user}) => {
  const dispatch = useDispatch();
  const resendMailDisabled = user.inviteAccepted != null;

  const mailIconStyle: React.CSSProperties = useMemo(() => {
    return {
      opacity: resendMailDisabled ? 0.5 : 1,
      cursor: resendMailDisabled ? '' : 'pointer',
    };
  }, [resendMailDisabled]);

  const handleResendMail = useCallback(() => {
    if (resendMailDisabled) return;

    dispatch(resendInvitationEmail(user));
  }, [user, resendMailDisabled, dispatch]);

  return (
    <Tooltip title={localized('ResendInvitation')} disableHoverListener={resendMailDisabled}>
      <MailOutline style={mailIconStyle} onClick={handleResendMail} />
    </Tooltip>
  );
};
