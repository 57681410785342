import {createAsyncThunk} from '@reduxjs/toolkit';
import {OrganizationClient, User, UserClient} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {organizationClient, userClient} from '../../api-clients';
import {store} from '../../store';
import {showErrorSnackbar, showSuccessSnackbar} from '../snackbar/snackbar-slice';

export const getUser = createAsyncThunk('user/getUser', () => {
  return AsyncOperationHandler((client) => (client as UserClient).getByAuthId(), userClient);
});

export const SetUserActive = createAsyncThunk('user/setUserActive', (active: boolean) => {
  return active;
});

export const createUser = createAsyncThunk('user/createUser', async (data: {user: User; organizationId: number}) => {
  let response = await AsyncOperationHandler(
    (client) => (client as UserClient).createUserWithOrganizationId(data.user, data.organizationId),
    userClient,
  );
  const dispatch = store.dispatch;

  if (response) {
    dispatch(showSuccessSnackbar(localized('Success')));
    return response;
  } else {
    dispatch(showErrorSnackbar(localized('InvalidEmailFormat')));
    return null;
  }
});

export const deleteUserFromOrganization = createAsyncThunk(
  'user/deleteUserFromOrganization',
  (data: {userId: number | undefined; organizationId: number | undefined}) => {
    return AsyncOperationHandler(
      (client) => (client as OrganizationClient).deleteUserOrganization(data.userId, data.organizationId),
      organizationClient,
    );
  },
);

export const getUsersByOrganizationId = createAsyncThunk(
  'user/getUsersByOrganizationId',
  (organizationId: number | undefined) => {
    return AsyncOperationHandler(
      (client) => (client as UserClient).getUsersByOrganizationId(organizationId),
      userClient,
    );
  },
);

export const updateLastOpenedMarketingPage = createAsyncThunk(
  'user/updateLastOpenedMarketingPage',
  (organizationId: number) => {
    return AsyncOperationHandler(
      (client) => (client as UserClient).updateLastOpenedMarketingPage(organizationId),
      userClient,
    );
  },
);

export const resendInvitationEmail = createAsyncThunk('user/resendInvitationEmail', (user: User) => {
  return AsyncOperationHandler(
    (client) => (client as UserClient).resendInvitationEmail(user),
    userClient,
    true,
    () => {
      const dispatch = store.dispatch;
      dispatch(showSuccessSnackbar(localized('Success')));
    },
  );
});
