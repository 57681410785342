import {Box, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import {FC, default as React} from 'react';
import {useSelector} from 'react-redux';
import PowerConsumptionIcon from '../../../assets/icons/power_consumption_01.png';
import {localized} from '../../../i18n/i18n';
import {AppState} from '../../../state/store';
import {secondaryTextColor} from '../../../styles/color-constants';
import {h1} from '../../../styles/font-size-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';

export const ConsumptionTotalCard: FC = React.memo(() => {
  const classes = useStyles();
  const {totalConsumption} = useSelector((store: AppState) => store.energyConsumptionReducer);

  return (
    <BasicCard minHeight={'95%'} maxWidth={'calc(100vw - 64px)'}>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
        <img
          className={classes.summarizingCardIcons}
          src={PowerConsumptionIcon}
          alt="PowerConsumptionIcon"
          height={30}
        />
        <Typography>{localized('TotalElectricityConsumptionDuringPeriod')}</Typography>
      </Box>
      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}></Box>
      <Typography className={classes.highlightedText}>
        {totalConsumption.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }) + ' kWh'}
      </Typography>
    </BasicCard>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summarizingCardIcons: {
      margin: '10px 20px 10px 10px',
    },
    highlightedText: {
      textAlign: 'center',
      fontWeight: 'bold',
      padding: '20px',
      fontSize: h1,
      color: secondaryTextColor,
    },
  }),
);
