import {Box, Link, Typography} from '@material-ui/core';
import React from 'react';
import {localized} from '../../../i18n/i18n';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';

export const ContactFooter = React.memo(() => {
  const hrefMail = 'mailto:' + localized('KundeServiceMail');
  const hrefPhone = 'tel:' + localized('KundeServicePhone');
  return (
    <BasicCard>
      <Typography variant={'h6'}>{localized('NeedHelp')}</Typography>
      <Box display={'flex'}>
        <Typography variant={'body1'}>
          {localized('MarketingContact') + ' ' + localized('MarketingContactInfo') + ' '}
          <Link variant={'body1'} href={hrefMail} target="_blank" rel="noopener noreferrer">
            {localized('KundeServiceMail')}
          </Link>{' '}
          {localized('MarketingContactOrPhone')}
          <Link variant={'body1'} href={hrefPhone} target="_blank" rel="noopener noreferrer">
            {' ' + localized('KundeServicePhone')}
          </Link>{' '}
        </Typography>{' '}
      </Box>
    </BasicCard>
  );
});
