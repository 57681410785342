import {Box, Button, createStyles, makeStyles, Theme} from '@material-ui/core';
import React, {FC} from 'react';
import {localized} from '../../../i18n/i18n';
import {basicCardBgColor, btnTextColor, secondaryColor} from '../../../styles/color-constants';
import {h5} from '../../../styles/font-size-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {TitleAndSubCard} from '../../../view/components/default-components/title-and-sub-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstLayerCard: {
      minWidth: 280,
      height: 'fit-content',
    },
    scoundLayerCard: {
      backgroundColor: '#FFFFF',
      margin: 0,
    },
    headerAndButton: {
      alignItems: 'center',
      display: 'flex',
      flexWrap: 'wrap',
    },
    header: {
      flexGrow: 1,
    },
  }),
);
export const useButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: '80px',
      minHeight: '32px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: basicCardBgColor,
      margin: '10px 0px 10px 10px',
      color: btnTextColor,
      boxShadow: '3px 3px 10px #838D9B33',
      border: '1px solid #E0E0E0',
      fontSize: h5,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
      '&.Mui-disabled': {
        backgroundColor: secondaryColor,
        color: '#FFF',
      },
    },
  }),
);

interface PropsFromParent {
  title?: string;
  redirectButton?: any;
}
export const DashboardFilesCard: FC<PropsFromParent> = React.memo(({title, redirectButton: redirect, children}) => {
  const cardClasses = useStyles();
  const buttonClasses = useButtonStyles();

  return (
    <BasicCard className={cardClasses.firstLayerCard}>
      <Box className={cardClasses.headerAndButton}>
        <Box className={cardClasses.header}>
          <TitleAndSubCard title={title} />
        </Box>
        <Button className={buttonClasses.root} onClick={redirect}>
          {localized('SeeMore')}
        </Button>
      </Box>
      <BasicCard className={cardClasses.scoundLayerCard}>{children}</BasicCard>
    </BasicCard>
  );
});
