import {Box, BoxProps, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import React, {FC} from 'react';
import {localized} from '../../../i18n/i18n';
import {h5} from '../../../styles/font-size-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    resetButton: {
      fontSize: h5,
      msAlignSelf: 'flex-end',
      lineHeight: '26px',
      maxWidth: '648px',
      borderBottom: '1px solid black',
      cursor: 'pointer',
      alignSelf: 'center',
      '&:hover': {
        color: '#3333',
      },
    },
    box: {
      margin: '16px 8px',
    },
  }),
);

export const ResetFilterButton: FC<BoxProps> = React.memo(({...props}) => {
  const classes = useStyles();

  return (
    <Box onClick={props.onClick} display={'flex'} className={classes.box} >
      <Typography className={classes.resetButton}>{localized('Reset')}</Typography>
    </Box>
  );
});
