import {createAsyncThunk} from '@reduxjs/toolkit';
import {ContractClient, ContractDto, DocumentClient, IContractDto} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {contractClient, documentClient} from '../../api-clients';
import {store} from '../../store';
import {showSuccessSnackbar} from '../snackbar/snackbar-slice';

export const getContractsDetails = createAsyncThunk('contracts/getContracts', (organizationId: number) => {
  return AsyncOperationHandler(
    (client) => (client as ContractClient).getContractsDetails(organizationId),
    contractClient,
  );
});

export async function getContractPDF(fileId: number) {
  return AsyncOperationHandler((client) => (client as DocumentClient).getDocumentAzure(fileId), documentClient, true);
}
export async function renewContract(organizationId: number, contract: IContractDto) {
  return AsyncOperationHandler(
    (client) => (client as ContractClient).renewContact(organizationId, ContractDto.fromJS(contract)),
    contractClient,
    true,
    renewSuccessMessage,
  );
}

const renewSuccessMessage = () => {
  const dispatch = store.dispatch;
  dispatch(showSuccessSnackbar(localized('RenewContractSuccess')));
};
