import {createAsyncThunk} from '@reduxjs/toolkit';
import {GreenOptimizationClient} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {AsyncOperationHandler} from '../../../utilities/platform-helpers/api-helper';
import {greenOptimizationClient} from '../../api-clients';
import {store} from '../../store';
import {showSuccessSnackbar} from '../snackbar/snackbar-slice';

export const getGreenOptimizationContractsStatus = createAsyncThunk(
  'greenOptimization/getGreenOptimization',
  (organizationId: number) => {
    return AsyncOperationHandler(
      (client) => (client as GreenOptimizationClient).getGreenOptimizationContractsStatus(organizationId),
      greenOptimizationClient,
    );
  },
);

export async function chooseGreenOptimization(organizationId: number, greenOptimizationId: string) {
  return AsyncOperationHandler(
    (client) => (client as GreenOptimizationClient).chooseGreenOptimization(organizationId, greenOptimizationId),
    greenOptimizationClient,
    true,
    chooseGreenOptimizationSuccessMessage,
  );
}

const chooseGreenOptimizationSuccessMessage = () => {
  const dispatch = store.dispatch;
  dispatch(showSuccessSnackbar(localized('ChooseGreenOptimizationSuccess')));
};
