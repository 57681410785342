import {Box, createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import clsx from 'clsx';
import React from 'react';
import {useSelector} from 'react-redux';
import {AppState} from '../../../state/store';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    openingHoursCard: {
      textAlign: 'left',
      width: '100%',
      maxWidth: '360px',
      flexWrap: 'wrap'
    },
    daysText: {
      fontWeight: 'bold',
    },
    fridayText: {
      minWidth: 80,
      alignSelf: 'flex-start',
      marginRight: 20,
    },
    marginRight: {
      marginRight: 10
    }

  }),
);

export const OpeningHoursCard = React.memo(() => {
  const classes = useStyles();
  const {customerServiceInfo} = useSelector((store: AppState) => store.contactsReducer);

  return (
    <Grid container direction={'column'}>
      <BasicCard className={classes.openingHoursCard}>
        <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Box className={classes.marginRight}>
            <AccessTimeIcon />
          </Box>
          <Box >
            <Typography className={classes.daysText}>{'Mandag til torsdag'}</Typography>
            <Typography>{customerServiceInfo && customerServiceInfo.mondayToThursday!}</Typography>
          </Box>
          <Box className={clsx(classes.fridayText)}>
            <Typography className={classes.daysText}>{'Fredag'}</Typography>
            <Typography>{customerServiceInfo && customerServiceInfo.friday!}</Typography>
          </Box>
        </Box>
      </BasicCard>
    </Grid>
  );
});
