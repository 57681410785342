import {createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import React, {FC} from 'react';
import {h5} from '../../../styles/font-size-constants';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemBox: {
      [theme.breakpoints.down('md')]: {
        margin: '20px',
      },
      [theme.breakpoints.up('md')]: {
        margin: '20px 40px',
      },
      minWidth: 140,

      [theme.breakpoints.down('xs')]: {
        margin: '10px 20px 10px 20px',
      },
    },
    category: {
      fontSize: h5,
      fontWeight: 'bold',
    },
    value: {
      fontSize: h5,
    },
  }),
);

interface PropsFromParent {
  category?: string | undefined;
  valueText?: string | undefined;
  valueCeiling?: number | undefined;
  valuePrice?: number | undefined;
  emptySpace?: boolean;
}

export const ContractInfoCardItem: FC<PropsFromParent> = React.memo((props) => {
  const classes = useStyles();
  return (
    <Grid item className={classes.itemBox}>
      {!props.emptySpace && (
        <>
          {props.valueText || props.valueCeiling || props.valuePrice ? (
            <>
              <Typography className={classes.category}>{props.category}</Typography>
              <Typography className={classes.value}>{props.valueText}</Typography>
              {props.valueCeiling ? (
                <Typography className={classes.value}>{props.valueCeiling + ' DKK/MWh'}</Typography>
              ) : (
                <>
                  {props.valuePrice ? (
                    <Typography className={classes.value}>{props.valuePrice + ' DKK'}</Typography>
                  ) : null}
                </>
              )}
            </>
          ) : null}
        </>
      )}
    </Grid>
  );
});
