import {createSlice} from '@reduxjs/toolkit';
import {GetAllNewsItems, GetSingleBlog} from './operation';
import {NewsState} from './types';

const initialState: NewsState = {
  newsItems: [],
  pending: false,
  fetchedAll: false,
};

export const NewsSlice = createSlice({
  name: 'News',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetAllNewsItems.fulfilled, (state, action) => {
      state.pending = false;
      state.fetchedAll = true;
      state.newsItems = action.payload ? action.payload : [];
    });

    builder.addCase(GetAllNewsItems.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(GetAllNewsItems.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(GetSingleBlog.fulfilled, (state, action) => {
      state.pending = false;
      const newsItems = [...state.newsItems];
      if (action.payload) {
        newsItems.push(action.payload);
        state.newsItems = newsItems;
      }
    });

    builder.addCase(GetSingleBlog.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(GetSingleBlog.rejected, (state, action) => {
      state.pending = false;
    });
  },
});

// eslint-disable-next-line
export const {} = NewsSlice.actions;

export default NewsSlice.reducer;
