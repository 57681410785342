import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDailyConsumptionDto, IUnitDto } from '../../../api/api';
import {
  getFirstDayInPreviousMonth,
  getFirstDayInPreviousQuarter,
  getLastDayInPreviousMonth,
  getLastDayInPreviousQuarter,
  getMondayInPreviousWeek,
  getSundayInPreviousWeek,
  getYesterday,
} from '../../../utilities/helpers/date-helpers';
import { countNumberOfDaysBetweenDates } from '../../../utilities/platform-helpers/date-helpers';
import {
  countUniqueConsumptionDays,
  countUniqueConsumptionHours,
  getConnectionIdsFromIDailyConsumptionDto as getConnectionIdsFromConsumptionDto,
  setMonthlyConsumption,
} from './energy-consumption-helper';
import {
  getConsumption,
  getConsumptionByDate,
  getConsumptionByDateOneYearPrior,
  getConsumptionOneYearPrior,
  getUnits,
} from './operation';
import { EnergyConsumptionState, FixedPeriodCategory, SortingDays } from './types';

const initialState: EnergyConsumptionState = {
  consumption: [] as IDailyConsumptionDto[],
  consumptionOneYearPrior: [] as IDailyConsumptionDto[],
  units: [] as IUnitDto[],
  pending: false,
  isSuccess: false,
  totalConsumption: 0,
  sortingDays: {
    fromDate: getFirstDayInPreviousMonth(),
    toDate: getLastDayInPreviousMonth(),
  },
  numberOfDays: 0,
  selectedMeasuringPointIds: [] as string[],
  idsInConsumption: [] as string[],
  idsInConsumptionOneYearPrior: [] as string[],
  monthlyConsumption: 0,
  currentFixedPeriodCategory: FixedPeriodCategory.Month,
  priorFixedPeriodCategory: FixedPeriodCategory.Month,
  periodAverage: 0,
  yearPriorSelected: false,
};

const checkedUnits = (state: EnergyConsumptionState, newIdsInConsumption: string[], oldIdsInConsumption: string[], newIdsInConsumptionOneYearPrior: string[], oldIdsInConsumptionOneYearPrior: string[]) => {
  const newIds = new Set([...newIdsInConsumption, ...newIdsInConsumptionOneYearPrior]);
  const oldIds = new Set([...oldIdsInConsumption, ...oldIdsInConsumptionOneYearPrior]);
  const wasAllUnitsChecked = state.selectedMeasuringPointIds.length === oldIds.size;

  if (wasAllUnitsChecked) {
    // Add all units from the new dataset
    state.selectedMeasuringPointIds = Array.from(newIds);
  } else {
    // Remove those that aren't relevant in the new dataset
    state.selectedMeasuringPointIds = state.selectedMeasuringPointIds.filter(id => newIds.has(id));
  }
}

export const energyConsumptionSlice = createSlice({
  name: 'energyConsumption',
  initialState,
  reducers: {
    setTotalConsumption: (state, action: PayloadAction<number>) => {
      if (action.payload) {
        state.totalConsumption = action.payload;

        if (state.numberOfDays === 1) {
          state.periodAverage = action.payload / countUniqueConsumptionHours(state.consumption);
        } else {
          state.periodAverage = action.payload / countUniqueConsumptionDays(state.consumption);
        }
      }
      if (action.payload === 0) {
        state.totalConsumption = 0;
        state.periodAverage = 0;
      } else {
      }
    },

    setSortingDays: (state, action: PayloadAction<SortingDays>) => {
      if (action.payload) {
        state.sortingDays.fromDate = action.payload.fromDate;
        state.sortingDays.toDate = action.payload.toDate;
        state.numberOfDays = countNumberOfDaysBetweenDates(action.payload.fromDate, action.payload.toDate);

        if (state.numberOfDays === 1) {
          state.currentFixedPeriodCategory = FixedPeriodCategory.Day;
        } else if (state.numberOfDays === 7) {
          state.currentFixedPeriodCategory = FixedPeriodCategory.Week;

          // Possible month length
        } else if (state.numberOfDays >= 28 && state.numberOfDays <= 31) {
          state.currentFixedPeriodCategory = FixedPeriodCategory.Month;

          // Possible quarter length
        } else if (state.numberOfDays >= 89 && state.numberOfDays <= 92) {
          state.currentFixedPeriodCategory = FixedPeriodCategory.Quarter;
        } else {
          state.currentFixedPeriodCategory = FixedPeriodCategory.NotFixed;
        }
      }
    },

    setNumberOfDays: (state, action: PayloadAction<SortingDays>) => {
      if (action.payload) {
        state.numberOfDays = countNumberOfDaysBetweenDates(state.sortingDays.fromDate, state.sortingDays.toDate);
      }
    },

    setSelectedMeasuringPointIds: (state, action: PayloadAction<string[]>) => {
      if (action.payload) {
        state.selectedMeasuringPointIds = action.payload.filter(i => i);
      }
    },

    setPeriodCategory: (state, action: PayloadAction<FixedPeriodCategory>) => {
      if (action.payload !== undefined) {
        state.currentFixedPeriodCategory = action.payload;

        switch (action.payload) {
          case FixedPeriodCategory.Day:
            state.sortingDays.fromDate = getYesterday();
            state.sortingDays.toDate = getYesterday();
            break;

          case FixedPeriodCategory.Week:
            state.sortingDays.fromDate = getMondayInPreviousWeek();
            state.sortingDays.toDate = getSundayInPreviousWeek();
            break;

          case FixedPeriodCategory.Month:
            state.sortingDays.fromDate = getFirstDayInPreviousMonth();
            state.sortingDays.toDate = getLastDayInPreviousMonth();
            break;

          case FixedPeriodCategory.Quarter:
            state.sortingDays.fromDate = getFirstDayInPreviousQuarter();
            state.sortingDays.toDate = getLastDayInPreviousQuarter();
            break;

          default:
            state.sortingDays.fromDate = getFirstDayInPreviousMonth();
            state.sortingDays.toDate = getLastDayInPreviousMonth();
            break;
        }

        state.numberOfDays = countNumberOfDaysBetweenDates(state.sortingDays.fromDate, state.sortingDays.toDate);
      }
    },

    setYearPriorSelected: (state, action: PayloadAction<boolean>) => {
      state.yearPriorSelected = action.payload;

      if (action.payload === false) {
        // Clean up data from prior year
        state.idsInConsumptionOneYearPrior = [];
        state.consumptionOneYearPrior = [];
        checkedUnits(state, state.idsInConsumption, state.idsInConsumption, [], state.idsInConsumptionOneYearPrior);
      }
    },
    resetConsumptionOneYearPriorIds: (state) => {
      state.idsInConsumptionOneYearPrior = initialState.idsInConsumptionOneYearPrior
    }
  },
  extraReducers: (builder) => {
    // Get DailyConsumptionDtos - DateRange
    builder.addCase(getConsumption.fulfilled, (state, action) => {
      if (action.payload) {
        const newIdsInConsumption = getConnectionIdsFromConsumptionDto(action.payload);

        checkedUnits(state, newIdsInConsumption, state.idsInConsumption, state.idsInConsumptionOneYearPrior, state.idsInConsumptionOneYearPrior);

        state.pending = false;
        state.consumption = action.payload;
        state.monthlyConsumption = setMonthlyConsumption(action.payload);
        state.idsInConsumption = newIdsInConsumption;
      }
    });
    builder.addCase(getConsumption.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getConsumption.rejected, (state, action) => {
      state.pending = false;
    });

    // Get DailyConsumptionDtos - ByDate
    builder.addCase(getConsumptionByDate.fulfilled, (state, action) => {
      if (action.payload) {
        const newIdsInConsumption = getConnectionIdsFromConsumptionDto(action.payload);

        checkedUnits(state, newIdsInConsumption, state.idsInConsumption, state.idsInConsumptionOneYearPrior, state.idsInConsumptionOneYearPrior);

        state.pending = false;
        state.consumption = action.payload;
        state.idsInConsumption = newIdsInConsumption;
      }
    });
    builder.addCase(getConsumptionByDate.pending, (state, action) => {
      state.pending = true;
      if (state.currentFixedPeriodCategory !== state.priorFixedPeriodCategory) {
        state.consumption = initialState.consumption;
        state.consumptionOneYearPrior = initialState.consumptionOneYearPrior;
      }

      state.priorFixedPeriodCategory = state.currentFixedPeriodCategory;
    });

    builder.addCase(getConsumptionByDate.rejected, (state, action) => {
      state.pending = false;
    });

    // Get One Year Prior DailyConsumptionDtos - DateRange
    builder.addCase(getConsumptionOneYearPrior.fulfilled, (state, action) => {
      if (action.payload) {
        const newIdsInConsumptionOneYearPrior = getConnectionIdsFromConsumptionDto(action.payload);
        checkedUnits(state, state.idsInConsumption, state.idsInConsumption, newIdsInConsumptionOneYearPrior, state.idsInConsumptionOneYearPrior);

        state.pending = false;
        state.consumptionOneYearPrior = action.payload;
        state.idsInConsumptionOneYearPrior = newIdsInConsumptionOneYearPrior;
      }
    });
    builder.addCase(getConsumptionOneYearPrior.pending, (state, action) => {
      state.pending = true;
      if (state.currentFixedPeriodCategory !== state.priorFixedPeriodCategory) {
        state.consumption = initialState.consumption;
        state.consumptionOneYearPrior = initialState.consumptionOneYearPrior;
      }

      state.priorFixedPeriodCategory = state.currentFixedPeriodCategory;
    });

    builder.addCase(getConsumptionOneYearPrior.rejected, (state, action) => {
      state.pending = false;
    });

    // Get One Year Prior DailyConsumptionDtos - ByDate
    builder.addCase(getConsumptionByDateOneYearPrior.fulfilled, (state, action) => {
      if (action.payload) {
        const newIdsInConsumptionOneYearPrior = getConnectionIdsFromConsumptionDto(action.payload);

        checkedUnits(state, state.idsInConsumption, state.idsInConsumption, newIdsInConsumptionOneYearPrior, state.idsInConsumptionOneYearPrior);

        state.pending = false;
        state.consumptionOneYearPrior = action.payload;
        state.idsInConsumptionOneYearPrior = newIdsInConsumptionOneYearPrior;
      }
    });
    builder.addCase(getConsumptionByDateOneYearPrior.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getConsumptionByDateOneYearPrior.rejected, (state, action) => {
      state.pending = false;
    });

    // Get UnitDtos
    builder.addCase(getUnits.fulfilled, (state, action) => {
      if (action.payload) {
        state.units = action.payload;
        state.selectedMeasuringPointIds = state.units.map(unit => unit.connectionid!)
      }
    });
  },
});

export const {
  setTotalConsumption,
  setSortingDays,
  setPeriodCategory,
  setNumberOfDays,
  setSelectedMeasuringPointIds,
  setYearPriorSelected,
  resetConsumptionOneYearPriorIds: resetConsumptionOneYearPrior,
} = energyConsumptionSlice.actions;
export default energyConsumptionSlice.reducer;
