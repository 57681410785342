import {createStyles, makeStyles, Popper, Theme} from '@material-ui/core';
import {List} from 'react-virtualized';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Autocomplete from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import React, {FC, useEffect, useState} from 'react';
import { checkboxFilterBg, secondaryTextColor } from '../../../../styles/color-constants';
import {CssTextField} from '../../../components/default-components/text-fields/basic-text-field';

const ListboxComponent = React.forwardRef((props: any, ref: any) => {
  const {children, role, ...other} = props;
  const itemCount = Array.isArray(children) ? children.length : 0;
  const itemSize = 42;
  const renderRow = (renderRowProps: any) => {
    const style = {...renderRowProps.style, overflow: 'hidden'}
    return React.cloneElement(children[renderRowProps.index], {
      style: style,
    });
  };

  return (
    <div ref={ref}>
      <div {...other}>
        <List
          height={200}
          width={250}
          rowHeight={itemSize}
          overscanCount={5}
          rowCount={itemCount}
          rowRenderer={renderRow}
          role={role}
        />
      </div>
    </div>
  );
});

const  popper = {
     width: "fit-content"
  }
const PopperMy =  (props: any)  => {
  return <Popper {...props} style={popper} placement="bottom-start" />;
};

interface PropsFromParent {
  onChange: (item: DropdownOption) => void;
  items: DropdownOption[];
  label: string;
  value: DropdownOption | undefined;
  hideUnderline?: boolean;
  inputClassName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdownContainer: {
      display: 'contents',
      width: 'fit-content'
    },
  }),
);

export const EmptyDropdownOption: DropdownOption = {label: '', value: ''};

export class DropdownOption {
  label: string;
  value: any;
  constructor(label: string, value: any) {
    this.label = label;
    this.value = value;
  }
}

const listBoxProps = {
  style: {
    background: checkboxFilterBg,
    border: '1px solid ' + secondaryTextColor,
    width: 250,
    height: 220,
    overflow: 'hidden',
  },
};
export const BasicDropdown: FC<PropsFromParent> = React.memo(
  ({onChange, items, label, value, hideUnderline, inputClassName}) => {
    const classes = useStyles();
    const renderInput = (params: any) => (
      <CssTextField
        key={value}
        disabled={true}
        {...params}
        label={label}
        margin="normal"
        InputProps={{...params.InputProps, disableUnderline: hideUnderline}}
        className={inputClassName}
      />
    );

    const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);

    // Add an item option in case of
    useEffect(() => {
      let options = items;

      if (!options || options.length < 1) {
        options.unshift(EmptyDropdownOption);
      }

      setDropdownOptions(options);
    }, [items]);

    const onChangeLocal = (event: any, newValue: any) => {
      onChange(newValue);
    };

    const getOptionLabel = (option: DropdownOption) => {
      return option.label;
    };

    const getOptionSelected = (option: DropdownOption, valueOption: DropdownOption) =>
      option.value === valueOption.value;

    return (
      <Autocomplete
      PopperComponent={PopperMy}
        value={value ? value : EmptyDropdownOption}
        className={clsx(classes.dropdownContainer)}
        onChange={onChangeLocal}
        options={dropdownOptions}
        id="disable-clearable"
        disableClearable={true}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        renderInput={renderInput}
        ListboxComponent={ListboxComponent as any}
        ListboxProps={listBoxProps}
        popupIcon={<KeyboardArrowDown />}
      />
    );
  },
);
