import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import React, {FC, useContext, useEffect, useState} from 'react';
import {headerBackgroundColor, primaryTextColor} from '../../../../styles/color-constants';
import {h2} from '../../../../styles/font-size-constants';
import {FlexColumn} from '../../../components/default-components/flex-column';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: headerBackgroundColor,
      marginTop: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        marginLeft: '8px ',
      },
    },
    title: {
      fontSize: h2,
      color: primaryTextColor,
      fontWeight: 'bold',
      marginRight: theme.spacing(3),

      [theme.breakpoints.up('md')]: {
        marginLeft: theme.spacing(2),
      },
    },
  }),
);

interface PropsFromParent {
  title: string;
  area: string;
}

export const PageHeaderContext = React.createContext({} as any);
export const UsernameHeaderContext = React.createContext({} as any);
export const usePageHeader = () => useContext(PageHeaderContext);
export const useUsernameHeader = () => useContext(UsernameHeaderContext);

export const PageHeader: FC<PropsFromParent> = React.memo(({title, area, ...props}) => {
  const classes = useStyles();
  const [areaId, setAreaId] = useState<string>();
  useEffect(() => {
    setAreaId(area);
  }, [area]);

  return (
    <PageHeaderContext.Provider value={{areaId}}>
      <FlexColumn className={classes.root}>
        <Box className={classes.title}>{title}</Box>
        <Box>{props.children}</Box>
      </FlexColumn>
    </PageHeaderContext.Provider>
  );
});
