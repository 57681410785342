import {Box, createStyles, Divider, makeStyles, Theme, Typography} from '@material-ui/core';
import clsx from 'clsx';
import React, {FC, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IUnitDto} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {energyConsumptionSlice} from '../../../state/features/energy-consumption/energy-consumption-slice';
import {SortingDays} from '../../../state/features/energy-consumption/types';
import {AppState} from '../../../state/store';
import {basicCardBgColor, primaryColor} from '../../../styles/color-constants';
import {h6} from '../../../styles/font-size-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {TitleAndSubCard} from '../../../view/components/default-components/title-and-sub-card';
import {ConsumptionUnits} from './consumption-measuring-units';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sortFlexbox: {
      cursor: 'pointer',
      margin: 6,
      padding: 0,
      display: 'flex',
      textAlign: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
    },
    selected: {
      cursor: 'pointer',
      backgroundColor: primaryColor,
      color: basicCardBgColor,
      '&:hover': {
        backgroundColor: primaryColor,
        color: basicCardBgColor,
      },
    },
    measuringPointButtons: {
      fontSize: h6,
    },
    checkAll: {
      padding: 10,
      minWidth: 85,
    },
    divider: {
      margin: '10px',
    },
    chooseAllBox: {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      margin: '10px 0px',
    },
  }),
);

export const getConnectionIds2 = (units: IUnitDto[], selectedIds: string[]) => {
  let ids: string[] = [];
  units
    .filter(
      (unit, i, unitList) =>
        unitList.findIndex((t) => t.connectionid! === unit.connectionid!) === i &&
        selectedIds.includes(unit.connectionid!),
    )
    .forEach((element) => ids.push(element.connectionid!));
  return ids;
};
export const getConnectionIds = (units: IUnitDto[], sortingDays?: SortingDays) => {
  let ids: string[] = [];
  units
    .filter((thing, i, arr) => arr.findIndex((t) => t.connectionid! === thing.connectionid!) === i)
    .forEach((element) => ids.push(element.connectionid!));
  return ids;
};

interface PropsFromParent {}

export const ConsumptionUnitsCard: FC<PropsFromParent> = React.memo(({children, ...props}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [numberOfUnits, setNumberOfUnits] = useState<number>(0);
  const {selectedMeasuringPointIds, units} = useSelector((store: AppState) => store.energyConsumptionReducer);

  const handleCheckAllPressed = () => {
    dispatch(energyConsumptionSlice.actions.setSelectedMeasuringPointIds(getConnectionIds(units)));
  };

  const handleUncheckAllPressed = () => {
    let empty: string[] = [];
    dispatch(energyConsumptionSlice.actions.setSelectedMeasuringPointIds(empty));
  };

  return (
    <BasicCard minHeight={'95%'} maxHeight={250} maxWidth={'calc(100vw - 64px)'}>
      <TitleAndSubCard title={localized('MeasuringPoints')} />
      <Box className={classes.chooseAllBox}>
        <BasicCard
          className={clsx(
            classes.sortFlexbox,
            selectedMeasuringPointIds.length === numberOfUnits &&
              selectedMeasuringPointIds.length !== 0 &&
              classes.selected,
            classes.checkAll,
          )}
          onClick={handleCheckAllPressed}>
          <Typography className={classes.measuringPointButtons}>{localized('SelectAll')}</Typography>
        </BasicCard>
        <BasicCard
          className={clsx(
            classes.sortFlexbox,
            selectedMeasuringPointIds.length === 0 && classes.selected,
            classes.checkAll,
          )}
          onClick={handleUncheckAllPressed}>
          <Typography className={classes.measuringPointButtons}>{localized('DeselectAll')}</Typography>
        </BasicCard>
      </Box>
      <Divider variant="middle" className={classes.divider} />
      <ConsumptionUnits numberOfUnitsCallback={setNumberOfUnits} />
    </BasicCard>
  );
});
