import {Badge, Box, createStyles, makeStyles, SvgIcon, Theme, Typography} from '@material-ui/core';
import React, {FC, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {localized} from '../../../i18n/i18n';
import {chooseGreenOptimization} from '../../../state/features/green-optimization/operation';
import {showErrorSnackbar} from '../../../state/features/snackbar/snackbar-slice';
import {basicCardTextColor, disabledColor, primaryTextColor, secondaryColor} from '../../../styles/color-constants';
import {h3, h5} from '../../../styles/font-size-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {DownloadFileButton} from './download-file-button';
import {BigMaterialCardPropsFromParent} from './Interfaces';
import {SquareBanner} from './square-banner';
import {WideBanner} from './wide-banner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    GridAroundBigMaterial: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexGrow: 1,
    },
    DownloadBoxStyle: {
      gridRow: 2,
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      justifyItems: 'stretch',
    },
    contactMe: {
      position: 'absolute',
      top: '35%',
      zIndex: 1,
      cursor: 'pointer',
      marginLeft: 0,
      marginRight: 5,
      minWidth: 50,
      maxWidth: 120,
      maxHeight: 40,
      boxShadow: ' 0px 0px  20px grey',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      transition: 'flex-grow 300ms ease-in-out',
      color: primaryTextColor,
      '&:hover': {
        backgroundColor: '#ccc',
        color: '#FFF',
        flex: 1,
      },
    },
    SvgIcon: {
      overflow: 'visible',
      margin: 1,
      marginRight: 10,
      padding: 2,
      minHeight: 27,
      minWidth: 27,
      float: 'left',
    },
    headerTypo: {
      fontSize: h3,
      fontFamily: 'Lato',
      fontWeight: 'bold',
    },
    bodyTypo: {
      fontSize: h5,
      fontFamily: 'Lato',
      fontWeight: 'normal',
    },
    image: {
      padding: '20%',
      flex: 1,
    },
    card: {
      boxShadow: (active: boolean) => (active ? 'inset 0px 0px 0px 2px ' + secondaryColor : ''),
    },
    Badge: {display: 'flex', transform: 'translate(20px, -20px)'},
  }),
);

export const BigMaterialCard: FC<BigMaterialCardPropsFromParent> = React.memo(
  ({
    access,
    description,
    squareDownLoadLinks,
    squareImgSrc,
    title,
    wideDownLoadLinks,
    wideImgSrc,
    isNewToUser,
    organizationId,
    activeCategory,
    bannerIcon,
    ...props
  }) => {
    const classes = useStyles(access);
    const [localWideImgSrc, setWideImgSrc] = React.useState(wideImgSrc);
    const [localSquareImgSrc, setSquareImgSrc] = React.useState(squareImgSrc);
    const dispatch = useDispatch();

    //reset images when components links is updated
    useEffect(() => {
      setWideImgSrc(wideImgSrc);
      setSquareImgSrc(squareImgSrc);
    }, [wideImgSrc, squareImgSrc, wideDownLoadLinks, squareDownLoadLinks]);

    const SquareDownloadButtons = squareDownLoadLinks.map((link, index) => (
      <DownloadFileButton
        disabled={!access || link?.disabled}
        id={link?.id}
        name={link?.title}
        type={link?.type}
        key={index}
        organizationId={organizationId}
        setParentImgSrc={setSquareImgSrc}
      />
    ));
    const WideDownloadButtons = wideDownLoadLinks.map((link, index) => (
      <DownloadFileButton
        disabled={!access || link?.disabled}
        id={link?.id}
        name={link?.title}
        type={link?.type}
        key={index}
        organizationId={organizationId}
        setParentImgSrc={setWideImgSrc}
      />
    ));

    const BodyTextColor = access ? basicCardTextColor : disabledColor;
    const IconHeaderColor = access ? primaryTextColor : disabledColor;

    const [linkDisabled, setLinkDisabled] = React.useState(false);
    const handleOnClick = async () => {
      if (linkDisabled) return;
      if (organizationId) {
        setLinkDisabled(true);
        try {
          await chooseGreenOptimization(organizationId, activeCategory?.toString() ?? '');
        } catch (error) {
          dispatch(showErrorSnackbar(localized('MailNotSuccessfullSent')));
        }
        setLinkDisabled(false);
      }
    };
    return (
      <BasicCard
        className={classes.card}
        color={BodyTextColor}
        display={'flex'}
        flexDirection={'column'}
        flex={1}
        justifyContent={'center'}>
        <Badge
          badgeContent={isNewToUser ? 'New' : ''}
          color="secondary"
          overlap="rectangle"
          invisible={!isNewToUser}
          className={classes.Badge}
        />
        <Box className={classes.GridAroundBigMaterial}>
          <Box display="flex" flexWrap="nowrap">
            <SvgIcon
              style={{
                color: IconHeaderColor,
              }}
              className={classes.SvgIcon}
              component={bannerIcon}
            />
            <Box>
              <Typography style={{color: IconHeaderColor}} className={classes.headerTypo}>
                {title}
              </Typography>
              <Typography className={classes.bodyTypo}>{description}</Typography>
            </Box>
          </Box>
          <Box>
            <WideBanner ImgSrc={access ? localWideImgSrc : 'grey'} />

            <Box className={classes.DownloadBoxStyle}>{WideDownloadButtons}</Box>

            <SquareBanner ImgSrc={access ? localSquareImgSrc : 'grey'} />

            <Box className={classes.DownloadBoxStyle}>{SquareDownloadButtons}</Box>
          </Box>
          {!linkDisabled && !access && (
            <BasicCard onClick={handleOnClick} className={classes.contactMe}>
              <Typography className={classes.bodyTypo}>{localized('ContactMe')} </Typography>
            </BasicCard>
          )}
        </Box>
      </BasicCard>
    );
  },
);
