import {Box, Button, createStyles, makeStyles, Theme} from '@material-ui/core';
import LabelIcon from '@material-ui/icons/Label';
import PersonIcon from '@material-ui/icons/Person';
import React, {FC} from 'react';
import {localized} from '../../../i18n/i18n';
import {primaryColor, secondaryColor} from '../../../styles/color-constants';
import {h5} from '../../../styles/font-size-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    documnetFooter: {
      fontSize: h5,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      width: (props: PropsFromParent) => props.minWidth,
      flexWrap: 'wrap',
    },
    documnetFooterContentContainer: {
      fontSize: h5,
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: 'fit-content',
      maxWidth: '500px',
    },
    flexItems: {
      margin: '0 5px 0 0',
      display: 'flex',
    },
    icons: {
      alignSelf: 'center',
      color: primaryColor,
      fontSize: '20',
      margin: '0 3px 0 0',
    },
    root: {
      minWidth: '80px',
      minHeight: '32px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: secondaryColor,
      margin: '10px 0px 10px 10px',
      color: '#fff',
      boxShadow: '3px 3px 10px #838D9B33',
      border: '1px solid #E0E0E0',
      fontSize: h5,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
      '&.Mui-disabled': {
        backgroundColor: secondaryColor,
        color: '#FFF',
      },
    },
  }),
);
interface PropsFromParent {
  category: string;
  date?: Date;
  author: string;
  minWidth?: number;
  readMore?: () => void;
}
export const DashboardFilesCardFooter: FC<PropsFromParent> = React.memo(
  ({category, date, author, minWidth, readMore}) => {
    const classes = useStyles({minWidth} as PropsFromParent);

    return (
      <Box className={classes.documnetFooter}>
        <Box className={classes.documnetFooterContentContainer}>
          {category.length > 0 && (
            <Box className={classes.flexItems}>
              <LabelIcon className={classes.icons} fontSize="inherit" />
              {category}
            </Box>
          )}
          {date && <Box className={classes.flexItems}>{date.toLocaleDateString()}</Box>}
          {category.length > 0 && (
            <Box className={classes.flexItems}>
              <PersonIcon className={classes.icons} fontSize="inherit" />
              {author}
            </Box>
          )}
        </Box>
        {readMore && (
          <Button className={classes.root} onClick={readMore}>
            {localized('ReadMore')}
          </Button>
        )}
      </Box>
    );
  },
);
