import {Box, createStyles, Grid, Hidden, makeStyles, Theme, Typography} from '@material-ui/core';
import {Breakpoint} from '@material-ui/core/styles/createBreakpoints';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Dispatch} from 'redux';
import {useLayoutStyles} from '../../../app-routes';
import {localized} from '../../../i18n/i18n';
import {getContractsDetails} from '../../../state/features/contract/operation';
import {getDocumentDetails} from '../../../state/features/document/operation';
import {energyConsumptionSlice} from '../../../state/features/energy-consumption/energy-consumption-slice';
import {
  getConsumption,
  getConsumptionByDate,
  getConsumptionByDateOneYearPrior,
  GetConsumptionExcelExport,
  getConsumptionOneYearPrior,
  getUnits,
} from '../../../state/features/energy-consumption/operation';
import {FixedPeriodCategory, SortingDays} from '../../../state/features/energy-consumption/types';
import {getGreenOptimizationContractsStatus} from '../../../state/features/green-optimization/operation';
import {routes} from '../../../state/routes';
import {AppState} from '../../../state/store';
import {h5, h6} from '../../../styles/font-size-constants';
import {getOneYearPrior} from '../../../utilities/helpers/date-helpers';
import {countNumberOfDaysBetweenDates} from '../../../utilities/platform-helpers/date-helpers';
import {usePrevious} from '../../../utilities/use-previous-hook';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {DateRangePicker} from '../../../view/components/default-components/date-range-picker/date-range-picker';
import {PageContentContainer} from '../../../view/components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../view/components/default-components/page-header.tsx/page-header';
import {PageTitle} from '../../../view/components/default-components/page-title/page-title';
import {ResetFilterButton} from '../../components/economy/reset-filter-button';
import {ConsumptionBackAndForthButtons} from '../../components/energi-consumption/consumption-back-and-forth-buttons';
import {ConsumptionDocumentsReport} from '../../components/energi-consumption/consumption-documents-report';
import {ConsumptionFixedDaysButton} from '../../components/energi-consumption/consumption-fixed-days-button';
import {ConsumptionGraph} from '../../components/energi-consumption/consumption-graph';
import {ConsumptionUnitsCard} from '../../components/energi-consumption/consumption-measuring-units-card';
import {ConsumptionPriorYearButton} from '../../components/energi-consumption/consumption-prior-year-button';
import {ConsumptionTotalCard} from '../../components/energi-consumption/consumption-total-card';
import {primaryColor} from '../../../styles/color-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subText: {
      fontSize: h5,
      margin: '0 0 0 16px',
    },
    functionalityGrid: {
      alignItems: 'flex-end',
    },
    datepickerGridItem: {
      flexGrow: 0,
    },
    datepickerBox: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
      },
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
      },
      alignItems: 'start',
    },
    downloadExcel: {
      cursor: 'pointer',
      maxHeight: 40,
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
    },
    downloadIcon: {
      marginLeft: 5,
      height: 18,
    },
    downloadTypo: {
      fontSize: h6,
    },
    mobileResetBox: {
      marginLeft: 10,
      alignSelf: 'center',
    },
    linkStyle: {
      color: primaryColor
    }
  }),
);

const firstHidden = ['xs', 'sm', 'm', 'md', 'lg'] as Breakpoint[];
const secondHidden = ['m', 'betweenlgandxl'] as Breakpoint[];
const thirdHidden = ['xs', 'lg', 'betweenlgandxl', 'xl'] as Breakpoint[];
const fourthHidden = ['xs', 'sm', 'm', 'md', 'betweenlgandxl', 'xl'] as Breakpoint[];
const fifthHidden = ['sm', 'md', 'lg', 'betweenlgandxl', 'xl'] as Breakpoint[];
const sixthHidden = ['xs'] as Breakpoint[];

function fetchOneYearPrior(sortingDays: SortingDays, dispatch: Dispatch<any>, organizationId: number) {
  if (countNumberOfDaysBetweenDates(sortingDays.fromDate, sortingDays.toDate) === 1) {
    dispatch(
      getConsumptionByDateOneYearPrior({
        date: getOneYearPrior(sortingDays.fromDate),
        organizationId: organizationId,
      }),
    );
  } else {
    dispatch(
      getConsumptionOneYearPrior({
        fromDate: getOneYearPrior(sortingDays.fromDate),
        toDate: getOneYearPrior(sortingDays.toDate),
        organizationId: organizationId,
      }),
    );
  }
}

export const EnergyConsumption = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {sortingDays, consumption, selectedMeasuringPointIds, yearPriorSelected, idsInConsumption} = useSelector(
    (store: AppState) => store.energyConsumptionReducer,
  );
  const prevYearPriorSelected = usePrevious(yearPriorSelected);
  const layoutClasses = useLayoutStyles();
  const elOverviewLink = (
    <a href={'https://eloverblik.dk'} target={'_blank'} rel={'noopener noreferrer'} style={{color: primaryColor}}>
      eloverblik.dk
    </a>
  );
  const elDeclarationLink = (
    <a
      href={'https://www.scanenergi.dk/om-os/godt-at-vide-som-kunde/eldeklaration'}
      target={'_blank'}
      rel={'noopener noreferrer'}
      style={{color: primaryColor}}>
      her
    </a>
  );
  const kontakteOs = (
    <a href={routes.contact} style={{color: primaryColor}}>
      {localized('ContactUs')}
    </a>
  );

  const {organizationId} = useSelector((store: AppState) => store.organizationReducer);
  const {units} = useSelector((store: AppState) => store.energyConsumptionReducer);

  // Fetch energy data when date changes
  useEffect(() => {
    if (organizationId) {
      if (sortingDays.toDate && sortingDays.fromDate && yearPriorSelected === prevYearPriorSelected) {
        if (countNumberOfDaysBetweenDates(sortingDays.fromDate, sortingDays.toDate) === 1) {
          dispatch(getConsumptionByDate({date: sortingDays.fromDate, organizationId: organizationId}));
        } else {
          dispatch(
            getConsumption({
              fromDate: sortingDays.fromDate,
              toDate: sortingDays.toDate,
              organizationId: organizationId,
            }),
          );
        }
        if (yearPriorSelected === true) {
          fetchOneYearPrior(sortingDays, dispatch, organizationId);
        }
      }
    }
  }, [dispatch, organizationId, sortingDays, yearPriorSelected, prevYearPriorSelected]);

  // Fetch energy data when yearPriorSelected changes
  useEffect(() => {
    if (organizationId && sortingDays.toDate && sortingDays.fromDate) {
      if (yearPriorSelected === true && yearPriorSelected !== prevYearPriorSelected) {
        fetchOneYearPrior(sortingDays, dispatch, organizationId);
      }
    }
    // eslint-disable-next-line
  }, [yearPriorSelected]);

  // Fetch contract data and units
  useEffect(() => {
    if (organizationId) {
      dispatch(getGreenOptimizationContractsStatus(organizationId));
      dispatch(getDocumentDetails(organizationId));
      dispatch(getContractsDetails(organizationId));
      dispatch(getUnits(organizationId));
    }
  }, [organizationId, dispatch]);

  const handleFromDateChanged = useCallback(
    (event: Date) => {
      let newLocalSortingDays: SortingDays = {
        ...sortingDays,
        fromDate: event,
      };

      dispatch(energyConsumptionSlice.actions.setSortingDays(newLocalSortingDays));
    },
    [sortingDays, dispatch],
  );

  const handleToDateChanged = useCallback(
    (event: Date) => {
      let newLocalSortingDays: SortingDays = {
        ...sortingDays,
        toDate: event,
      };

      dispatch(energyConsumptionSlice.actions.setSortingDays(newLocalSortingDays));
    },
    [sortingDays, dispatch],
  );

  const downloadExcel = useCallback(() => {
    if (consumption && consumption.length > 0) {
      let chosenDataPoints = consumption.filter((item) => {
        return selectedMeasuringPointIds.includes(item.connectionId!) || item.connectionId === null;
      });
      let chosenUnits = units.filter((item) => {
        return selectedMeasuringPointIds.includes(item.connectionid!) || item.connectionid === null;
      });

      if (countNumberOfDaysBetweenDates(sortingDays.fromDate, sortingDays.toDate) === 1) {
        GetConsumptionExcelExport(chosenDataPoints, chosenUnits, true);
      } else {
        GetConsumptionExcelExport(
          chosenDataPoints.filter((dp) => dp.date && dp.date >= sortingDays.fromDate && dp.date <= sortingDays.toDate),
          chosenUnits,
          false,
        );
      }
    }
  }, [consumption, sortingDays, units, selectedMeasuringPointIds]);

  const handleResetButtonPressed = useCallback(() => {
    let newLocalSortingDays: SortingDays = {
      fromDate: moment().startOf('month').toDate(),
      toDate: moment().endOf('month').toDate(),
    };

    dispatch(energyConsumptionSlice.actions.setSortingDays(newLocalSortingDays));
    dispatch(energyConsumptionSlice.actions.setPeriodCategory(FixedPeriodCategory.Month));
    dispatch(energyConsumptionSlice.actions.setYearPriorSelected(false));
    // Todo replace
    dispatch(energyConsumptionSlice.actions.setSelectedMeasuringPointIds(idsInConsumption));
  }, [dispatch, idsInConsumption]);

  return (
    <PageTitle title="EnergyConsumption">
      <PageHeader area="template" title={localized('EnergyConsumption')}></PageHeader>
      <PageContentContainer>
        <Grid className={layoutClasses.desktopOnly} container>
          <Grid item xs={12}>
            <Typography className={classes.subText}>
              {localized('BelowYouWillFindADynamicConsumptionOverview')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.subText}>
              {localized('IfYouNeedDataRegardingElectricHeatingOrProduction')}
              {kontakteOs}
              {' ' + localized('OrLookAt') + ' '}
              {elOverviewLink}
              {'.'}
            </Typography>
            <Grid item xs={12}>
              <Typography className={classes.subText}>
                {localized('SeePowerDecleration') + ' '}
                {elDeclarationLink}
                {'.'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row">
          <Hidden only={firstHidden}>
            <Grid item lg={2}>
              <ConsumptionUnitsCard />
            </Grid>
          </Hidden>
          <Grid item md={12} lg>
            <Grid item lg={12} betweenlgandxl={9}>
              <Grid container className={classes.functionalityGrid}>
                <Grid item xs={12} sm className={classes.datepickerGridItem}>
                  <DateRangePicker
                    dateFrom={sortingDays.fromDate}
                    dateFromChanged={handleFromDateChanged}
                    dateTo={sortingDays.toDate}
                    dateToChanged={handleToDateChanged}
                  />
                </Grid>
                <Grid item xs={12} sm>
                  <Box className={classes.datepickerBox}>
                    <ConsumptionBackAndForthButtons />
                    <ConsumptionFixedDaysButton periodCategory={FixedPeriodCategory.Day} btnText={localized('Day')} />
                    <ConsumptionFixedDaysButton periodCategory={FixedPeriodCategory.Week} btnText={localized('Week')} />
                    <ConsumptionFixedDaysButton
                      periodCategory={FixedPeriodCategory.Month}
                      btnText={localized('Month')}
                    />
                    <ConsumptionFixedDaysButton
                      periodCategory={FixedPeriodCategory.Quarter}
                      btnText={localized('Quarter')}
                    />
                    <ConsumptionPriorYearButton btnText={localized('CompareWithLastYear')} />
                    <Hidden only={sixthHidden}>
                      <ResetFilterButton onClick={handleResetButtonPressed} />
                    </Hidden>
                  </Box>
                </Grid>
                <Grid item>
                  <Box display={'flex'} flexDirection={'row'}>
                    <BasicCard onClick={downloadExcel} className={classes.downloadExcel}>
                      <Typography className={classes.downloadTypo}>{localized('DownloadExcel')}</Typography>
                      <GetAppIcon className={classes.downloadIcon} />
                    </BasicCard>
                    <Hidden only={fifthHidden}>
                      <Box className={classes.mobileResetBox}>
                        <ResetFilterButton onClick={handleResetButtonPressed} />
                      </Box>
                    </Hidden>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container direction="row">
              <Hidden smUp only={secondHidden}>
                <Grid item xs={12}>
                  <ConsumptionUnitsCard />
                </Grid>
              </Hidden>
              <Grid item xs={12} lg={9}>
                <ConsumptionGraph />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Grid container>
                  <Hidden only={thirdHidden}>
                    <Grid item sm={6}>
                      <ConsumptionUnitsCard />
                    </Grid>
                  </Hidden>
                  <Grid item xs={12} sm={6} md>
                    <ConsumptionTotalCard />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Grid container>
              <Hidden only={fourthHidden}>
                <Grid item xs={12} sm={12} lg={6}>
                  <ConsumptionUnitsCard />
                </Grid>
              </Hidden>
              <Grid item xs={12} lg={6} xl={5}>
                <ConsumptionDocumentsReport />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageContentContainer>
    </PageTitle>
  );
});
