import {CircularProgress, createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import React, {FC} from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circularProgress: {
      height: '100%'
    }
  }),
);

export const BasicCircularProgress: FC = React.memo(() => {
  const classes = useStyles();
  return (
    <>
      <Grid container direction="column" justify="center" alignItems="center" className={classes.circularProgress}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    </>
  );
});
