import {MarketingMaterialCategories} from '../../../api/api';
import {localized, localizedInterpolation} from '../../../i18n/i18n';
import {
  BigMaterialCardPropsFromParent,
  DownloadLink,
  SmallMaterialCardPropsFromParent,
} from '../../../view-scan-energi/components/marketing-material/Interfaces';

export interface MarketingMaterialCategoryPageDatas {
  Category: MarketingMaterialCategories;
  MarketingMaterialPageData: MarketingMaterialPageData;
}
export const DefaultDownLoadLinks: DownloadLink[] = [
  {
    title: undefined,
    type: 'JPG',
    id: 0,
    disabled: true,
  },
  {
    title: undefined,
    type: 'PNG',
    id: 0,
    disabled: true,
  },
  {
    title: undefined,
    type: 'PDF',
    id: 0,
    disabled: true,
  },
];

export interface MarketingMaterialPageData {
  banners: BigMaterialCardPropsFromParent;
  diploma: SmallMaterialCardPropsFromParent;
  soMeTexts: SmallMaterialCardPropsFromParent;
  cSRTexts: SmallMaterialCardPropsFromParent;
}

export function DefaultMarketingMaterialPageData(
  marketingMaterialCategory: MarketingMaterialCategories,
): MarketingMaterialPageData {
  return {
    banners: {
      access: false,
      title: localized('BannerTitle'),
      description: localizedInterpolation('BannerDescription', {
        product: GetCategoryName(marketingMaterialCategory),
      }),
      wideImgSrc: 'grey',
      squareImgSrc: 'grey',
      wideDownLoadLinks: DefaultDownLoadLinks,
      squareDownLoadLinks: DefaultDownLoadLinks,
      isNewToUser: false,
      organizationId: 0,
    },
    diploma: {
      access: false,
      title: localized('DiplomaTitle'),
      description: localizedInterpolation('DiplomaDescription', {
        product: GetCategoryName(marketingMaterialCategory),
      }),
      downLoadLinks: [
        {
          type: 'PDF',
          id: 0,
          title: localized('DiplomaTitle'),
        },
      ],
      isNewToUser: false,
      organizationId: 0,
    },
    soMeTexts: {
      access: false,
      title: localized('SoMeTitle'),
      description: localized('SoMeDescription'),
      downLoadLinks: [
        {
          type: 'Word',
          id: 0,
          title: localized('SoMeTitle'),
        },
      ],
      isNewToUser: false,
      organizationId: 0,
    },
    cSRTexts: {
      access: false,
      title: localized('CSRTextsTitle'),
      description: localized('CSRTextsDescription'),
      downLoadLinks: [
        {
          type: 'Word',
          id: 0,
          title: localized('CSRTextsTitle'),
        },
      ],
      isNewToUser: false,
      organizationId: 0,
    }
  };
}

export const GetCategoryName = (marketingMaterialCategory: MarketingMaterialCategories) => {
  switch (marketingMaterialCategory) {
    case MarketingMaterialCategories.Co2Mapping:
      return localized('CO2mapping');
    case MarketingMaterialCategories.ClimateAction:
      return localized('ClimateAction');
    case MarketingMaterialCategories.EnergyConsulting:
      return localized('EnergyConsulting');
    case MarketingMaterialCategories.WindPower:
      return localized('WindPower');
    case MarketingMaterialCategories.ClimateForest:
      return localized('CLIMATEForest');
    default:
      return '';
  }
};
