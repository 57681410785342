import moment from 'moment';
import {IDailyConsumptionDto} from '../../../api/api';
import {getOneYearPrior} from '../../../utilities/helpers/date-helpers';
import {SortingDays} from './types';

export interface IConsumptionDateInputs {
  date: Date;
  organizationId: number | undefined;
}

export const setMonthlyConsumption = (dataPoints: IDailyConsumptionDto[]) => {
  let monthlyConsumption = 0;
  if (dataPoints) {
    dataPoints
      .filter((d) => d.date?.getMonth() === new Date().getMonth())
      .map((d) => (monthlyConsumption += d.consumptionValue!));
  }
  return monthlyConsumption;
};

export const getOneYearPriorSortingDays = (sortingDays: SortingDays) => {
  let newSortingDays = {} as SortingDays;
  newSortingDays.fromDate = getOneYearPrior(sortingDays.fromDate);
  newSortingDays.toDate = getOneYearPrior(sortingDays.toDate);
  return newSortingDays;
};

export const countUniqueConsumptionHours = (consumption: IDailyConsumptionDto[]) => {
  return consumption.filter(
    (consumption, index, self) =>
      index === self.findIndex((t) => t.date.getHours() === consumption.date.getHours() && t.connectionId !== null),
  ).length;
};
export const countUniqueConsumptionDays = (consumption: IDailyConsumptionDto[]) => {
  return consumption.filter(
    (consumption, index, self) =>
      index ===
      self.findIndex((t) => moment(t.date).valueOf() === moment(consumption.date).valueOf() && t.connectionId !== null),
  ).length;
};

export const getConnectionIdsFromIDailyConsumptionDto = (consumption: IDailyConsumptionDto[]) => {
  let ids: string[] = [];
  consumption
    .filter((thing, i, arr) => arr.findIndex((t) => t.connectionId! === thing.connectionId!) === i)
    .forEach((element) => ids.push(element.connectionId!));
  return ids;
};
