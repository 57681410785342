import {B2COptions} from './auth-types';
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const CALLBACK_URL = `${process.env.REACT_APP_BASE_URL}`;
export const AUTHORITY = process.env.REACT_APP_AUTHORITY;
export const TOKEN_ID = process.env.REACT_APP_TOKEN_ID as string;
export const AUTH_SCOPE = `api://${process.env.REACT_APP_API_CLIENT_ID}/${process.env.REACT_APP_AAD_API_PERMISSION}`;
export const TOKEN_ROLE_IDENTIFIER = process.env.REACT_APP_B2C_TOKEN_ROLE_IDENTIFIER as string;
export const TOKEN_STORAGE_KEY = 'token';
export const B2CConfig: B2COptions = {
    ClientId: process.env.REACT_APP_B2C_CLIENT_ID as string,
    RedirectUri: process.env.REACT_APP_B2C_REDIRECT_URI as string,
    Tenant: process.env.REACT_APP_B2C_TENANT as string,
    TenantUrl: process.env.REACT_APP_B2C_TENANT_URL as string,
    SignInPolicy: process.env.REACT_APP_B2C_SIGN_IN_POLICY as string,
    PasswordResetPolicy: process.env.REACT_APP_B2C_PASSWORD_RESET_POLICY as string,
    Scope: process.env.REACT_APP_B2C_SCOPE as string,
    CacheLocation: "localStorage"

};
