import {createStyles, makeStyles, Theme, withStyles} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';
import React, {FC} from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {children?: React.ReactElement<any, any>},
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface PropsFromParent {
  open: boolean;
  onClose: () => void;
}

const StyledDialog = withStyles((theme: Theme) => ({
  root: {},
  paper: {
    minWidth: 400,
    padding: theme.spacing(3, 8, 3, 8),
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 'unset',
  },
}))(Dialog);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minWidth: 600,
    },
  }),
);

export const BasicModal: FC<PropsFromParent> = React.memo(({open, onClose, children}) => {
  const classes = useStyles();
  return (
    <StyledDialog
      BackdropProps={{style: {background: 'transparent'}}}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      keepMounted={false}
      className={classes.container}>
      {children}
    </StyledDialog>
  );
});
