import {createStyles, Drawer, makeStyles, Theme} from '@material-ui/core';
import List from '@material-ui/core/List';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import clsx from 'clsx';
import React, {FC} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useLayoutStyles} from '../../../app-routes';
import ContactIconGrey from '../../../assets/icons/menu-icons/contact_grey.png';
import ContractIconGrey from '../../../assets/icons/menu-icons/contract_grey.png';
import ContractIconWhite from '../../../assets/icons/menu-icons/contract_white.png';
import DashboardIconGrey from '../../../assets/icons/menu-icons/dashboard_grey.png';
import DashboardIconWhite from '../../../assets/icons/menu-icons/dashboard_white.png';
import DocumentsIconGrey from '../../../assets/icons/menu-icons/documents_grey.png';
import DocumentsIconWhite from '../../../assets/icons/menu-icons/documents_white.png';
import EconomyIconGrey from '../../../assets/icons/menu-icons/economy_grey.png';
import EconomyIconWhite from '../../../assets/icons/menu-icons/economy_white.png';
import EnergyConsumptionIconGrey from '../../../assets/icons/menu-icons/energyconsumption_grey.png';
import EnergyConsumptionIconWhite from '../../../assets/icons/menu-icons/energyconsumption_white.png';
import GreenOptimizationIconGrey from '../../../assets/icons/menu-icons/greenoptimization_grey.png';
import GreenOptimizationIconWhite from '../../../assets/icons/menu-icons/greenoptimization_white.png';
import ContactIconWhite from '../../../assets/icons/menu-icons/kontakt_hvid.png';
import MarketingMaterialGrey from '../../../assets/icons/menu-icons/marketing_material_grey.svg';
import MarketingMaterialWhite from '../../../assets/icons/menu-icons/marketing_material_white.png';
import NewsIconGrey from '../../../assets/icons/menu-icons/news_grey.png';
import NewsIconWhite from '../../../assets/icons/menu-icons/news_white.png';
import {useAuth} from '../../../auth/general-auth-provider';
import {localized} from '../../../i18n/i18n';
import {toggleDrawer} from '../../../state/features/webapp/webapp-slice';
import {routes} from '../../../state/routes';
import {AppState} from '../../../state/store';
import {navigationIconColor, sidebarIconBorder} from '../../../styles/color-constants';
import {BasicCard} from '../../components/default-components/basic-card/basic-card';
import {DrawerItem} from './drawer-item';

const drawerWidth = 270;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHover: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: 95,
      '&:hover': {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      position: 'absolute',
      zIndex: 1,
    },
    drawerIcon: {
      color: sidebarIconBorder,
      transform: 'scale(1.4)',
    },
    cardWidth: {
      width: '130px',
      paddingTop: theme.spacing(5),
    },
    drawerContainer: {
      padding: 10,
    },
  }),
);

const useIconStyles = makeStyles((theme: Theme) =>
  createStyles({
    dashboardIcon: {
      width: '25px',
      height: '22px',
      marginLeft: 5,
    },
    economyIcon: {
      width: '25px',
      height: '20px',
      marginLeft: 5,
    },
    greenOptimizationIcon: {
      width: '22px',
      height: '33px',
      marginLeft: 6,
    },
    energyConsumptionIcon: {
      width: '29px',
      height: '22px',
      marginLeft: 2,
    },
    contractsIcon: {
      width: '21px',
      height: '26px',
      marginLeft: 7,
    },
    documentsIcon: {
      width: '20px',
      height: '27px',
      marginLeft: 7,
    },
    newsIcon: {
      width: '29px',
      height: '29px',
      marginLeft: 2,
    },
    ebooksIcon: {
      width: '30px',
      height: '26px',
      marginLeft: 2,
    },
    contactIcon: {
      width: '25px',
      height: '25px',
      marginLeft: 5,
    },
    profileIcon: {
      width: '25px',
      height: '25px',
      fill: 'white',
    },
    signOutIcon: {
      width: '25px',
      height: '25px',
      fill: 'white',
    },
    profileIconGrey: {
      width: '25px',
      height: '25px',
      fill: navigationIconColor,
    },
    signOutIconGrey: {
      width: '25px',
      height: '25px',
      fill: navigationIconColor,
    },
    groupIconGrey: {
      width: '25px',
      height: '25px',
      fill: navigationIconColor,
      marginLeft: 5,
    },
    groupIcon: {
      width: '25px',
      height: '25px',
      fill: 'white',
      marginLeft: 5,
    },
    competitionIcon: {
      width: '25px',
      height: '30px',
      marginLeft: 5,
    },
    marketingMaterialIcon: {
      width: '30px',
      marginLeft: 2,
    },
  }),
);

const MenuDrawer: FC = React.memo(() => {
  let location = useLocation();
  const classes = useStyles();
  const iconClasses = useIconStyles();
  const layoutClasses = useLayoutStyles();
  const locationBaseString = '/' + location.pathname.split('/')[1];
  const drawerIsOpen = useSelector((store: AppState) => store.webAppReducer.open);
  const dispatch = useDispatch();
  const {logout} = useAuth();
  const logOut = () => logout({returnTo: window.location.origin});

  const unreadMarketingMaterial = useSelector((store: AppState) => store.marketingMaterialReducer.unreadMMNotification);

  // Navigation list
  const renderDrawerList = (isDesktop = true) => {
    return (
      <List>
        <DrawerItem
          linkTo={routes.dashboard}
          icon={<img src={DashboardIconGrey} className={iconClasses.dashboardIcon} alt="Dashboard-icon-grey" />}
          iconSelected={
            <img src={DashboardIconWhite} className={iconClasses.dashboardIcon} alt="Dashboard-icon-white" />
          }
          text={localized('Overview')}
          selected={locationBaseString === routes.dashboard}
          identifier={routes.dashboard}
        />
        <DrawerItem
          linkTo={routes.economy}
          icon={<img src={EconomyIconGrey} className={iconClasses.economyIcon} alt="Creditcard-icon-grey" />}
          iconSelected={<img src={EconomyIconWhite} className={iconClasses.economyIcon} alt="Creditcard-icon-white" />}
          text={localized('Economy')}
          selected={locationBaseString === routes.economy}
          identifier={routes.economy}
        />
        <DrawerItem
          linkTo={routes.sustainableInitiatives}
          icon={
            <img
              src={GreenOptimizationIconGrey}
              className={iconClasses.greenOptimizationIcon}
              alt="ClimateGlobe-icon-grey"
            />
          }
          iconSelected={
            <img
              src={GreenOptimizationIconWhite}
              className={iconClasses.greenOptimizationIcon}
              alt="ClimateGlobe-icon-white"
            />
          }
          text={localized('SustainableInitiatives')}
          selected={locationBaseString === routes.sustainableInitiatives}
          identifier={routes.sustainableInitiatives}
        />
        <DrawerItem
          linkTo={routes.marketingMaterial}
          icon={
            <img
              src={MarketingMaterialGrey}
              className={iconClasses.marketingMaterialIcon}
              alt="MarketingMaterial-icon-grey"
            />
          }
          iconSelected={
            <img
              src={MarketingMaterialWhite}
              className={iconClasses.marketingMaterialIcon}
              alt="MarketingMaterial-icon-white"
            />
          }
          text={localized('MarketingMaterial')}
          selected={locationBaseString === routes.marketingMaterial}
          identifier={routes.marketingMaterial}
          unReadNotification={unreadMarketingMaterial}
        />

        <DrawerItem
          linkTo={routes.energyConsumption}
          icon={
            <img src={EnergyConsumptionIconGrey} className={iconClasses.energyConsumptionIcon} alt="Graph-icon-grey" />
          }
          iconSelected={
            <img
              src={EnergyConsumptionIconWhite}
              className={iconClasses.energyConsumptionIcon}
              alt="Graph-icon-white"
            />
          }
          text={localized('EnergyConsumption')}
          selected={locationBaseString === routes.energyConsumption}
          identifier={routes.energyConsumption}
        />
        <DrawerItem
          linkTo={routes.contracts}
          icon={<img src={ContractIconGrey} className={iconClasses.contractsIcon} alt="Documents-icon-grey" />}
          iconSelected={
            <img src={ContractIconWhite} className={iconClasses.contractsIcon} alt="Documents-icon-white" />
          }
          text={localized('Agreements')}
          selected={locationBaseString === routes.contracts}
          identifier={routes.contracts}
        />
        <DrawerItem
          linkTo={routes.documents}
          icon={<img src={DocumentsIconGrey} className={iconClasses.documentsIcon} alt="Contract-icon-grey" />}
          iconSelected={
            <img src={DocumentsIconWhite} className={iconClasses.documentsIcon} alt="Contract-icon-white" />
          }
          text={localized('Documents')}
          selected={locationBaseString === routes.documents}
          identifier={routes.documents}
        />
        <DrawerItem
          linkTo={routes.news}
          icon={<img src={NewsIconGrey} className={iconClasses.newsIcon} alt="News-icon-grey" />}
          iconSelected={<img src={NewsIconWhite} className={iconClasses.newsIcon} alt="News-icon-white" />}
          text={localized('News')}
          selected={locationBaseString === routes.news}
          identifier={routes.news}
        />
        <DrawerItem
          linkTo={routes.contact}
          icon={<img src={ContactIconGrey} className={iconClasses.contactIcon} alt="Contact-icon-grey" />}
          iconSelected={<img src={ContactIconWhite} className={iconClasses.contactIcon} alt="Contact-icon-white" />}
          text={localized('Contact')}
          selected={locationBaseString === routes.contact}
          identifier={routes.contact}
        />
        {!isDesktop && <AddMobileDrawerItems />}
      </List>
    );
  };

  const AddMobileDrawerItems = () => {
    return (
      <>
        <DrawerItem
          linkTo={routes.userInfo}
          icon={<AccountCircleOutlinedIcon className={iconClasses.profileIconGrey} />}
          iconSelected={<AccountCircleOutlinedIcon className={iconClasses.profileIcon} />}
          text={localized('Profile')}
          selected={locationBaseString === routes.userInfo}
          identifier={routes.userInfo}
        />
        <DrawerItem
          linkTo={''}
          icon={<ExitToAppIcon className={iconClasses.signOutIconGrey} />}
          iconSelected={<ExitToAppIcon className={iconClasses.signOutIcon} />}
          text={localized('SignOut')}
          selected={false}
          identifier={''}
          onMenuItemClicked={logOut}
        />
      </>
    );
  };

  // Handles toggle on outside click
  const toggleDrawerOnClick = (reason: string) => {
    dispatch(toggleDrawer());
  };

  // Switch between normal navigation menu and and drawer menu
  return (
    <>
      <div className={layoutClasses.desktopOnly}>
        <div className={classes.cardWidth}>
          <BasicCard className={classes.cardHover}>{renderDrawerList()}</BasicCard>
        </div>
      </div>

      <Drawer
        className={clsx(layoutClasses.mobileOnly)}
        onClose={toggleDrawerOnClick}
        BackdropProps={{invisible: true}}
        anchor={'left'}
        open={drawerIsOpen}>
        <div className={clsx(layoutClasses.mobileOnly, classes.drawerContainer)}>{renderDrawerList(false)}</div>
      </Drawer>
    </>
  );
});

export default MenuDrawer;
