import {Badge, Box, createStyles, Grid, makeStyles, SvgIcon, Theme, Typography} from '@material-ui/core';
import React, {FC} from 'react';
import {useDispatch} from 'react-redux';
import {localized} from '../../../i18n/i18n';
import {chooseGreenOptimization} from '../../../state/features/green-optimization/operation';
import {showErrorSnackbar} from '../../../state/features/snackbar/snackbar-slice';
import {basicCardTextColor, disabledColor, primaryTextColor, secondaryColor} from '../../../styles/color-constants';
import {h3, h5} from '../../../styles/font-size-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {DownloadFileButton} from './download-file-button';
import {SmallMaterialCardPropsFromParent} from './Interfaces';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    DownloadBoxStyle: {
      gridRow: 2,
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      justifyItems: 'stretch',
      gap: 30,
    },
    GridAroundSmallMaterial: {
      display: 'flex',
      minHeight: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexGrow: 1,
    },
    DescriptionStyle: {
      height: '90%',
    },
    smallCard: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      boxShadow: (active: boolean) => (active ? 'inset 0px 0px 0px 2px ' + secondaryColor : ''),
    },
    contactMe: {
      position: 'absolute',
      [theme.breakpoints.up('xs')]: {
        bottom: '15px',
      },
      [theme.breakpoints.up('md')]: {
        bottom: '2%',
      },
      zIndex: 1,
      cursor: 'pointer',
      marginLeft: 0,
      marginRight: 5,
      minWidth: 50,
      maxWidth: 120,
      maxHeight: 40,
      boxShadow: ' 0px 0px  20px grey',
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      justifyContent: 'center',
      transition: 'flex-grow 300ms ease-in-out',
      color: primaryTextColor,
      '&:hover': {
        backgroundColor: '#ccc',
        color: '#FFF',
        flex: 1,
      },
    },
    headerTypo: {
      fontSize: h3,
      fontFamily: 'Lato',
      fontWeight: 'bold',
    },
    bodyTypo: {
      fontSize: h5,
      fontFamily: 'Lato',
      fontWeight: 'normal',
    },
    badge: {display: 'flex', transform: 'translate(20px, -20px)'},
    SvgIcon: {
      overflow: 'visible',
      margin: 1,
      marginRight: 10,
      padding: 2,
      minHeight: 27,
      minWidth: 27,
      float: 'left',
    },
  }),
);

export const SmallMaterialCard: FC<SmallMaterialCardPropsFromParent> = React.memo(
  ({
    access,
    description,
    title,
    isNewToUser,
    organizationId,
    activeCategory,
    bannerIcon,
    downLoadLinks,
    ...props
  }) => {
    const classes = useStyles(access);
    const dispatch = useDispatch();

    const downLoadButtons = downLoadLinks.map((link, index) => (
      <DownloadFileButton
        disabled={!access}
        id={link?.id}
        name={link?.title}
        type={link?.type}
        key={index}
        organizationId={organizationId}
      />
    ));

    const BodyTextColor = access ? basicCardTextColor : disabledColor;
    const IconHeaderColor = access ? primaryTextColor : disabledColor;

    const [linkDisabled, setLinkDisabled] = React.useState(false);
    const handleOnClick = async () => {
      if (linkDisabled) return;
      if (organizationId) {
        setLinkDisabled(true);
        try {
          await chooseGreenOptimization(organizationId, activeCategory?.toString() ?? '');
        } catch (error) {
          dispatch(showErrorSnackbar(localized('MailNotSuccessfullSent')));
        }
        setLinkDisabled(false);
      }
    };

    return (
      <>
        <BasicCard color={BodyTextColor} className={classes.smallCard}>
          <Badge
            badgeContent={isNewToUser ? 'New' : ''}
            color="secondary"
            overlap="rectangle"
            invisible={!isNewToUser}
            className={classes.badge}
          />
          <Box className={classes.GridAroundSmallMaterial}>
            <Box display="flex" flexWrap="nowrap">
              <SvgIcon
                style={{
                  color: IconHeaderColor,
                }}
                className={classes.SvgIcon}
                component={bannerIcon}
              />
              <Box>
                <Typography className={classes.headerTypo}>{title}</Typography>
                <Box className={classes.DescriptionStyle}>
                  <Typography className={classes.bodyTypo}>{description}</Typography>
                </Box>
              </Box>
            </Box>
            <Grid>
              <Box className={classes.DownloadBoxStyle}>{downLoadButtons}</Box>
            </Grid>
            {!linkDisabled && !access && (
              <BasicCard onClick={handleOnClick} className={classes.contactMe}>
                <Typography className={classes.bodyTypo}>{localized('ContactMe')} </Typography>
              </BasicCard>
            )}
          </Box>
        </BasicCard>
      </>
    );
  },
);
