import React, {FC} from 'react';
import {Redirect} from 'react-router';
import {RedirectUriStorageKey} from '../utilities/constants';
import {useAuth} from './general-auth-provider';

const Callback: FC = () => {
  const {isAuthenticated, logout} = useAuth();
  const signOut = () => logout({returnTo: window.location.href});
  let redirectUrl: string | null = window.sessionStorage.getItem(RedirectUriStorageKey);

  if (isAuthenticated && redirectUrl) {
    window.sessionStorage.removeItem(RedirectUriStorageKey);
    return <Redirect push={true} to={redirectUrl} />;
  } else {
    signOut();
    return null;
  }
};

export default Callback;
