import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {DocumentType, IDebtorPostingDocumentDto} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {DebtorPostingFilter} from '../../../state/features/invoice/types';
import {AppState} from '../../../state/store';
import {GridWithCustomBreakpoints} from '../../../styles/custom-grid-breakpoints';
import {DocumentCard, DocumentCardTableItem} from '../document/document-card';
import {FilterProvider} from '../filterprovider';

export const DebtorPostingDocumentCard = React.memo(() => {
  const debtorPostingsDocuments = useSelector((state: AppState) => state.documentsReducer.debtorPostingDocuments);
  const debtorPostingsDocumentsFilter = useSelector((state: AppState) => state.debtorPostingReducer.currentFilter);
  const pending = useSelector((state: AppState) => state.documentsReducer.pending);
  const [documentsToUse, setDocumentsToUse] = useState<DocumentCardTableItem[]>([]);

  useEffect(() => {
    const documents = debtorPostingsDocuments.filter(documentFilter(debtorPostingsDocumentsFilter)).map(
      (dp) =>
        ({
          ...dp,
          rightHeader: dp.amount && dp.amount.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        } as DocumentCardTableItem),
    );
    setDocumentsToUse(documents);
  }, [debtorPostingsDocuments, debtorPostingsDocumentsFilter, setDocumentsToUse]);

  return (
    <GridWithCustomBreakpoints item xs={12} sm={12} md={12} lg={12} betweenlgandxl={5}>
      <FilterProvider>
        <DocumentCard
          title={localized('Invoice')}
          parentDocuments={documentsToUse!}
          pending={pending}
          dateFrom={debtorPostingsDocumentsFilter.DateFrom}
          dateTo={debtorPostingsDocumentsFilter.DateTo}
          rightHeader={localized('Amount')}
          docuNumber={15}
        />
      </FilterProvider>
    </GridWithCustomBreakpoints>
  );
});

const documentFilter = (debtorPostingsFilter: DebtorPostingFilter) => (doc: IDebtorPostingDocumentDto) => {
  let useDocument = doc.documentType === DocumentType.Invoice;
  if (
    debtorPostingsFilter.DebtorPostingType !== undefined &&
    debtorPostingsFilter.DebtorPostingType !== doc.debtorPostingType
  ) {
    return false;
  }
  if (debtorPostingsFilter.InvoiceNumber) {
    const docNameLowerCase = doc.invoiceNumber?.toLowerCase();
    const invoiceNameLowerCase = debtorPostingsFilter.InvoiceNumber.toLowerCase();
    if (!docNameLowerCase?.includes(invoiceNameLowerCase)) {
      return false;
    }
  }
  return useDocument;
};
