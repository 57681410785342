import {Grid} from '@material-ui/core';
import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import {AppState} from '../../../state/store';
import { userInfoUseStyles } from '../user-info/user-info-helper';
import {ContactCard} from './contact-card';

export const ContactCards: FC = React.memo(() => {
  const {contactsSlice} = useSelector((store: AppState) => store.contactsReducer);
 const classes = userInfoUseStyles();
  return (
    <Grid container>
      {contactsSlice.map((contact, index) => (
        <Grid className={classes.contactCardWrapper} key={index} item>
          <ContactCard  contact={contact} />
        </Grid>
      ))}
    </Grid>
  );
});
