import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MarketingMaterialCategories, MarketingMaterialDto, MaterialFormats, MaterialTypes} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {getMarketingMaterials} from './operation';
import {
  DefaultMarketingMaterialPageData,
  GetCategoryName,
  MarketingMaterialCategoryPageDatas,
  MarketingMaterialPageData,
} from './types';

const initialNotifications: MarketingMaterialCategories[] = [];
export const InitialMarketingMaterialState: MarketingMaterialCategoryPageDatas[] = [
  {
    Category: MarketingMaterialCategories.WindPower,
    MarketingMaterialPageData: DefaultMarketingMaterialPageData(MarketingMaterialCategories.WindPower),
  },
  {
    Category: MarketingMaterialCategories.ClimateAction,
    MarketingMaterialPageData: DefaultMarketingMaterialPageData(MarketingMaterialCategories.ClimateAction),
  },
  {
    Category: MarketingMaterialCategories.Co2Mapping,
    MarketingMaterialPageData: DefaultMarketingMaterialPageData(MarketingMaterialCategories.Co2Mapping),
  },
  {
    Category: MarketingMaterialCategories.EnergyConsulting,
    MarketingMaterialPageData: DefaultMarketingMaterialPageData(MarketingMaterialCategories.EnergyConsulting),
  },
  {
    Category: MarketingMaterialCategories.ClimateForest,
    MarketingMaterialPageData: DefaultMarketingMaterialPageData(MarketingMaterialCategories.ClimateForest),
  },
];

export const InitialActiveIds = [
  {
    Category: MarketingMaterialCategories.WindPower,
    ActiveIds: [] as number[],
  },
  {
    Category: MarketingMaterialCategories.ClimateAction,
    ActiveIds: [] as number[],
  },
  {
    Category: MarketingMaterialCategories.Co2Mapping,
    ActiveIds: [] as number[],
  },
  {
    Category: MarketingMaterialCategories.EnergyConsulting,
    ActiveIds: [] as number[],
  },
  {
    Category: MarketingMaterialCategories.ClimateForest,
    ActiveIds: [] as number[],
  },
];

export const MarketingMaterialsSlice = createSlice({
  name: 'marketingMaterials',
  initialState: {
    marketingMaterials: InitialMarketingMaterialState,
    activeCategorys: [MarketingMaterialCategories.NotSet],
    activeMaterials: InitialActiveIds,
    unreadMMNotification: false,
    notifications: initialNotifications,
    materialDownloaded: false,
  },
  reducers: {
    addNotification: (state, action: PayloadAction<MarketingMaterialCategories>) => {
      state.notifications.push(action.payload);

      if (state.notifications.length > 0) {
        state.unreadMMNotification = true;
      }
    },
    removeNotification: (state, action: PayloadAction<MarketingMaterialCategories>) => {
      state.notifications = state.notifications.filter((notification) => notification !== action.payload);

      if (state.notifications.length === 0) {
        state.unreadMMNotification = false;
      }
    },
    resetMarketingMaterialNotifications: (state) => {
      state.notifications = [];
      state.unreadMMNotification = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarketingMaterials.pending, (state, action) => {
      state.marketingMaterials = InitialMarketingMaterialState;
      state.activeMaterials = InitialActiveIds;
      state.materialDownloaded = false;
    });
    builder.addCase(getMarketingMaterials.fulfilled, (state, action) => {
      if (action.payload) {
        state.activeCategorys = [] as MarketingMaterialCategories[];

        action.payload.forEach((marketingMaterialDto) => {
          const category = marketingMaterialDto.marketingMaterialCategory;

          //add ID to active materials
          const activeCategoryIndex = state.activeMaterials.findIndex(
            (activeMaterial) => activeMaterial.Category === category,
          );
          if (activeCategoryIndex > -1) {
            state.activeMaterials[activeCategoryIndex].ActiveIds.push(marketingMaterialDto?.id);
          }

          //add marketing material to state
          const categoryIndex = state.marketingMaterials.findIndex(
            (marketingMaterial) => marketingMaterial.Category === category,
          );
          state.activeCategorys.push(category);

          if (categoryIndex > -1) {
            const MarketingPage = state.marketingMaterials[categoryIndex].MarketingMaterialPageData;
            switch (marketingMaterialDto.materialType) {
              //small cards
              case MaterialTypes.CSRTexts:
                smallCardMapper(marketingMaterialDto, 'cSRTexts', MarketingPage, 'CSR Texts');
                break;
              case MaterialTypes.Diploma:
                smallCardMapper(
                  marketingMaterialDto,
                  'diploma',
                  MarketingPage,
                  GetCategoryName(marketingMaterialDto.marketingMaterialCategory) + localized('DiplomaTitle'),
                );
                break;
              case MaterialTypes.SoMeTexts:
                smallCardMapper(marketingMaterialDto, 'soMeTexts', MarketingPage, localized('SoMeTitle'));
                break;
              //Big cards
              case MaterialTypes.WideBanner:
                bigCardMapper(marketingMaterialDto, 'banners', MarketingPage, 'wideDownLoadLinks', '');
                break;
              case MaterialTypes.SquareBanner:
                bigCardMapper(marketingMaterialDto, 'banners', MarketingPage, 'squareDownLoadLinks', '');
                break;

              default:
                break;
            }
          }
        });
        state.materialDownloaded = true;
      }
    });
  },
});

export default MarketingMaterialsSlice.reducer;

export const {
  addNotification,
  removeNotification,
  resetMarketingMaterialNotifications,
} = MarketingMaterialsSlice.actions;

const smallCardMapper = (
  marketingMaterialDto: MarketingMaterialDto,
  materialType: keyof MarketingMaterialPageData,
  marketingMaterialPageData: MarketingMaterialPageData,
  linkTitle: string,
) => {
  if (materialType !== 'banners') {
    marketingMaterialPageData[materialType].access = true;

    marketingMaterialPageData[materialType].downLoadLinks = [
      {
        title: linkTitle,
        id: marketingMaterialDto.id,
        type: getFormatName(marketingMaterialDto.materialFormat),
        disabled: false,
      },
    ];
    marketingMaterialPageData[materialType].isNewToUser = marketingMaterialDto.newToUser;
  }
};

const bigCardMapper = (
  marketingMaterialDto: MarketingMaterialDto,
  materialType: keyof MarketingMaterialPageData,
  marketingMaterialPageData: MarketingMaterialPageData,
  bannerType: 'squareDownLoadLinks' | 'wideDownLoadLinks',
  linkTitle: string,
) => {
  if (materialType === 'banners') {
    marketingMaterialPageData[materialType].access = true;
    const DownloadLinkIndex = marketingMaterialPageData[materialType][bannerType].findIndex(
      (DownloadLink) => DownloadLink.type === getFormatName(marketingMaterialDto.materialFormat),
    );
    marketingMaterialPageData[materialType][bannerType][DownloadLinkIndex] = {
      title: linkTitle,
      id: marketingMaterialDto.id,
      type: getFormatName(marketingMaterialDto.materialFormat),
      disabled: false,
    };

    if (!marketingMaterialPageData[materialType].isNewToUser)
      marketingMaterialPageData[materialType].isNewToUser = marketingMaterialDto.newToUser;
  }
};

export const getFormatName = (format: MaterialFormats) => {
  switch (format) {
    case MaterialFormats.PDF:
      return 'PDF';
    case MaterialFormats.Word:
      return 'Word';
    case MaterialFormats.JPG:
      return 'JPG';
    case MaterialFormats.PNG:
      return 'PNG';
    case MaterialFormats.MP4:
      return 'MP4';
    case MaterialFormats.NotSet:
      return '';
    default:
      return '';
  }
};
