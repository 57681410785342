import {Box, createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import clsx from 'clsx';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLayoutStyles} from '../../../app-routes';
import DateIcon from '../../../assets/icons/date_icon.svg';
import {localized} from '../../../i18n/i18n';
import {getCustomerServiceInfo} from '../../../state/features/contact/operation';
import {AppState} from '../../../state/store';
import {secondaryTextColor} from '../../../styles/color-constants';
import {h1} from '../../../styles/font-size-constants';
import {formatToLocalDateAndLeadingZeros} from '../../../utilities/platform-helpers/date-helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    documentIcon: {
      [theme.breakpoints.up('md')]: {
        width: '42px',
        height: '52px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '30px',
        height: '40px',
      },
    },
    documentIconContainer: {
      height: '52px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
    },
    alignSelfCenter: {
      alignSelf: 'center',
    },
    iconBox: {
      alignSelf: 'center',
      maxWidth: 50,
    },
    boldText: {
      paddingLeft: '5px',

      fontWeight: 800,
      [theme.breakpoints.up('md')]: {
        fontSize: 18,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    MinHeight77: {minHeight: 77},
    Date: {
      marginRight: 20,
      textAlign: 'right',
      fontWeight: 800,
      fontSize: h1,
      color: secondaryTextColor,
      [theme.breakpoints.up('md')]: {
        fontSize: 30,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 21,
      },
    },
    paddingLeftFive: {},
    documentContainer: {
      backgroundColor: '#fff',

      marginTop: '16px',
      boxShadow: 'rgb(131 141 155 / 20%) 3px 3px 10px',
      borderRadius: 10,

      paddingLeft: 20,

      [theme.breakpoints.up('md')]: {
        maxWidth: '700px',
        marginLeft: '16px',
        marginBottom: '16px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
        marginBottom: '16px',
      },
    },
    gridContainer: {overflow: 'hidden'},
  }),
);

export const NextInvoiceCard = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const layoutClasses = useLayoutStyles();
  const {customerServiceInfo} = useSelector((store: AppState) => store.contactsReducer);
  useEffect(() => {
    dispatch(getCustomerServiceInfo());
  }, [dispatch]);

  return (
    <div className={classes.gridContainer}>
      <Grid item xs={12}>
        <Box className={classes.documentContainer}>
          <Grid className={classes.MinHeight77} container direction="row" alignItems="center" justify="space-between">
            <div className={classes.documentIconContainer}>
              <img src={DateIcon} className={classes.documentIcon} alt={'Document-icon-black'} />
              <Typography className={clsx(layoutClasses.desktopOnly, classes.boldText)}>
                {localized('NextInvoiceDefaultText')}
              </Typography>
              <Typography className={clsx(layoutClasses.mobileOnly, classes.boldText)}>
                {localized('NextInvoiceTextShort')}
              </Typography>
            </div>
            <Typography className={classes.Date}>
              {customerServiceInfo && formatToLocalDateAndLeadingZeros(customerServiceInfo.nextInvoice)}
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </div>
  );
});
