import GA4React from 'ga-4-react';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import {Monitor} from './services/telemetry-service';
import history from './state/history';
import {GoogleAnalyticsKey, InsightsKey} from './utilities/constants';

declare const window: any;
const ga4react = new GA4React(GoogleAnalyticsKey);
InsightsKey && Monitor.initialize(InsightsKey, history);

(async (_) => {
  await ga4react.initialize();

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
})();
