import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import { setSelectedOrganization } from '../organization/organization-slice';
import {getGreenOptimizationContractsStatus} from './operation';
import {GreenOptimizationState} from './types';

const initialState: GreenOptimizationState = {
  greenOptimizationsContracts: undefined,
  numberOfGreenOptimizationContracts: 0,
  pending: false,
  isSuccess: false,
};

export const greenOptimizationSlice = createSlice({
  name: 'greenOptimization',
  initialState,
  reducers: {
    setNumberGreenOptimizationContracts: (state, action: PayloadAction<number>) => {
      if (state?.greenOptimizationsContracts) {
        state.numberOfGreenOptimizationContracts = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    // Get
    builder.addCase(getGreenOptimizationContractsStatus.fulfilled, (state, action) => {
      state.pending = false;
      state.greenOptimizationsContracts = action.payload;

      let numberOfContracts: number = 0;
      if (action.payload?.windEnergy) {
        numberOfContracts++;
      }
      if (action.payload?.climateEnergy) {
        numberOfContracts++;
      }
      if (action.payload?.co2Mapping) {
        numberOfContracts++;
      }
      if (action.payload?.energyAdvice) {
        numberOfContracts++;
      }
      if (action.payload?.climateForest) {
        numberOfContracts++;
      }

      state.numberOfGreenOptimizationContracts = numberOfContracts;
    });

    builder.addCase(getGreenOptimizationContractsStatus.pending, (state) => {
      state.pending = true;
    });

    builder.addCase(getGreenOptimizationContractsStatus.rejected, (state) => {
      state.pending = false;
    });
    builder.addCase(setSelectedOrganization, (state, action) => {
      if (action.payload.previousOrganizationId !== undefined) {
        return initialState;
      }
    });
  },
});

export const {setNumberGreenOptimizationContracts} = greenOptimizationSlice.actions;
export default greenOptimizationSlice.reducer;
