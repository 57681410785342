import {Grid, GridProps} from '@material-ui/core';
import React, {FC} from 'react';

// Creating a custom grid-breakpoint
// Inspiration: https://stackoverflow.com/questions/57773595/react-material-ui-grid-is-it-possible-to-add-breakpoints

declare module '@material-ui/core/styles/createBreakpoints' {
  export interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    m: true;
    md: true;
    lg: true;
    xl: true;
    betweenlgandxl: true;
  }
}

interface PropsCostumGrid {}
export const GridWithCustomBreakpoints: FC<PropsCostumGrid & GridProps> = React.memo(({children, ...others}) => {
  const betweenlgandxlBreakpoint = `MuiGrid-grid-betweenlgandxl-${others.betweenlgandxl}`;

  return (
    <Grid className={betweenlgandxlBreakpoint} {...others}>
      {children}
    </Grid>
  );
});
