import {Box, BoxProps} from '@material-ui/core';
import clsx from 'clsx';
import React, {FC} from 'react';
import {primaryTextColor} from '../../../../styles/color-constants';
import {h5} from '../../../../styles/font-size-constants';

export const TextButton: FC<BoxProps> = React.memo((props) => {
  return (
    <Box
      m={1}
      textAlign="left"
      color={primaryTextColor}
      fontSize={h5}
      textOverflow="Lato"
      whiteSpace="nowrap"
      overflow="hidden"
      {...props}
      className={clsx(props.className)}>
      {props.children}
    </Box>
  );
});
