import { createStyles, makeStyles, Theme } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { energyConsumptionSlice } from '../../../state/features/energy-consumption/energy-consumption-slice';
import { FixedPeriodCategory, SortingDays } from '../../../state/features/energy-consumption/types';
import { AppState } from '../../../state/store';
import { arrowColor, primaryTextColor } from '../../../styles/color-constants';
import { h6 } from '../../../styles/font-size-constants';
import {
  addOneDay,
  addOneMonth,
  addOneMonthToEndOfMonth,
  addOneQuarter,
  addOneQuarterToEndOfMonth,
  addOneWeek,
  subtractOneDay,
  subtractOneMonth,
  subtractOneMonthToEndOfMonth,
  subtractOneQuarter,
  subtractOneQuarterToEndOfMonth,
  subtractOneWeek,
} from '../../../utilities/helpers/date-helpers';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrowFlexbox: {
      maxHeight: 40,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 5,
      marginRight: 5,
    },
    typogrphy: {
      fontSize: h6,
    },
    arrowIcon: {
      opacity: 1,
      cursor: 'pointer',
      color: arrowColor,
      '&:hover': {
        color: primaryTextColor,
      },
    },
    arrowDiv: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

interface PropsFromParent {}

export const ConsumptionBackAndForthButtons: FC<PropsFromParent> = React.memo(({children, ...props}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {currentFixedPeriodCategory, sortingDays} = useSelector((store: AppState) => store.energyConsumptionReducer);
  const [newSortingDays, setNewSortingDays] = useState<SortingDays>(sortingDays);

  useEffect(() => {
    setNewSortingDays(sortingDays);
  }, [sortingDays, currentFixedPeriodCategory]);

  const handleBackPressed = () => {
    let newLocalSortingDays: SortingDays = {
      ...newSortingDays,
    };

    switch (currentFixedPeriodCategory) {
      case FixedPeriodCategory.Day:
        newLocalSortingDays.fromDate = subtractOneDay(newSortingDays.fromDate);
        newLocalSortingDays.toDate = subtractOneDay(newSortingDays.toDate);
        break;

      case FixedPeriodCategory.Week:
        newLocalSortingDays.fromDate = subtractOneWeek(newSortingDays.fromDate);
        newLocalSortingDays.toDate = subtractOneWeek(newSortingDays.toDate);
        break;

      case FixedPeriodCategory.Month:
        newLocalSortingDays.fromDate = subtractOneMonth(newSortingDays.fromDate);
        newLocalSortingDays.toDate = subtractOneMonthToEndOfMonth(newSortingDays.toDate);
        break;

      case FixedPeriodCategory.Quarter:
        newLocalSortingDays.fromDate = subtractOneQuarter(newSortingDays.fromDate);
        newLocalSortingDays.toDate = subtractOneQuarterToEndOfMonth(newSortingDays.toDate);
        break;

      default:
        break;
    }

    dispatch(energyConsumptionSlice.actions.setSortingDays(newLocalSortingDays));
  };

  const handleForthPressed = () => {
    let newLocalSortingDays: SortingDays = {
      ...newSortingDays,
    };

    switch (currentFixedPeriodCategory) {
      case FixedPeriodCategory.Day:
        newLocalSortingDays.fromDate = addOneDay(newSortingDays.fromDate);
        newLocalSortingDays.toDate = addOneDay(newSortingDays.toDate);
        break;

      case FixedPeriodCategory.Week:
        newLocalSortingDays.fromDate = addOneWeek(newSortingDays.fromDate);
        newLocalSortingDays.toDate = addOneWeek(newSortingDays.toDate);
        break;

      case FixedPeriodCategory.Month:
        newLocalSortingDays.fromDate = addOneMonth(newSortingDays.fromDate);
        newLocalSortingDays.toDate = addOneMonthToEndOfMonth(newSortingDays.toDate);
        break;

      case FixedPeriodCategory.Quarter:
        newLocalSortingDays.fromDate = addOneQuarter(newSortingDays.fromDate);
        newLocalSortingDays.toDate = addOneQuarterToEndOfMonth(newSortingDays.toDate);
        break;

      default:
        break;
    }

    dispatch(energyConsumptionSlice.actions.setSortingDays(newLocalSortingDays));
  };

  return (
    <BasicCard className={classes.arrowFlexbox}>
      <KeyboardArrowLeftIcon onClick={handleBackPressed} className={classes.arrowIcon} />
      <KeyboardArrowRightIcon onClick={handleForthPressed} className={classes.arrowIcon} />
    </BasicCard>
  );
});
