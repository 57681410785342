import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IUser} from '../../../api/api';
import deleteIcon from '../../../assets/icons/delete.svg';
import {localized} from '../../../i18n/i18n';
import {deleteUserFromOrganization} from '../../../state/features/user/operation';
import {AppState} from '../../../state/store';

export const DeleteUserFromOrganizationButtonStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {cursor: 'pointer'},
  }),
);

interface DeleteUserFromOrganizationButtonProps {
  user: IUser;
}

export const DeleteUserFromOrganizationButton = React.memo((props: DeleteUserFromOrganizationButtonProps) => {
  const classes = DeleteUserFromOrganizationButtonStyles();
  const organizationId = useSelector((state: AppState) => state.organizationReducer.organizationId);
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const confirmDeleteUser = useCallback(() => {
    if (props.user && props.user.id && organizationId) {
      dispatch(deleteUserFromOrganization({userId: props.user.id, organizationId}));
    }
    handleClose();
  }, [props.user, organizationId, dispatch]);

  return (
    <>
      <div className={classes.button} onClick={handleClickOpen}>
        <img src={deleteIcon} width="32px" height="23px" alt="firm-icon" />
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {localized('RemoveUser')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {localized('AreYouSureYouWantToRemove') + ' ' + props.user.name + ' ' + localized('FromTheOrganization') + '?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {localized('Cancel')}
          </Button>
          <Button onClick={confirmDeleteUser} color="primary" autoFocus>
            {localized('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
