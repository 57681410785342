import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import React, {FC} from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageBox: {
      display: 'flex',
      height: 80,
      alignItems: 'flex-end',
      justifyContent: 'center',
      marginBottom: 10
    },
  }),
);

export const GreenImageItem: FC = React.memo((props) => {
  const classes = useStyles();

  return <Box className={classes.imageBox}>{props.children}</Box>;
});
