import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import React, {FC} from 'react';
import {localized, Translation} from '../../i18n/i18n';

const TitleAndSubTypographyUseStyles = makeStyles((theme: Theme) =>
  createStyles({
    textStyle: {
      fontWeight: 800,
      marginTop: '20px',
    },
  }),
);

interface TitleAndSubtypography {
  title: keyof Translation;
  subTexts?: string[];
  subText?: string;
  className?: string;
  customSubtitle?: JSX.Element;
}

export const TitleAndSubTypography: FC<TitleAndSubtypography> = React.memo((props: TitleAndSubtypography) => {
  const classes = TitleAndSubTypographyUseStyles();
  return (
    <div className={props.className}>
      <Typography className={classes.textStyle}>{localized(props.title)}</Typography>
      {props.subText && <Typography>{props.subText}</Typography>}
      {props.subTexts && props.subTexts.map(generateSubTexts())}
      {props.customSubtitle && props.customSubtitle}
    </div>
  );
});
function generateSubTexts(): (value: string, index: number, array: string[]) => JSX.Element {
  return (st, index) => <Typography key={index}>{st}</Typography>;
}
