import {Box, createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DocumentType, IContractDto, IDocumentDto, ProductCategory} from '../../../api/api';
import {useLayoutStyles} from '../../../app-routes';
import Co2Kortlaegning from '../../../assets/icons/green-optimization/Co2Kortlaegning.svg';
import KLIMAskov from '../../../assets/icons/green-optimization/KLIMAskov.png';
import KlimaTiltag from '../../../assets/icons/green-optimization/KlimaTiltag.svg';
import Raadgivning from '../../../assets/icons/green-optimization/Raadgivning.svg';
import VINDenergi from '../../../assets/icons/green-optimization/VINDenergi.svg';
import {localized} from '../../../i18n/i18n';
import {getContractsDetails} from '../../../state/features/contract/operation';
import {getDocumentDetails} from '../../../state/features/document/operation';
import {getGreenOptimizationContractsStatus} from '../../../state/features/green-optimization/operation';
import {AppState} from '../../../state/store';
import {cardSubText} from '../../../styles/color-constants';
import {GridWithCustomBreakpoints} from '../../../styles/custom-grid-breakpoints';
import {h5} from '../../../styles/font-size-constants';
import {PageContentContainer} from '../../../view/components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../view/components/default-components/page-header.tsx/page-header';
import {PageTitle} from '../../../view/components/default-components/page-title/page-title';
import {TitleAndSubCard} from '../../../view/components/default-components/title-and-sub-card';
import {ContractCard} from '../../components/contract/contract-card';
import {DocumentCard} from '../../components/document/document-card';
import {FilterProvider} from '../../components/filterprovider';
import {GreenElementCard} from '../../components/green-optimization/green-element-card';
import {GreenImageItem} from '../../components/green-optimization/green-image-item';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subText: {
      fontSize: h5,
      margin: '0 0 0 16px',
    },
    cardYouGetText: {
      fontSize: h5,
      textAlign: 'left',
      margin: '15px 0',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word',
      color: cardSubText,
    },
  }),
);

export const GreenOptimization = React.memo(() => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {pending, documentsSlice} = useSelector((store: AppState) => store.documentsReducer);
  const {contractsSlice} = useSelector((store: AppState) => store.contractsReducer);
  const [greenDocs, setGreenDocs] = useState<IContractDto[]>();
  const [reportsDocs, setReportsDocs] = useState<IDocumentDto[]>();
  const {greenOptimizationsContracts} = useSelector((store: AppState) => store.greenOptimizationReducer);
  const layoutClasses = useLayoutStyles();
  // Fetch page data
  const {organizationId} = useSelector((store: AppState) => store.organizationReducer);
  useEffect(() => {
    if (organizationId) {
      dispatch(getDocumentDetails(organizationId));
      dispatch(getContractsDetails(organizationId));
      dispatch(getGreenOptimizationContractsStatus(organizationId));
    }
  }, [dispatch, organizationId]);

  useEffect(() => {
    setGreenDocs(contractsSlice.filter((d) => d.productCategory === ProductCategory.GreenEnergy));
    setReportsDocs(documentsSlice.filter((d) => d.documentType === DocumentType.GreenOptimizationReports));
  }, [contractsSlice, documentsSlice]);
  return (
    <PageTitle title={localized('SustainableInitiatives')}>
      <PageHeader area="template" title={localized('SustainableInitiatives')}></PageHeader>
      <PageContentContainer>
        <Typography className={clsx(classes.subText, layoutClasses.desktopOnly)}>
          {localized('BelowYouCanSeeWhichGreenInitiativesYouHaveChosen')}
        </Typography>
        <Typography className={clsx(classes.subText, layoutClasses.desktopOnly)}>
          {localized('YourGreenOptionsOfCO2NeutralPowerHelpReduceCO2Emissions')}
        </Typography>
        <Grid container direction="row" alignItems="flex-start">
          <Grid container direction="row">
            <GridWithCustomBreakpoints item xs={12} sm={6} lg={4} betweenlgandxl={true}>
              <GreenElementCard
                greenOptimizationId={'1'}
                active={greenOptimizationsContracts?.windEnergy ? true : false}>
                <GreenImageItem>
                  <img src={VINDenergi} alt={'Green_01'} />
                </GreenImageItem>
                <TitleAndSubCard title={localized('WindPower')} />
                <Typography className={classes.cardYouGetText}>
                  {localized('ByChoosingCO2NeutralWindEnergyYouGet')}
                </Typography>
              </GreenElementCard>
            </GridWithCustomBreakpoints>
            <GridWithCustomBreakpoints item xs={12} sm={6} lg={4} betweenlgandxl={true}>
              <GreenElementCard
                greenOptimizationId={'2'}
                active={greenOptimizationsContracts?.climateEnergy ? true : false}>
                <GreenImageItem>
                  <img src={KlimaTiltag} alt={'Green_02'} />
                </GreenImageItem>
                <TitleAndSubCard title={localized('ClimateAction')} />
                <Typography className={classes.cardYouGetText}>{localized('WithClimateEnergyYouGet')}</Typography>
              </GreenElementCard>
            </GridWithCustomBreakpoints>
            <GridWithCustomBreakpoints item xs={12} sm={6} lg={4} betweenlgandxl={true}>
              <GreenElementCard
                greenOptimizationId={'3'}
                active={greenOptimizationsContracts?.co2Mapping ? true : false}>
                <GreenImageItem>
                  <img src={Co2Kortlaegning} alt={'Green_03'} />
                </GreenImageItem>
                <TitleAndSubCard title={localized('CO2mapping')} />
                <Typography className={classes.cardYouGetText}>{localized('WithCO2MappingYouGet')}</Typography>
              </GreenElementCard>
            </GridWithCustomBreakpoints>
            <GridWithCustomBreakpoints item xs={12} sm={6} lg={4} betweenlgandxl={true}>
              <GreenElementCard
                greenOptimizationId={'4'}
                active={greenOptimizationsContracts?.energyAdvice ? true : false}>
                <GreenImageItem>
                  <img src={Raadgivning} alt={'Green_04'} />
                </GreenImageItem>
                <TitleAndSubCard title={localized('EnergyConsulting')} />
                <Typography className={classes.cardYouGetText}>{localized('ByChoosingEnergyAdviceYouGet')}</Typography>
              </GreenElementCard>
            </GridWithCustomBreakpoints>
            <GridWithCustomBreakpoints item xs={12} sm={6} lg={4} betweenlgandxl={true}>
              <GreenElementCard
                greenOptimizationId={'5'}
                active={greenOptimizationsContracts?.climateForest ? true : false}>
                <GreenImageItem>
                  <img src={KLIMAskov} alt={'Green_05'} />
                </GreenImageItem>
                <TitleAndSubCard title={localized('CLIMATEForest')} />
                <Typography className={classes.cardYouGetText}>
                  {localized('ByChoosingCLIMATEforestYouAreHelpingTo')}
                </Typography>
              </GreenElementCard>
            </GridWithCustomBreakpoints>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FilterProvider>
              <ContractCard
                title={localized('GreenEnergy')}
                parentContracts={greenDocs}
                pending={pending}
                cardHeight={540}
                docuNumber={8}
                contractPage={false}
              />
            </FilterProvider>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FilterProvider>
              <DocumentCard
                title={localized('Reports')}
                parentDocuments={reportsDocs!}
                pending={pending}
                docuNumber={5}
              />
            </FilterProvider>
          </Grid>
        </Grid>
      </PageContentContainer>
    </PageTitle>
  );
});
