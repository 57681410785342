import {createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import React, {FC, useCallback, useEffect, useState} from 'react';
import {AutoSizer, List, ListRowProps} from 'react-virtualized';
import {IDocumentDto} from '../../../api/api';
import {useLayoutStyles} from '../../../app-routes';
import {i18nTranslationKey, localized} from '../../../i18n/i18n';
import {TitleAndSubCard} from '../../../view/components/default-components/title-and-sub-card';
import {BasicCircularProgress} from '../basic-circular-progress';
import {BasicNoDocuments} from '../basic-no-documents';
import {useDocumentFilter} from '../filterprovider';
import {BasicDocumentList} from './basic-document-list';
import {SortType} from './basic-sort-type';
import {DocumentListItem} from './document-list-item';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    documentsContainer: {
      maxHeight: '500px',
      overflow: 'hidden',
      marginTop: '10px',
      paddingLeft: 5,
      paddingRight: 5,
      marginLeft: -5,
      marginRight: -5,
    },
    headerDate: {
      textAlign: 'center',
    },
    spaceBetweenTitleAndData: {
      marginBottom: 20,
    },
  }),
);

export interface DocumentCardTableItem extends IDocumentDto {
  rightHeader?: any;
}

interface PropsFromParent {
  title: string;
  parentDocuments: DocumentCardTableItem[];
  pending?: boolean;
  dateFrom?: Date;
  dateTo?: Date;
  status?: boolean;
  cardHeight?: number;
  docuHeight?: number;
  docuNumber?: number;
  rightHeader?: keyof i18nTranslationKey;
}

export const DocumentCard: FC<PropsFromParent> = React.memo(
  ({title, parentDocuments, pending, dateFrom, dateTo, status, cardHeight, docuHeight: documentElementHeight, docuNumber: elementsNumber, rightHeader}) => {
    const classes = useStyles();
    const layout = useLayoutStyles();
    const [filteredDocuments, setFilteredDocuments] = useState<DocumentCardTableItem[]>(parentDocuments);
    const [documents, setDocuments] = useState<DocumentCardTableItem[]>(filteredDocuments);
    const {sortProp} = useDocumentFilter();
    const documentsListHeight = cardHeight || (elementsNumber || 5) * (documentElementHeight || 40);

    // Item must have key & style to handle scroll
    const rowRenderer = useCallback(
      (props: ListRowProps) => {
        return (
          <div key={props.key} style={props.style}>
            <DocumentListItem documents={documents[props.index]} />
          </div>
        );
      },
      [documents],
    );

    useEffect(() => {
      if (dateFrom === undefined || dateTo === undefined) {
        setFilteredDocuments(parentDocuments);
      } else {
        setFilteredDocuments(
          parentDocuments &&
            parentDocuments.filter(
              (doc) => doc.date.valueOf()! >= dateFrom?.valueOf()! && doc.date.valueOf()! <= dateTo?.valueOf()!,
            ),
        );
      }
    }, [parentDocuments, dateFrom, dateTo]);

    useEffect(() => {
      if (sortProp === 'date') {
        setDocuments(filteredDocuments && [...filteredDocuments].sort((a, b) => b.date.valueOf()! - a.date.valueOf()!));
      } else {
        setDocuments(filteredDocuments && [...filteredDocuments].sort((a, b) => a.name!.localeCompare(b.name!)));
      }
    }, [sortProp, filteredDocuments]);

    const buildList = useCallback((sizes: {width: number, height: number}) => {
      return (
        <List
          width={sizes.width}
          height={sizes.height}
          rowCount={documents?.length}
          rowHeight={documentElementHeight || 45}
          rowRenderer={rowRenderer} />
      );
    }, [documents, rowRenderer, documentElementHeight])

    return (
      <BasicDocumentList>
        <Grid
          className={classes.spaceBetweenTitleAndData}
          container
          direction="row"
          justify="space-between"
          alignItems="center">
          <TitleAndSubCard title={title} />
          <SortType />
        </Grid>
        {pending ? (
          <BasicCircularProgress />
        ) : (
          <>
            {documents === undefined || documents!.length === 0 ? (
              <BasicNoDocuments />
            ) : (
              <>
                <Grid
                  className={layout.desktopAndLargeMobile}
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="center">
                  <Grid item xs={6}>
                    <Typography>{localized('Document')} </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography>{localized('Date')} </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>{rightHeader ? rightHeader : ''} </Typography>{' '}
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
                <div className={classes.documentsContainer} style={{height: documentsListHeight}}>
                  <AutoSizer>
                    {buildList}
                  </AutoSizer>
                </div>
              </>
            )}
          </>
        )}
      </BasicDocumentList>
    );
  },
);

