import {useRouteMatch, Switch, Route} from 'react-router-dom';
import {EnergyConsumption} from './energy-consumption';
import React, {FC} from 'react';

export const EnergyConsumptionRoute: FC = React.memo(() => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path}>
        <EnergyConsumption />
      </Route>
    </Switch>
  );
});
