import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import React, {FC} from 'react';
import {MarketingMaterialCategories} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {MaterialFilterButton} from './material-filter-button';

interface PropsFromParent {
  handleFilterChange: (category: MarketingMaterialCategories) => void;
  selectedCategory: MarketingMaterialCategories;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    responseiveBox: {
      marginLeft: '11px ',
      transition: 'color 0.1s ease-in-out, background-color 0.1s ease-in-out',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
      [theme.breakpoints.down('xs')]: {
        margin: '0 -5px 0 -5px ',
      },
    },
  }),
);
export const MaterialFilterMenu: FC<PropsFromParent> = React.memo(({handleFilterChange, selectedCategory}) => {
  const classes = useStyles();
  return (
    <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} className={classes.responseiveBox}>
      <MaterialFilterButton
        MaterialCategory={MarketingMaterialCategories.WindPower}
        btnText={localized('WindPower')}
        selectedCategory={selectedCategory}
        handleFilterChange={handleFilterChange}
      />
      <MaterialFilterButton
        MaterialCategory={MarketingMaterialCategories.ClimateAction}
        btnText={localized('ClimateAction')}
        selectedCategory={selectedCategory}
        handleFilterChange={handleFilterChange}
      />
      <MaterialFilterButton
        MaterialCategory={MarketingMaterialCategories.ClimateForest}
        btnText={localized('CLIMATEForest')}
        selectedCategory={selectedCategory}
        handleFilterChange={handleFilterChange}
      />
      <MaterialFilterButton
        MaterialCategory={MarketingMaterialCategories.Co2Mapping}
        btnText={localized('CO2mapping')}
        selectedCategory={selectedCategory}
        handleFilterChange={handleFilterChange}
      />
      <MaterialFilterButton
        MaterialCategory={MarketingMaterialCategories.EnergyConsulting}
        btnText={localized('EnergyConsulting')}
        selectedCategory={selectedCategory}
        handleFilterChange={handleFilterChange}
      />
    </Box>
  );
});
