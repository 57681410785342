import {Button, createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {INews} from '../../../api/api';
import {localized} from '../../../i18n/i18n';
import {GetSingleBlog} from '../../../state/features/news/operation';
import {AppState} from '../../../state/store';
import {arrowColor, primaryTextColor, secondaryColor, whiteTextColor} from '../../../styles/color-constants';
import {GridWithCustomBreakpoints} from '../../../styles/custom-grid-breakpoints';
import {h5} from '../../../styles/font-size-constants';
import {hubspotVideoURL} from '../../../utilities/constants';
import {replaceVideoTextWithIFrame} from '../../../utilities/platform-helpers/html-video-helper';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {DashboardFilesCardFooter} from '../dashboard/dashboard-files-card-footer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridWithMaxWidth: {
      maxWidth: 1200,
      width: 'calc(100% - 64px )',
      '& img': {maxWidth: '100% !important'},
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      paddingRight: 10,

      marginRight: 10,
      [theme.breakpoints.up('md')]: {
        marginLeft: 6,
        paddingLeft: 10,
      },
    },
    root: {
      minWidth: '110px',
      paddingRight: '20px',
      minHeight: '32px',
      borderRadius: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: whiteTextColor,
      marginRight: '5px',
      color: primaryTextColor,
      boxShadow: '3px 3px 10px #838D9B33',
      border: '1px solid #E0E0E0',
      fontSize: h5,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
      '&.Mui-disabled': {
        backgroundColor: secondaryColor,
        color: '#FFF',
      },
    },
    arrow: {
      opacity: 1,
      color: arrowColor,
      '&:hover': {
        color: primaryTextColor,
      },
    },
    containerClassName: {
      maxWidth: 'calc(100% - 64px )',
    },
    contentContainer: {
      [theme.breakpoints.up('lg')]: {
        paddingRight: 10,
        paddingLeft: 10,
      },
    },
    iFrameStyle: {
      position: 'absolute',
      width: '100% !important',
      height: '100% !important',
      border: '0 none',
    },
  }),
);

export const SingleNewsContentPage = React.memo(() => {
  const history = useHistory();
  const classes = useStyles();
  const searchParam = history.location.pathname;
  const newsItems = useSelector((state: AppState) => state.newsReducer.newsItems);
  const [newsItemToUse, setNewsItemToUse] = useState<INews | undefined>(
    newsItems.find((nt) => nt.id && searchParam.includes(nt.id)),
  );
  const dispatch = useDispatch();

  const iFrameVideoBuilder = useCallback((playerId: number) => {
    return (
      <div style={{maxWidth: '1920px'}}>
        <div style={{position: 'relative', height: 0, paddingBottom: '56.25%'}}>
          <iframe
            referrerPolicy="origin"
            sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
            className={classes.iFrameStyle}
            allow="autoplay; fullscreen;"
            title="HubSpot Video"
            src={hubspotVideoURL + playerId}></iframe>
        </div>
      </div>
    );
  }, []);

  const newsItemWithParsedVideo: INews | undefined = useMemo(() => {
    if (!newsItemToUse || !newsItemToUse.body) return undefined;

    const newsItemBody = newsItemToUse.body;

    const newBody = replaceVideoTextWithIFrame(newsItemBody, iFrameVideoBuilder);
    return {
      ...(newsItemToUse as INews),
      body: newBody,
    };
  }, [newsItemToUse]);

  useEffect(() => {
    if (newsItemToUse === undefined) {
      dispatch(GetSingleBlog(searchParam.slice(1)));
    }
  }, [dispatch, newsItemToUse, searchParam]);

  const goBack = useCallback(() => {
    if (history.action === 'POP') {
      window.close();
    } else {
      history.goBack();
    }
  }, [history]);

  useEffect(() => {
    if (!newsItemToUse) {
      setNewsItemToUse(newsItems.find((nt) => nt.id && searchParam.includes(nt.id)));
    }
  }, [newsItems, newsItemToUse, searchParam, setNewsItemToUse]);

  return (
    <Grid className={classes.containerClassName} container direction="row">
      <GridWithCustomBreakpoints className={classes.gridWithMaxWidth} item xs={12} sm={12} md={12} betweenlgandxl={9}>
        <div className={classes.headerContainer}>
          <Button className={classes.root} onClick={goBack}>
            <KeyboardArrowLeftOutlinedIcon className={classes.arrow} />
            {localized('Back')}
          </Button>
          <DashboardFilesCardFooter
            minWidth={287}
            category={
              newsItemWithParsedVideo && newsItemWithParsedVideo.category !== undefined
                ? newsItemWithParsedVideo.category.toString()
                : ''
            }
            date={
              newsItemWithParsedVideo && newsItemWithParsedVideo.publishDate
                ? newsItemWithParsedVideo.publishDate
                : undefined
            }
            author={
              newsItemWithParsedVideo && newsItemWithParsedVideo.authorName ? newsItemWithParsedVideo.authorName : ''
            }
          />
        </div>
        <BasicCard>
          <div className={classes.contentContainer}>
            {newsItemWithParsedVideo && newsItemWithParsedVideo.headerImageSrc && (
              <img src={newsItemWithParsedVideo.headerImageSrc} alt="GreenEnergy" />
            )}
            {newsItemWithParsedVideo && <div dangerouslySetInnerHTML={setInnerHtml(newsItemWithParsedVideo)}></div>}
          </div>
        </BasicCard>
      </GridWithCustomBreakpoints>
    </Grid>
  );
});

function setInnerHtml(newsToUse: INews) {
  return {__html: newsToUse.body ? newsToUse.body : ''};
}
