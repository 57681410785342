import * as msal from '@azure/msal-browser';
import {localized} from '../../i18n/i18n';
import {decodeToken} from '../../utilities/platform-helpers/auth-helper';
import {B2CConfig, TOKEN_ROLE_IDENTIFIER, TOKEN_STORAGE_KEY} from './../auth-constants';
import { Monitor } from '../../services/telemetry-service';
declare const window: any;

// AD b2c specific configs
const authConfig: msal.Configuration = {
  auth: {
    authority: `https://${B2CConfig.Tenant}.b2clogin.com/tfp/${B2CConfig.TenantUrl}/${B2CConfig.SignInPolicy}`,
    clientId: B2CConfig.ClientId,
    knownAuthorities: [`https://${B2CConfig.Tenant}.b2clogin.com/tfp/${B2CConfig.TenantUrl}/${B2CConfig.SignInPolicy}`],
    redirectUri: B2CConfig.RedirectUri,
  },
  cache: {
    cacheLocation: B2CConfig.CacheLocation,
    storeAuthStateInCookie: false,
  },
};
const authParameters = {
  scopes: ['openid', B2CConfig.Scope],
};

let accountId = '';
// General auth helper functions

export const initAuthSettings = async (setWaitForResponse: (waitForResponse: boolean) => void) => {
  const msalPublicClient: msal.IPublicClientApplication = await new msal.PublicClientApplication(authConfig);
  const handleResponse = (resp: any) => {
    if (resp !== null) {
      accountId = resp.account.homeAccountId;
    } else {
      /**
       * See here for more info on account retrieval:
       * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
       */
      const currentAccounts = msalPublicClient.getAllAccounts();
      if (currentAccounts === null) {
        return;
      } else if (currentAccounts.length > 1) {
        accountId = currentAccounts[0].homeAccountId;
        console.log('Multiple accounts detected.');
      } else if (currentAccounts.length === 1) {
        accountId = currentAccounts[0].homeAccountId;
      }
    }
    setWaitForResponse(true);
  };
  msalPublicClient
    .handleRedirectPromise()
    .then(handleResponse)
    .catch((err: msal.BrowserAuthError) => {
      // If error is password reset redirect to password reset policy
      if (err.errorMessage.includes('AADB2C90118')) {
        let passwordResetRequest: msal.RedirectRequest = {
          scopes: authParameters.scopes,
          authority: `https://${B2CConfig.Tenant}.b2clogin.com/tfp/${B2CConfig.TenantUrl}/${B2CConfig.PasswordResetPolicy}`,
        }
        msalPublicClient.loginRedirect(passwordResetRequest);
        // Error is user returning from aborted password redirect, redirect to login page
      } else if (err.errorMessage.includes('AADB2C90091')) {
        msalPublicClient.loginRedirect();
      } else {
        // Application insights logging in case of unhandled error
        Monitor.logException(err);
        // Console logging
        console.error(err);
      }
    });
  return msalPublicClient;
};
export async function clientIsAuthenticated(authClient: any) {
  return accountId ? true : false;
}

export async function clientGetUser(authClient: any) {
  let token = localStorage.getItem('token');
  if (token) {
    let jwtToken = decodeToken(token);
    let user: any = {
      name: jwtToken.name,
      role: localized(jwtToken[TOKEN_ROLE_IDENTIFIER]),
    };
    return user;
  }
  return {} as any;
}

export async function clientLoginWithPopup(authClient: any, params: any) {
  const msalClient = authClient as msal.IPublicClientApplication;
  await msalClient.loginPopup(params);
}
export const clientLoginWithRedirect = async (authClient: any, params: any) => {
  const msalClient = authClient as msal.IPublicClientApplication;
  try {
    msalClient.loginRedirect(authParameters);
  } catch (err) {
    // handle error
  }
};
// msal handles redirect
export async function clientHandleRedirectCallback(authClient: any) {}

// Get token silently
export const clientGetTokenSilently = async (authClient: any, params: any) => {
  const msalClient = authClient as msal.IPublicClientApplication;
  let currentAccount = msalClient.getAccountByHomeId(accountId);

  let redirectRequest: msal.RedirectRequest = {
    scopes: authParameters.scopes,
  };

  let silentRequest: msal.SilentRequest = {
    scopes: [B2CConfig.Scope],
    account: currentAccount!,
    forceRefresh: true,
  };
  return msalClient
    .acquireTokenSilent(silentRequest)
    .then((response) => {
      if (response.accessToken && response.accessToken === '') {
        throw new msal.InteractionRequiredAuthError();
      }
      return response.accessToken;
    })
    .catch((error: any) => {
      console.log('silent token acquisition fails. acquiring token using redirect');
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return msalClient.acquireTokenRedirect(redirectRequest);
      } else if (error instanceof msal.BrowserAuthError) {
        return msalClient.acquireTokenRedirect(redirectRequest);
      } else {
        console.log('unhandled auth error', error);
      }
    });
};
// Extracts userole from token
export function getUserRole(tokenId: string = 'token') {
  let token = localStorage.getItem(tokenId);

  if (token) {
    let jwtToken = decodeToken(token);

    if (jwtToken[TOKEN_ROLE_IDENTIFIER]) {
      return jwtToken[TOKEN_ROLE_IDENTIFIER];
    } else {
      return undefined;
    }
  }
  return '';
}
// Starts the logout process.
export const clientLogout = (authClient: any, params: any) => {
  const msalClient = authClient as msal.IPublicClientApplication;
  localStorage.removeItem(TOKEN_STORAGE_KEY);
  msalClient.logout();
};
