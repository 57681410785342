import {Box, createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import React, {FC} from 'react';
import {useDispatch} from 'react-redux';
import {FileResponse} from '../../../api/api';
import {useLayoutStyles} from '../../../app-routes';
import DocumentIcon from '../../../assets/icons/document.png';
import {localized} from '../../../i18n/i18n';
import {getContractPDF} from '../../../state/features/contract/operation';
import {showErrorSnackbar} from '../../../state/features/snackbar/snackbar-slice';
import {arrowColor, primaryTextColor} from '../../../styles/color-constants';
import {promptUserWithFileOptions} from '../../../utilities/file-helper';
import {saveExccelAs} from '../../../utilities/helpers/general-helpers';
import {formatToLocalDateAndLeadingZeros} from '../../../utilities/platform-helpers/date-helpers';
import {DocumentCardTableItem} from './document-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    downloadIcon: {
      opacity: 1,
      cursor: 'pointer',
      color: arrowColor,
      '&:hover': {
        color: primaryTextColor,
      },
    },
    downloadIconContainer: {
      height: '24px',
      marginLeft: 10,
      marginRight: 15,
    },
    documentIcon: {
      width: '21px',
      height: '26px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
    },
    documentIconContainer: {
      height: '26px',
      paddingLeft: '5px',
    },
    alignTextBoldTextCenter: {
      fontWeight: 800,
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& div:first-child': {
        marginRight: 20,
        marginLeft: 10,
      },
    },
    paddingLeftFive: {},
    documentContainer: {
      marginTop: '10px',
      boxShadow: 'rgb(131 141 155 / 20%) 3px 3px 10px',
      paddingTop: '5px',
      paddingBottom: '5px',
      borderRadius: 10,
    },
    ellipsis: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  }),
);

interface PropsFromParent {
  documents: DocumentCardTableItem;
}
export const DocumentListItem: FC<PropsFromParent> = React.memo(({documents}) => {
  const classes = useStyles();
  const layout = useLayoutStyles();
  const dispatch = useDispatch();

  const downloadDocument = async () => {
    try {
      let file: FileResponse | null;

      if (documents.id) {
        file = await getContractPDF(documents.id);
        if (file) {
          let fileName = getDocumentName(documents, file);

          if (file.data.type === 'application/octet-stream') {
            saveExccelAs(file, fileName + '.xlsx');
          } else {
            promptUserWithFileOptions(file, fileName);
          }
        }
      } else throw new Error('No document id');
    } catch (e) {
      dispatch(showErrorSnackbar('Error while downloading document'));
    }
  };

  const showInfoHorizontal = () => {
    return (
      <Grid
        className={layout.desktopAndLargeMobile}
        container
        direction="row"
        justify="space-between"
        alignItems="center">
        <Grid item xs={6}>
          <div className={classes.flexRow}>
            <div className={classes.documentIconContainer}>
              <img src={DocumentIcon} className={classes.documentIcon} alt={'Document-icon-black'} />
            </div>
            <Typography className={classes.ellipsis}>{documents.name}</Typography>
          </div>
        </Grid>
        <Grid item xs={3}>
          <Typography>{documents.date && formatToLocalDateAndLeadingZeros(documents.date)}</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.alignTextBoldTextCenter}>
            {documents.rightHeader ? documents.rightHeader : ''}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <div onClick={downloadDocument} className={classes.downloadIconContainer}>
            <GetAppIcon className={classes.downloadIcon} />
          </div>
        </Grid>
      </Grid>
    );
  };
  const showInfoVertical = () => {
    return (
      <Grid className={layout.smallMobileOnly} container direction="row" justify="space-between" alignItems="center">
        <Grid item xs={2}>
          <img src={DocumentIcon} className={classes.documentIcon} alt={'Document-icon-black'} />
        </Grid>
        <Grid item xs={8}>
          <Typography>{documents.name}</Typography>
          <Typography>
            {localized('Date')}: {documents.date && formatToLocalDateAndLeadingZeros(documents.date)}
          </Typography>
          <Typography className={classes.alignTextBoldTextCenter}>
            {localized('Amount')}:{' '}
            {documents.id.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <div onClick={downloadDocument} className={classes.downloadIconContainer}>
            <GetAppIcon className={classes.downloadIcon} />
          </div>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box className={classes.documentContainer}>
      {showInfoVertical()}
      {showInfoHorizontal()}
    </Box>
  );
});

function getDocumentName(documents: DocumentCardTableItem, file: FileResponse): string {
  let fileName;
  if (documents.name !== undefined && documents.name.length !== 0) {
    fileName = documents.name;
  } else if (file.fileName !== undefined && file.fileName.length !== 0) {
    fileName = file.fileName;
  } else {
    fileName = 'ScanEnergiDocument' + new Date().getTime();
  }

  return fileName;
}
