import ReactDOMServer from "react-dom/server";

const videoRegex = /\{% video_player.*%\}/g;
    
const playerIdRegex = /player_id='[0-9]+'/g;

const number_regex = /[0-9]+/g;

  export const replaceVideoTextWithIFrame = (text: string, iFrameFn: (playerIdNumber: number) => JSX.Element) => {
    return text.replaceAll(videoRegex, (text) => {
      const playerIdTextString = text.match(playerIdRegex);
      if (playerIdTextString == null) return '';

      const playerIdString = playerIdTextString[0].match(number_regex);
      const playerIdNumber = Number(playerIdString);

      const iFrameElement = iFrameFn(playerIdNumber);
      const iFrameElementHTML = ReactDOMServer.renderToStaticMarkup(iFrameElement);
      return iFrameElementHTML;
    });
  };