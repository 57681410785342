import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import React, {FC} from 'react';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstLayerCard: {
      maxWidth: 'calc(100vw - 64px )',
    },
    minWidth: {
      minWidth: '450px',
    },
  }),
);

export const BasicDocumentList: FC = React.memo(({children}) => {
  const classes = useStyles();

  return (
    <BasicCard className={classes.firstLayerCard} overflow={'auto'}>
      <Box > {children}</Box>
    </BasicCard>
  );
});
