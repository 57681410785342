import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import clsx from 'clsx';
import React, {FC} from 'react';
import {basicCardBgColor, basicCardBoxShadow, primaryColor, primaryTextColor} from '../../../styles/color-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    documentCardItem: {
      backgroundColor: (props: PropsFromParent) => (props.selected ? primaryColor : basicCardBgColor),
      color: (props: PropsFromParent) => (props.selected ? basicCardBgColor : primaryTextColor),
      boxShadow: basicCardBoxShadow,
      borderRadius: '10px',
      position: 'relative',
      minHeight: '40px',
      margin: '10px 0px',
      padding: '5px 0px',
    },
    cursor: {
      cursor: 'pointer',
    },
  }),
);

interface PropsFromParent {
  selected?: boolean;
  contractPage?: boolean;
}

export const BasicDocumentListItem: FC<PropsFromParent> = React.memo(({children, ...props}) => {
  const classes = useStyles(props);
  return <Box className={clsx(classes.documentCardItem, props.contractPage && classes.cursor)}>{children}</Box>;
});
