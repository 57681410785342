import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getOrganizationsByUserId } from './operation';
import { OrganizationState } from './types';

const initialState: OrganizationState = {
  organization: undefined,
  organizationId: undefined,
  pending: false,
  isSuccess: false,
  userOrganizations: [],
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    // TODO: This might need to trigger a clear data, in all other slice reducers
    setSelectedOrganization: (state, action: PayloadAction<SelectOrganization>) => {
      state.organization = state.userOrganizations.find((org) => org.organizationId === action.payload.organizationId);
      state.organizationId = action.payload.organizationId;
    },
  },
  extraReducers: (builder) => {
    // Get user organizations
    builder.addCase(getOrganizationsByUserId.fulfilled, (state, action) => {
      state.pending = false;

      if (action.payload && action.payload.length !== 0) {
        state.userOrganizations = action.payload;
        setSelectedOrganization({ previousOrganizationId: undefined, organizationId: action.payload[0].organizationId });
      }
    });

    builder.addCase(getOrganizationsByUserId.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getOrganizationsByUserId.rejected, (state, action) => {
      state.pending = false;
    });
  },
});

// eslint-disable-next-line
export const { setSelectedOrganization } = organizationSlice.actions;
export interface SelectOrganization {
  previousOrganizationId: number | undefined;
  organizationId: number | undefined;
}
export default organizationSlice.reducer;
