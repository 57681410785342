import {Box, createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import React, {FC, useMemo} from 'react';
import {MarketingMaterialCategories} from '../../../api/api';
import {ReactComponent as Banner_black} from '../../../assets/icons/marketing-material/Banner_black.svg';
import {ReactComponent as CSR_black} from '../../../assets/icons/marketing-material/CSR_black.svg';
import {ReactComponent as Diploma_black} from '../../../assets/icons/marketing-material/Diploma_black.svg';
import {ReactComponent as SocialMedia_black} from '../../../assets/icons/marketing-material/SocialMedia_black.svg';
import {MarketingMaterialPageData} from '../../../state/features/marketing-material/types';
import {BigMaterialCard} from './big-material-card';
import {SmallMaterialCard} from './small-material-card';

interface PropsFromParent {
  selectedMarketingMaterials: MarketingMaterialPageData;
  selectedCategory: MarketingMaterialCategories;
  organizationId: number;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Grid: {
      [theme.breakpoints.up('xs')]: {
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr)',
        gridTemplateColums: 'repeat(auto-fit, 1fr)',
        gridTemplateAreas: '"Banners" "Diploma" "CSRTexts" "SoMeTexts"',
      },
      [theme.breakpoints.up('m')]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gridTemplateRows: 'repeat(auto-fit, 1fr)',
        gridTemplateAreas: `
        "Banners Diploma"
        "Banners CSRTexts"
        "SoMeTexts empty"`,
      },
      [theme.breakpoints.up('lg')]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        gridTemplateRows: 'repeat(2, minmax(0, 1fr))',
        gridTemplateAreas: `
        "Banners Diploma SoMeTexts"
        "Banners CSRTexts empty"`,
      },
      [theme.breakpoints.up('betweenlgandxl')]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
        gridTemplateRows: 'repeat(2, minmax(0, 1fr))',
        gridTemplateAreas: `
        "Banners Diploma SoMeTexts"
        "Banners CSRTexts empty"`,
      },
    },
    GridWithoutBannerAndDiploma: {
      [theme.breakpoints.up('xs')]: {
        display: 'grid',
        gridTemplateColumns: 'minmax(0, 1fr)',
        gridTemplateColums: 'repeat(auto-fit, 1fr)',
        gridTemplateAreas: '"CSRTexts" "SoMeTexts"',
      },
      [theme.breakpoints.up('m')]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gridTemplateRows: 'repeat(auto-fit, 1fr)',
        gridTemplateAreas: `
        "CSRTexts SoMeTexts"`,
      },
      [theme.breakpoints.up('lg')]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        gridTemplateRows: 'repeat(auto-fit, 1fr)',
        gridTemplateAreas: `
        "CSRTexts SoMeTexts"`,
      },
      [theme.breakpoints.up('betweenlgandxl')]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
        gridTemplateRows: 'repeat(auto-fit, 1fr)',
        gridTemplateAreas: `
        "CSRTexts SoMeTexts"`,
      },
    },
  }),
);

export const MarketingMaterialGridHolder: FC<PropsFromParent> = React.memo(
  ({selectedCategory, selectedMarketingMaterials, organizationId}) => {
    const classes = useStyles();
    const {banners, diploma, cSRTexts, soMeTexts} = selectedMarketingMaterials;
    const withoutBannerAndDiploma = useMemo(
      () =>
        selectedCategory === MarketingMaterialCategories.Co2Mapping ||
        selectedCategory === MarketingMaterialCategories.EnergyConsulting,
      [selectedCategory],
    );

    return (
      <Grid className={withoutBannerAndDiploma ? classes.GridWithoutBannerAndDiploma : classes.Grid}>
        {!withoutBannerAndDiploma && (
          <>
            <Box display={'flex'} style={{gridArea: 'Banners'}}>
              <BigMaterialCard
                access={banners.access}
                title={banners.title}
                description={banners.description}
                wideImgSrc={banners.wideImgSrc}
                wideDownLoadLinks={banners.wideDownLoadLinks}
                squareImgSrc={banners.squareImgSrc}
                squareDownLoadLinks={banners.squareDownLoadLinks}
                bannerIcon={Banner_black}
                isNewToUser={banners.isNewToUser}
                organizationId={organizationId}
                activeCategory={selectedCategory}
              />
            </Box>
            <Box display={'flex'} style={{gridArea: 'Diploma'}}>
              <SmallMaterialCard
                access={diploma.access}
                title={diploma.title}
                description={diploma.description}
                downLoadLinks={diploma.downLoadLinks}
                bannerIcon={Diploma_black}
                isNewToUser={diploma.isNewToUser}
                organizationId={organizationId}
                activeCategory={selectedCategory}
              />
            </Box>
          </>
        )}
        <Box display={'flex'} style={{gridArea: 'SoMeTexts'}}>
          <SmallMaterialCard
            access={soMeTexts.access}
            title={soMeTexts.title}
            description={soMeTexts.description}
            downLoadLinks={soMeTexts.downLoadLinks}
            bannerIcon={SocialMedia_black}
            isNewToUser={soMeTexts.isNewToUser}
            organizationId={organizationId}
            activeCategory={selectedCategory}
          />
        </Box>
        <Box display={'flex'} style={{gridArea: 'CSRTexts'}}>
          <SmallMaterialCard
            access={cSRTexts.access}
            title={cSRTexts.title}
            description={cSRTexts.description}
            downLoadLinks={cSRTexts.downLoadLinks}
            bannerIcon={CSR_black}
            isNewToUser={cSRTexts.isNewToUser}
            organizationId={organizationId}
            activeCategory={selectedCategory}
          />
        </Box>
      </Grid>
    );
  },
);
