import { DebtorPostingDto } from "../../../api/api";
import { localized } from "../../../i18n/i18n";

export const getMonthAccumulated = (debtorPosting: DebtorPostingDto[]) => {
  let monthAccumulated = 0;
  const thisMonth = new Date().getMonth()
  const thisYear = new Date().getFullYear()
  if (debtorPosting) {
    debtorPosting
      .filter((d) => d.date?.getMonth() === thisMonth && d.date?.getFullYear() === thisYear)
      .map((d) => (monthAccumulated += d.debet! + d.credit!));
  }
  return monthAccumulated;
};

export const monthNames = [
  localized('January'),
  localized('February'),
  localized('March'),
  localized('April'),
  localized('May'),
  localized('June'),
  localized('July'),
  localized('August'),
  localized('September'),
  localized('October'),
  localized('November'),
  localized('December'),
];
