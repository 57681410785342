import {createStyles, makeStyles, Theme} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {useGA4React} from 'ga-4-react';
import React, {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Switch, useLocation} from 'react-router-dom';
import {useAuth} from './auth/general-auth-provider';
import NoPermissionPage from './auth/no-permission-page';
import {getOrganizationsByUserId} from './state/features/organization/operation';
import {SelectOrganization, setSelectedOrganization} from './state/features/organization/organization-slice';
import {getUser} from './state/features/user/operation';
import {routes} from './state/routes';
import {AppState} from './state/store';
import {GoogleAnalyticsKey, OrgIdKey} from './utilities/constants';
import {isTokenExpired} from './utilities/platform-helpers/auth-helper';
import {SingleNewsContentPageRoute} from './view-scan-energi/components/news/single-news-content-route';
import {SupportContact} from './view-scan-energi/components/support-contact';
import {ContactRoute} from './view-scan-energi/pages/contact/contact-route';
import {ContractsRoute} from './view-scan-energi/pages/contracts/contracts-route';
import {DashboardRoute} from './view-scan-energi/pages/dashboard/dashboard-route';
import {DocumentsRoute} from './view-scan-energi/pages/documents/documents-route';
import {EbooksRoute} from './view-scan-energi/pages/e-books/e-books-route';
import {EconomyRoute} from './view-scan-energi/pages/economy/economy-route';
import {EnergyConsumptionRoute} from './view-scan-energi/pages/energy-consumption/energy-consumption-route';
import {GreenOptimizationRoute} from './view-scan-energi/pages/green-optimization/green-optimization-route';
import {MarketingMaterialRoute} from './view-scan-energi/pages/marketing-material/marketing-material-route';
import {NewsRoute} from './view-scan-energi/pages/news/news-route';
import {UserInfoRoute} from './view-scan-energi/pages/user-info/user-info-route';
import {OverlaySpinner} from './view/components/default-components/spinner/overlay-spinner';
import AppDrawer from './view/navigation/app/app-drawer';
import AppHeaderBar from './view/navigation/app/header-bar';
import {HeaderBar} from './view/navigation/desktop/header-bar';
import MenuDrawer from './view/navigation/desktop/menu-drawer';

export const useLayoutStyles = makeStyles((theme: Theme) =>
  createStyles({
    mobileOnly: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    desktopOnly: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    desktopAndLargeMobile: {
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    smallMobileOnly: {
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    bodyContent: {
      flex: 1,
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
      },
    },
    appContent: {
      overflowX: 'auto',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(8),
      },
    },
  }),
);

export const AppRoutes: FC = React.memo(() => {
  const classes = useLayoutStyles();
  const token = useSelector((store: AppState) => store.authReducer.token);
  const {role} = useAuth();
  const organizations = useSelector((state: AppState) => state.organizationReducer.userOrganizations);
  const ga = useGA4React(GoogleAnalyticsKey);
  const location = useLocation();
  const dispatch = useDispatch();

  // Log page view to GA
  useEffect(() => {
    if (ga) {
      ga.pageview(location.pathname + location.search);
    }
  }, [ga, location]);

  // Get organizations and user after token changes
  useEffect(() => {
    if (token) {
      dispatch(getOrganizationsByUserId());
      dispatch(getUser());
    }
  }, [dispatch, token]);

  // Select org id and update local storage
  useEffect(() => {
    if (organizations.length > 0) {
      const id = localStorage.getItem(OrgIdKey);
      let idNumber = id ? +id : 0;

      // Set first from list if number from local storage isn't in list
      if (organizations.findIndex((org) => org.organizationId === idNumber) === -1) {
        idNumber = organizations[0].organizationId;
      }

      const payload: SelectOrganization = {
        organizationId: idNumber,
        previousOrganizationId: undefined,
      };

      dispatch(setSelectedOrganization(payload));

      if (id && id.length > 0) {
        localStorage.setItem(OrgIdKey, id.toString());
      } else {
        localStorage.setItem(OrgIdKey, organizations[0].organizationId.toString());
      }
    }
  }, [organizations, dispatch]);

  // Only show page if user is authenticated and has a role
  if (!token || isTokenExpired('token') || !role) {
    return null;
  } else if (role === undefined) {
    return <NoPermissionPage />;
  }

  return (
    <div>
      <div className={classes.appContent}>
        <AppHeaderBar />
        <HeaderBar />

        <Grid wrap="nowrap" container direction="row" justify="flex-start">
          <OverlaySpinner />
          <MenuDrawer />

          <div className={classes.bodyContent}>
            <Switch>
              <Route exact path={routes.dashboard} component={DashboardRoute}></Route>
              <Route path={routes.economy} component={EconomyRoute}></Route>
              <Route path={routes.sustainableInitiatives} component={GreenOptimizationRoute}></Route>
              <Route path={routes.energyConsumption} component={EnergyConsumptionRoute}></Route>
              <Route path={routes.contracts} component={ContractsRoute}></Route>
              <Route path={routes.documents} component={DocumentsRoute}></Route>
              <Route path={routes.singleNewsItem} component={SingleNewsContentPageRoute}></Route>
              <Route path={routes.news} component={NewsRoute}></Route>
              <Route path={routes.eBooks} component={EbooksRoute}></Route>
              <Route path={routes.contact} component={ContactRoute}></Route>
              <Route path={routes.userInfo} component={UserInfoRoute}></Route>
              <Route path={routes.marketingMaterial} component={MarketingMaterialRoute}></Route>
            </Switch>
          </div>
        </Grid>
        {location.pathname === routes.contact ? <div style={{height: 50}} /> : <SupportContact />}
        <AppDrawer />
      </div>
    </div>
  );
});
