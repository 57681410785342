import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core/';
import {GetApp} from '@material-ui/icons';

import React, {FC, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {downloadMarketingMaterial} from '../../../state/features/marketing-material/operation';
import {showErrorSnackbar} from '../../../state/features/snackbar/snackbar-slice';
import {disabledColor} from '../../../styles/color-constants';
import {promptUserWithFileOptions} from '../../../utilities/file-helper';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';

interface PropsFromParent {
  name?: string;
  id: number;
  disabled?: boolean;
  type?: string;
  organizationId: number;

  setParentImgSrc?: (src: string) => void;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    availablestyle: {
      cursor: 'pointer',
      maxHeight: 40,
      display: 'flex',
      alignItems: 'center',
      margin: 10,
      marginBottom: 0,
      justifyContent: 'space-between',
      flexGrow: 1,
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
        flexGrow: 1,
      },
    },
    disabledstyle: {
      color: disabledColor,
      cursor: 'default',
      maxHeight: 40,
      display: 'flex',
      alignItems: 'center',
      margin: 10,
      marginBottom: 0,
      justifyContent: 'space-around',
      flexGrow: 1,
    },
    TypeText: {},
    NameText: {
      flex: 100,
      font: 'normal normal normal 14px/16px lato',
    },
  }),
);

const convertBlobToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });

export const DownloadFileButton: FC<PropsFromParent> = React.memo((props) => {
  const [disabled, setDisabled] = React.useState(props.disabled);
  const classes = useStyles();
  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  const dispatch = useDispatch();
  const {disabled: Disabled, organizationId, setParentImgSrc, type} = props;

  useEffect(() => {
    async function fetchImage() {
      if (!Disabled && organizationId && setParentImgSrc !== undefined && (type === 'JPG' || type === 'PNG')) {
        try {
          let file = await downloadMarketingMaterial([props.id], organizationId);
          if (file) {
            let data = await convertBlobToBase64(file.data);
            setParentImgSrc(data);
          }
        } catch (e) {
          dispatch(showErrorSnackbar('File not found...'));
        }
      }
    }
    fetchImage();
  }, [setParentImgSrc, props.id, Disabled, organizationId, type, dispatch]);

  const handleDownload = async () => {
    if (disabled) return;

    setDisabled(true);
    if (props.id) {
      try {
        let file = await downloadMarketingMaterial([props.id], props.organizationId);
        if (file) {
          promptUserWithFileOptions(file, file.fileName ?? props.type ?? 'file');
        }
      } catch (e) {
        dispatch(showErrorSnackbar('File not found...'));
      }
    }
    setDisabled(false);
  };

  return (
    <BasicCard className={disabled ? classes.disabledstyle : classes.availablestyle} onClick={handleDownload}>
      {props.name && (
        <Typography className={classes.NameText} variant="body1">
          {props.name ?? ''}
        </Typography>
      )}
      <Typography className={classes.TypeText} variant="button">
        {props.type ?? ''}
      </Typography>
      {!disabled && <GetApp />}
    </BasicCard>
  );
});
