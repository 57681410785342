import React, {FC, useEffect} from 'react';
import {BrandName} from '../../../../utilities/constants';

export interface PropsFromParent {
  title: string;
  shouldPrefixBrandName?: boolean;
}

export const PageTitle: FC<PropsFromParent> = React.memo((props) => {
  const fullTitle =
    props.shouldPrefixBrandName !== false ? (BrandName ? `${BrandName} - ${props.title}` : props.title) : props.title;

  useEffect(() => {
    document.title = fullTitle;
  }, [fullTitle]);

  return <>{props.children}</>;
});
