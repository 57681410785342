import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import React, {FC} from 'react';

interface PropsFromParent {
  documentNumber: number;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      minWidth: 120,
    },
  }),
);
export const BasicDocumentListItemMapSpacer: FC<PropsFromParent> = React.memo(({documentNumber, children}) => {
  const classes = useStyles();
  return <Box className={classes.spacer}>{children}</Box>;
});
