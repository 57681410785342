import {createSlice} from '@reduxjs/toolkit';
import {ContactDto} from '../../../api/api';
import {ContactsState} from '../contact/types';
import {getContactsDetails, getCustomerServiceInfo} from './operation';

const initialState: ContactsState = {
  contactsSlice: [] as ContactDto[],
  customerServiceInfo: undefined,
  pending: false,
  isSuccess: false,
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get Contacts Details
    builder.addCase(getContactsDetails.fulfilled, (state, action) => {
      state.pending = false;
      state.contactsSlice = action.payload ? action.payload : [];
    });

    builder.addCase(getContactsDetails.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getContactsDetails.rejected, (state, action) => {
      state.pending = false;
    });

    // Get Customer Service Info
    builder.addCase(getCustomerServiceInfo.fulfilled, (state, action) => {
      state.pending = false;
      state.customerServiceInfo = action.payload;
    });

    builder.addCase(getCustomerServiceInfo.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getCustomerServiceInfo.rejected, (state, action) => {
      state.pending = false;
    });
  },
});

// eslint-disable-next-line
export const {} = contactsSlice.actions;

export default contactsSlice.reducer;
