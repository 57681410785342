import {createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import {GetApp} from '@material-ui/icons';
import React, {FC} from 'react';
import {useDispatch} from 'react-redux';
import {localized} from '../../../i18n/i18n';
import {downloadMarketingMaterial} from '../../../state/features/marketing-material/operation';
import {showErrorSnackbar} from '../../../state/features/snackbar/snackbar-slice';
import {disabledColor} from '../../../styles/color-constants';
import {h5} from '../../../styles/font-size-constants';
import {promptUserWithFileOptions} from '../../../utilities/file-helper';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';

interface PropsFromParent {
  name?: string;
  organizationId: number;
  availableIds?: number[];
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    availablestyle: {
      cursor: 'pointer',
      maxHeight: 40,
      gap: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
    },
    disabledstyle: {
      color: disabledColor,
      cursor: 'default',
      maxHeight: 40,
      gap: '10px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      transition: 'flex-grow 500ms ease-in-out',
      '&:hover': {
        backgroundColor: '#3333',
        color: '#FFF',
      },
    },
    ActiveTypo: {
      fontSize: h5,
      fontFamily: 'Lato',
      fontWeight: 'normal',
    },
  }),
);
export const DownloadAllButton: FC<PropsFromParent> = React.memo((PropsFromParent) => {
  const allIds = PropsFromParent.availableIds ? PropsFromParent.availableIds : [];
  const [disabled, setDisabled] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleDownload = async () => {
    if (disabled) return;
    setDisabled(true);
    try {
      let file = await downloadMarketingMaterial(allIds, PropsFromParent.organizationId);
      if (file) {
        if (allIds.length > 1) {
          promptUserWithFileOptions(file, PropsFromParent.name ?? 'AllAvailableFiles');
        } else {
          promptUserWithFileOptions(file, file.fileName ?? PropsFromParent.name ?? 'File');
        }
      }
    } catch (e) {
      dispatch(showErrorSnackbar('Error zipfile...'));
    }
    setDisabled(false);
  };
  return (
    <BasicCard className={disabled ? classes.disabledstyle : classes.availablestyle} onClick={handleDownload}>
      <Typography className={classes.ActiveTypo}>{localized('DownloadAll') + '  '}</Typography>
      <GetApp />
    </BasicCard>
  );
});
