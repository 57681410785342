import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {createSelector} from '@reduxjs/toolkit';
import React, {FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IOrganizationDto} from '../../../api/api';
import {SelectOrganization, setSelectedOrganization} from '../../../state/features/organization/organization-slice';
import {AppState} from '../../../state/store';
import {OrgIdKey} from '../../../utilities/constants';
import {
  BasicDropdown,
  DropdownOption,
} from '../../../view/components/default-components/baisc-dropdown/option-dropdown';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: '70px',
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'space-between',
    },

    organizationDropDown: {
      maxWidth: 260,
      minWidth: 250,
      [theme.breakpoints.down('xs')]: {
        minWidth: 135,
      },

      margin: 0,
      padding: '0px 20px 0px 20px',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
    },
    dropdownContainer: {
      flex: 1,
    },
    inputContainer: {
      margin: 0,
    },
  }),
);

const convertOrganizationToDropdownOption = (organization: IOrganizationDto) => {
  return new DropdownOption(organization.accountName ?? '', organization.organizationId);
};

const OrganizationOptionSelector = createSelector(
  (state: AppState): IOrganizationDto[] => state.organizationReducer.userOrganizations,
  (orgs: IOrganizationDto[]): DropdownOption[] => orgs.map(convertOrganizationToDropdownOption),
);

const OrganizationDropdown: FC = React.memo((props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const options = useSelector(OrganizationOptionSelector);
  const currentOrganization = useSelector((state: AppState) => state.organizationReducer.organization);

  const setOrganization = useCallback(
    (orgOption: DropdownOption) => {
      localStorage.setItem(OrgIdKey, orgOption.value);
      const payload: SelectOrganization = {
        organizationId: orgOption.value,
        previousOrganizationId: currentOrganization?.organizationId,
      };
      dispatch(setSelectedOrganization(payload));
    },
    [currentOrganization, dispatch],
  );

  return (
    <BasicCard className={classes.organizationDropDown}>
      <BasicDropdown
        hideUnderline={true}
        onChange={setOrganization}
        label=""
        items={options}
        value={options && options.find((o) => o.value === currentOrganization?.organizationId)}
        inputClassName={classes.inputContainer}
      />
    </BasicCard>
  );
});

export default OrganizationDropdown;
