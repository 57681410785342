import React, {useContext, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  addNotification,
  resetMarketingMaterialNotifications,
} from '../../state/features/marketing-material/marketing-material-slice';
import {getMarketingMaterials} from '../../state/features/marketing-material/operation';
import {
  MarketingMaterialCategoryPageDatas,
  MarketingMaterialPageData,
} from '../../state/features/marketing-material/types';
import {AppState} from '../../state/store';

export const NotificationContext = React.createContext({} as any);
export const useNoti = () => useContext(NotificationContext);

export const NotificationProvider = ({children, ...initOptions}: any) => {
  const {organizationId} = useSelector((store: AppState) => store.organizationReducer);
  const dispatch = useDispatch();

  const {marketingMaterials} = useSelector((store: AppState) => store.marketingMaterialReducer);
  useEffect(() => {
    if (organizationId) {
      dispatch(getMarketingMaterials({organizationId: organizationId}));
    }
  }, [organizationId, dispatch]);

  useEffect(() => {
    dispatch(resetMarketingMaterialNotifications());
    marketingMaterials.forEach((material: MarketingMaterialCategoryPageDatas) => {
      if (doesMarketingMaterialHaveNotifications(material.MarketingMaterialPageData)) {
        dispatch(addNotification(material.Category));
      }
    });
  }, [marketingMaterials, organizationId, dispatch]);

  return <NotificationContext.Provider value={{}}>{children}</NotificationContext.Provider>;
};

const doesMarketingMaterialHaveNotifications = (marketingMaterialPageData: MarketingMaterialPageData) => {
  return (
    marketingMaterialPageData.banners.isNewToUser ||
    marketingMaterialPageData.cSRTexts.isNewToUser ||
    marketingMaterialPageData.diploma.isNewToUser ||
    marketingMaterialPageData.soMeTexts.isNewToUser
  )
};
