import {useRouteMatch, Switch, Route} from 'react-router-dom';
import {Documents} from './documents';
import React, {FC} from 'react';

export const DocumentsRoute: FC = React.memo(() => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route path={match.path}>
        <Documents />
      </Route>
    </Switch>
  );
});
