import {Box, createStyles, Grid, makeStyles, Theme} from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import clsx from 'clsx';
import React, {FC, useContext} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useLayoutStyles} from '../../../app-routes';
import ngriScanLogo from '../../../assets/NRGiScanGreen.png';
import {useAuth} from '../../../auth/general-auth-provider';
import {localized} from '../../../i18n/i18n';
import {routes} from '../../../state/routes';
import {AppState} from '../../../state/store';
import {primaryColor, secondaryTextColor} from '../../../styles/color-constants';
import {h4} from '../../../styles/font-size-constants';
import {BasicCardButton} from '../../../view-scan-energi/components/basic-card-button';
import OrganizationDropdown from '../../../view-scan-energi/components/organization/organization-dropdown';
import {BasicCard} from '../../components/default-components/basic-card/basic-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    organizationDropDownMargin: {
      marginLeft: '16px',
    },
    organizationHeaderInfo: {
      minWidth: 200,
      justifyContent: 'flex-start',
      whiteSpace: 'nowrap',
      padding: '10px 20px 10px 20px',
    },
    organizationHeaderInfoData: {
      fontSize: '14',
      fontWeight: 'bold',
    },
    user: {
      fontSize: h4,
      color: secondaryTextColor,
      fontWeight: 'bold',
      margin: '10px',
    },
    iconBox: {
      margin: '10px',
    },
    icon: {
      height: '25px',
      width: '25px',
      color: secondaryTextColor,
    },
    iconWhite: {
      height: '25px',
      width: '25px',
      color: '#fff',
    },
    headerLogo: {
      maxWidth: 175,
      [theme.breakpoints.down('xs')]: {
        width: 100,
      },

      cursor: 'pointer',
    },
    cardWidth: {
      width: '300px',
    },
    userContainer: {
      // To align with body
      paddingRight: theme.spacing(1),
    },
  }),
);

export const PageHeaderContext = React.createContext({} as any);
export const UsernameHeaderContext = React.createContext({} as any);
export const usePageHeader = () => useContext(PageHeaderContext);
export const useUsernameHeader = () => useContext(UsernameHeaderContext);

export const HeaderBar: FC = React.memo(() => {
  const classes = useStyles();
  const {logout} = useAuth();
  const layoutClasses = useLayoutStyles();
  let history = useHistory();

  const logOut = () => logout({returnTo: window.location.origin});
  const goToUserInfoPage = () => {
    history.push(routes.userInfo);
  };
  const goToHomePage = () => {
    history.push(routes.dashboard);
  };
  const {userPageActive, user} = useSelector((state: AppState) => state.userReducer);
  const orgnanization = useSelector((state: AppState) => state.organizationReducer.organization);

  return (
    <Grid className={layoutClasses.desktopOnly} container alignItems="center">
      <Grid item>
        <div className={classes.cardWidth}>
          <img src={ngriScanLogo} alt="Logo" className={classes.headerLogo} onClick={goToHomePage} />
        </div>
      </Grid>

      <Grid item xs>
        <Grid container>
          <Grid xs={9} item>
            <Grid container direction="row" justify="flex-start" alignItems="center">
              <div className={classes.organizationDropDownMargin}>
                <OrganizationDropdown />
              </div>
              <BasicCard className={clsx(classes.organizationHeaderInfo)}>
                <Box display="flex" flexWrap="wrap">
                  <Box className={classes.organizationHeaderInfoData}>{localized('CustomerNo') + ':\u00A0'}</Box>
                  {orgnanization?.accountNum}
                  {/* TODO: We migth get debitorNo at some point
                  <Box className={classes.organizationHeaderInfoData} padding="0 0 0 20px">
                    {localized('DebitorNo') + ': '}
                  </Box>
                  {'000059194134'} */}
                </Box>
              </BasicCard>
            </Grid>
          </Grid>
          <Grid xs={3} item>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              className={clsx(classes.userContainer)}>
              <Box className={classes.user}>{user?.name ? user.name : user?.title}</Box>
              <Box className={classes.iconBox}>
                <BasicCardButton backgroundColor={userPageActive ? primaryColor : undefined} onClick={goToUserInfoPage}>
                  <AccountCircleOutlinedIcon className={userPageActive ? classes.iconWhite : classes.icon} />
                </BasicCardButton>
              </Box>
              <Box className={classes.iconBox}>
                <BasicCardButton onClick={logOut}>
                  <ExitToAppIcon className={classes.icon} />
                </BasicCardButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
