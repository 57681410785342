import {Box, Grid} from '@material-ui/core';
import React, {FC} from 'react';
import {useSelector} from 'react-redux';
import profile from '../../../assets/icons/profile.svg';
import {localized} from '../../../i18n/i18n';
import {AppState} from '../../../state/store';
import {GridWithCustomBreakpoints} from '../../../styles/custom-grid-breakpoints';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {TitleAndSubCard} from '../../../view/components/default-components/title-and-sub-card';
import {TitleAndSubTypography} from '../basic-card-title-subtext';
import {userInfoUseStyles} from './user-info-helper';
export const UserInfoCard: FC = React.memo(() => {
  const classes = userInfoUseStyles();
  const user = useSelector((state: AppState) => state.userReducer.user);

  return (
    <BasicCard className={classes.cardClass}>
      <Box display={'flex'}>
        <Box className={classes.cardIcon}>
          <img src={profile} width="32px" height="23px" alt="firm-icon" />
        </Box>
        <Box className={classes.cardText}>
          <Grid container direction={'row'}>
            <GridWithCustomBreakpoints item xs={12} sm={6} md={6}>
              <TitleAndSubCard title={localized('User')} subTitle={user?.name} />
            </GridWithCustomBreakpoints>
          </Grid>
          <div className={classes.contentContainer}>
            <Grid container direction="row" wrap={'wrap'}>
              {user?.name && (
                <Grid item sm={6} xs={12}>
                  <TitleAndSubTypography className={classes.minWidth250} title={'Name'} subText={user?.name} />{' '}
                </Grid>
              )}

              {user?.title && (
                <Grid item sm={6} xs={12}>
                  <TitleAndSubTypography className={classes.minWidth150} title={'Title'} subText={user?.title} />
                </Grid>
              )}
            </Grid>
          </div>
          <Grid container direction="row" wrap={'wrap'}>
            {user?.email && (
              <Grid item sm={6} xs={12}>
                <TitleAndSubTypography className={classes.defaultContent} title={'Email'} subText={user?.email} />
              </Grid>
            )}
          </Grid>
          {user?.phonenumber && (
            <TitleAndSubTypography
              className={classes.defaultContent}
              title={'Phone'}
              subText={localized('PhoneShort') + ' ' + user?.phonenumber}
            />
          )}
        </Box>
      </Box>
    </BasicCard>
  );
});
