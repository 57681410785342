import {IDailyConsumptionDto, IUnitDto} from '../../../api/api';

export interface EnergyConsumptionState {
  consumption: IDailyConsumptionDto[];
  consumptionOneYearPrior: IDailyConsumptionDto[];
  units: IUnitDto[];
  pending: boolean;
  isSuccess: boolean;
  totalConsumption: number;
  sortingDays: SortingDays;
  numberOfDays: number;
  currentFixedPeriodCategory: FixedPeriodCategory;
  priorFixedPeriodCategory: FixedPeriodCategory;
  selectedMeasuringPointIds: string[];
  idsInConsumption: string[];
  idsInConsumptionOneYearPrior: string[];
  monthlyConsumption: number;
  periodAverage: number;
  yearPriorSelected: boolean;
}

export interface SortingDays {
  fromDate: Date;
  toDate: Date;
}

export enum FixedPeriodCategory {
  NotFixed,
  Day,
  Week,
  Month,
  Quarter,
}
