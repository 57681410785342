import {createStyles, Grid, makeStyles, Theme, Typography} from '@material-ui/core';
import React from 'react';
import {localized} from '../../../i18n/i18n';
import {GridWithCustomBreakpoints} from '../../../styles/custom-grid-breakpoints';
import {h2, h5} from '../../../styles/font-size-constants';
import {PageContentContainer} from '../../../view/components/default-components/page-container/page-content-container';
import {PageHeader} from '../../../view/components/default-components/page-header.tsx/page-header';
import {PageTitle} from '../../../view/components/default-components/page-title/page-title';
import {NewsItemsOverview} from '../../components/news/news-card-item';
import {SignUpNewsRedirectCard} from '../../components/news/sign-up-news-redirect-card';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subText: {
      fontSize: h5,
      margin: '0 0 0 0px',
      lineHeight: '26px',
      maxWidth: '648px',
      [theme.breakpoints.up('md')]: {
        marginLeft: '16px',
      },
    },
    headerText: {
      fontSize: h2,
      fontWeight: 'bold',
      margin: '0 0 0 16px',
      lineHeight: '26px',
      maxWidth: '648px',
    },
    boldText: {
      fontWeight: 'bold',
    },
    flexDirectionRow: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      '&:hover': {
        color: '#3333',
      },
    },
    seachHeaderFlexBox: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

export const News = React.memo(() => {
  const classes = useStyles();

  return (
    <PageTitle title="News">
      <PageHeader area="template" title={localized('News')}></PageHeader>
      <PageContentContainer>
        <Grid container direction="row">
          <GridWithCustomBreakpoints item xs={12} sm={12} md={12}>
            <Typography className={classes.subText}>{localized('NewsDefaultText')}</Typography>
          </GridWithCustomBreakpoints>
          <GridWithCustomBreakpoints item xs={12} sm={12} md={12} lg={8} xl={8} betweenlgandxl={8}>
            <NewsItemsOverview />
          </GridWithCustomBreakpoints>
          <GridWithCustomBreakpoints item xs={12} sm={12} md={12} lg={4} xl={4} betweenlgandxl={4}>
            <SignUpNewsRedirectCard />
          </GridWithCustomBreakpoints>
        </Grid>
      </PageContentContainer>
    </PageTitle>
  );
});
