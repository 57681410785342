import {createSlice} from '@reduxjs/toolkit';
import {DebtorPostingDto} from '../../../api/api';
import {monthNames} from './debtor-posting-helper';
import {getDebtorPostings, SetCurrentFilter} from './operation';
import {DebtorPostingState} from './types';

const initialState: DebtorPostingState = {
  debtorPostings: [] as DebtorPostingDto[],
  pending: false,
  isSuccess: false,
  currentFilter: {
    DateFrom: undefined,
    DateTo: new Date(),
  },
  thisMonthDebtorPostings: [] as DebtorPostingDto[],
  saldo: 0,
  thisMonth: monthNames[new Date().getMonth()] + ' ' + new Date().getFullYear(),
};

export const DebtorPostingSlice = createSlice({
  name: 'Invoice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get
    builder.addCase(getDebtorPostings.fulfilled, (state, action) => {
      if (action.payload && action.payload.length !== 0 && action.payload[0].balance !== undefined) {
        state.pending = false;
        state.debtorPostings = action.payload;
        const thisMonth = new Date().getMonth();
        const thisYear = new Date().getFullYear();
        state.thisMonthDebtorPostings = action.payload
          ? action.payload.filter((d) => d.date.getMonth() === thisMonth && d.date?.getFullYear() === thisYear)
          : [];
        state.saldo = action.payload[0].balance;
        state.thisMonth = monthNames[new Date().getMonth()] + ' ' + new Date().getFullYear();
      }
    });

    builder.addCase(getDebtorPostings.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(getDebtorPostings.rejected, (state, action) => {
      state.pending = false;
    });

    builder.addCase(SetCurrentFilter.fulfilled, (state, action) => {
      state.pending = false;
      state.currentFilter = action.payload;
    });

    builder.addCase(SetCurrentFilter.pending, (state, action) => {
      state.pending = true;
    });

    builder.addCase(SetCurrentFilter.rejected, (state, action) => {
      state.pending = false;
    });
  },
});

// eslint-disable-next-line
export const {} = DebtorPostingSlice.actions;

export default DebtorPostingSlice.reducer;
