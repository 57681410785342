import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import React, {FC} from 'react';
import {cardHeadline, cardSubText, contentWrapperTextColor} from '../../../styles/color-constants';
import {body2, h3} from '../../../styles/font-size-constants';

interface PropsFromParent {
  title?: string;
  subTitle?: string;
  titleAlign?: 'flex-start' | 'center'
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: (props: PropsFromParent) => ({
      fontSize: h3,
      color: cardHeadline,
      fontWeight: 'bold',
      textAlign: 'left',
      alignSelf: props.titleAlign? props.titleAlign: 'flex-start'
    }),
    subTitle: () => ({
      fontSize: body2,
      textAlign: 'left',
      color: cardSubText,
    }),
  }),
);

export const TitleAndSubCard: FC<PropsFromParent> = React.memo((props) => {
  const classes = useStyles(props);
  return (
    <>
      <Box className={classes.title}>{props.title}</Box>
      <Box color={contentWrapperTextColor} className={classes.subTitle}>
        {props.subTitle}
      </Box>
    </>
  );
});
