import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {AutocompleteRenderOptionState} from '@material-ui/lab/Autocomplete';
import clsx from 'clsx';
import React, {FC} from 'react';
import { checkboxChecked } from '../../styles/color-constants';
import { BasicCheckbox } from '../../view/components/default-components/basic-checkbox/basic-checkbox';
import { FilterItem } from '../../view/components/default-components/checkbox-filtering/checkbox-filtering';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    optionText: {
      padding: theme.spacing(1, 0, 1, 0),
      width: '100%',
    },
    outerContainer: {
      padding: theme.spacing(0, 1, 0, 1),
      height: 45,
    },
    checkbox: {
      paddingLeft: 0,
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
    selected: {
      backgroundColor: 'transparent',
      color: checkboxChecked,
    },
    lastChild: {
      borderBottomWidth: 0,
    },
  }),
);

interface PropsFromParent {
  option: FilterItem;
  optionState?: AutocompleteRenderOptionState;
  onClick?: () => void;
  isLastChild?: boolean;
}

export const BasicCardCheckbox: FC<PropsFromParent> = React.memo(
  ({option, optionState, onClick, isLastChild}) => {
    const classes = useStyles();
    return (
      <div className={classes.outerContainer}>
        <div
          className={clsx(classes.optionText, {
            [classes.selected]: optionState?.selected,
            [classes.lastChild]: isLastChild,
          })}>
          <BasicCheckbox className={classes.checkbox} onClick={onClick} checked={optionState?.selected} />
          {option.title}
        </div>
      </div>
    );
  },
);
