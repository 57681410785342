import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import CreditCard from '../../../assets/icons/credit_card.svg';
import {localized} from '../../../i18n/i18n';
import {AppState} from '../../../state/store';
import {cardHeadline, secondaryTextColor} from '../../../styles/color-constants';
import {GridWithCustomBreakpoints} from '../../../styles/custom-grid-breakpoints';
import {h1, h4} from '../../../styles/font-size-constants';
import {BasicCard} from '../../../view/components/default-components/basic-card/basic-card';
import {TitleAndSubCard} from '../../../view/components/default-components/title-and-sub-card';
import {InvoiceTable} from './invoice-table';

export const useEconomyStyles = makeStyles((theme: Theme) =>
  createStyles({
    documentIcon: {
      width: '21px',
      height: '26px',
    },
    documentIconContainer: {
      height: '26px',
      paddingLeft: '5px',
    },
    alignSelfCenter: {
      alignSelf: 'center',
    },
    alignTextCenter: {
      textAlign: 'center',
    },
    alignTextBoldTextCenter: {
      textAlign: 'center',
      fontWeight: 800,
      fontSize: 20,
    },
    paddingLeftFive: {},
    documentContainer: {
      backgroundColor: '#fff',
      marginTop: '16px',
      boxShadow: 'rgb(131 141 155 / 20%) 3px 3px 10px',
      borderRadius: 10,
      marginLeft: '16px',
    },
    amountText: {
      marginRight: '10px',
      lineHeight: '50px',
      fontSize: h1,

      textAlign: 'left',
      fontWeight: 800,

      color: secondaryTextColor,
      [theme.breakpoints.up('md')]: {
        fontSize: 30,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 22,
      },
    },
    title: {
      lineHeight: '25px',
      fontSize: h4,
      color: cardHeadline,
      fontWeight: 'bold',
      textAlign: 'left',
    },
    subText: {
      lineHeight: '25px',
      marginLeft: '5px',
      color: cardHeadline,
      textAlign: 'left',
    },
    overflowY: {
      overflowY: 'auto',
    },
    boxStyle: {
      maxWidth: 'calc(100vw - 64px)',
      [theme.breakpoints.up('md')]: {
        maxWidth: 'calc(100vw - 300px - 64px - 64px - 16px - 16px)',
      },
    },
    spaceBetweenTitleAndData: {
      marginBottom: 20,
    },
    accumulatedCardHeader: {
      position: 'sticky',
      left: 0,
    },
    iconContainer: {
      marginRight: 10,
    },
  }),
);

export const InvoiceTableOverview = React.memo(() => {
  const classes = useEconomyStyles();
  const divRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(divRef.current?.clientWidth || 650);
  const {saldo} = useSelector((state: AppState) => state.debtorPostingReducer);

  // handles scroll bar, to align tableheader and tablerow
  useEffect(() => {
    const handler = () => {
      if (divRef && divRef.current) {
        const w = divRef.current.clientWidth;

        // Set the minWidth, according to element width - 42px equals icon width with margin
        if (w >= 1000 && width !== w) {
          setWidth(w - 42);
        }
      }
    };

    handler();
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
    // eslint-disable-next-line
  }, [width, divRef]);

  return (
    <GridWithCustomBreakpoints item xs={12} sm={12} md={12} lg={12} betweenlgandxl={7}>
      <BasicCard className={classes.boxStyle}>
        <Box display={'flex'} flexDirection={'row'}>
          <Box className={classes.iconContainer}>
            <img src={CreditCard} width="32px" height="23px" alt="firm-icon" />
          </Box>
          <Box display={'flex'} flexDirection={'column'} overflow={'auto'}>
            <Box className={classes.accumulatedCardHeader}>
              <Box>
                <TitleAndSubCard title={localized('Balance')} />
              </Box>
              <Box className={classes.spaceBetweenTitleAndData} display={'flex'} justifyContent="space-between">
                <Box className={classes.amountText}>
                  {saldo &&
                    saldo.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + ' kr'}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <InvoiceTable minWidth={width} />
      </BasicCard>
    </GridWithCustomBreakpoints>
  );
});
