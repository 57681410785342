import {Box, createStyles, makeStyles, Theme} from '@material-ui/core';
import React, {FC, useEffect, useRef, useState} from 'react';
import {List, ListRowRenderer} from 'react-virtualized';
import {VirtualizedListTableBoxHeight} from '../../../../styles/styling-constants';
import {ListTableHeader} from '../list-table/list-table-header';

interface PropsFromParent {
  columnSizes: number[];
  columnHeaders: string[];
  maxWidth?: (number | undefined)[];
  maxHeight?: number;
  minWidth?: number;
  textAlign?: 'center';
  items: any[];
  rowRenderer: ListRowRenderer;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1),
      width: 'auto',
    },
    list: (props: PropsFromParent) => ({
      height: VirtualizedListTableBoxHeight, // subtract page header,
      maxHeight: props.maxHeight ? props.maxHeight : 'unset',
      minWidth: props.minWidth ? props.minWidth : 'unset',
      overflowY: 'auto',
      outline: 'none',
      width: 'auto !important',
    }),
    scrollContainer: {
      overflow: 'hidden',
    },
  }),
);

type Props = PropsFromParent;

export const VirtualizedListTable: FC<Props> = React.memo((props) => {
  const classes = useStyles(props);
  const divRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(divRef.current?.clientHeight || 1000);
  const [width, setWidth] = useState(divRef.current?.clientWidth || 4000);
  const [hasScroll, setHasScroll] = useState(false);

  // handles scroll bar, to align tableheader and tablerow
  useEffect(() => {
    const handler = () => {
      if (divRef && divRef.current) {
        const h = divRef.current.clientHeight;
        const w = divRef.current.clientWidth;
        if (height !== h) {
          setHeight(h);
        }
        const showScroll = h / 64 < props.items.length;

        if (showScroll !== hasScroll) {
          setHasScroll(showScroll);
        }

        if (width !== w) {
          setWidth(w);
        }
      }
    };
    handler();
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
    // eslint-disable-next-line
  }, [props.items, hasScroll, height, width]);

  return (
    <div ref={divRef}>
      <Box className={classes.scrollContainer}>
        <ListTableHeader
          textAlign={props.textAlign}
          minWidth={props.minWidth ? props.minWidth - 10 : undefined}
          listHasScroll={false}
          columnSizes={props.columnSizes}
          headers={props.columnHeaders}
        />
        <List
          className={classes.list}
          overscanRowCount={5}
          height={divRef.current?.clientHeight || height}
          width={
            divRef.current && divRef.current.clientWidth
              ? props.minWidth && divRef.current.clientWidth < props.minWidth
                ? props.minWidth
                : divRef.current.clientWidth
              : width
          }
          rowCount={props.items.length}
          rowHeight={40}
          rowRenderer={props.rowRenderer}
        />
      </Box>
    </div>
  );
});
