import {IconButton} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React, {FC} from 'react';
import {useDispatch} from 'react-redux';
import {clearSnackbar} from '../../../../state/features/snackbar/snackbar-slice';
import {localized} from '../../../../i18n/i18n';

export const SnackbarCloseButton: FC<{snackKey: number}> = React.memo(({snackKey}) => {
  const dispatch = useDispatch();

  const onClickDismiss = () => {
    dispatch(clearSnackbar(snackKey));
  };

  return (
    <IconButton aria-label={localized('Close')} onClick={onClickDismiss}>
      <ClearIcon />
    </IconButton>
  );
});
